import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Filters } from 'page/internal/search/Filters'
import { FiltersTag } from 'page/internal/search/FiltersTag'
import { ui } from 'store/home/ui/HomeUiStore'
import { managerSearchStore } from 'store/manager/search'

export const FiltersPanel = observer(() => {
  const { mobile } = ui.window
  const { pin } = managerSearchStore

  if (!pin) return <Card pad={12}>
    <Space gap={8}>
      <Space gap={8}>
        <Icon size={16} name="filter_list" tertiary />
        {mobile && <Typo size={16} bold>
          {i18n('search.Filters')}
        </Typo>}
      </Space>
      <FiltersTag />
    </Space>
  </Card>

  return <Card pad={12}>
    <Filters />
  </Card>
})
