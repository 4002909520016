import { SearchInput } from 'component/Input/SearchInput'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'

export const Search = observer(() => {
  const { search } = groupPreviewSaas.it

  const onChange = useLatestCallback((value: string) => {
    groupPreviewSaas.it.search = value
  })

  return <SearchInput value={search} width={200} placeholder={i18n('label.Search')} onChange={onChange} />
})
