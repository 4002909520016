// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TryOnView-center-7eb4>:first-child{flex:1000000000 1 400px;width:100%;max-width:min(100vw,800px)}.TryOnView-center-7eb4>:last-child{flex:0 1 400px;width:100%;max-width:100vw}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/tryon/TryOnView.module.scss"],"names":[],"mappings":"AACE,oCACE,uBAAA,CACA,UAAA,CACA,0BAAA,CAGF,mCACE,cAAA,CACA,UAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"center": `TryOnView-center-7eb4`
};
module.exports = ___CSS_LOADER_EXPORT___;
