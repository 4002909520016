import { Tooltip } from 'component/Popover/Tooltip'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { sidebar } from 'saas/store/SidebarStore'
import css from './ExpandItem.module.scss'

export const ExpandItem = observer(() => {
  const { open } = sidebar
  const text = open ? i18n('sidebar.CompactView') : i18n('sidebar.FullView')
  const tooltip = open ? undefined : text

  const onOpen = useLatestCallback(() => {
    sidebar.open = !sidebar.open
  })

  return <Tooltip title={tooltip} right>
    <button className={css.button} onClick={onOpen}>
      <div className={css.icon}>
        {open ? <Icon name="left_panel_close" size={16} /> : <Icon name="left_panel_open" size={16} />}
      </div>
      {open && <div className={css.title}>
        {text}
      </div>}
    </button>
  </Tooltip>
})
