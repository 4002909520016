import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const TextColorInput = observer(() => {
  const { text } = tryon.it.edit
  const value = text ?? ''

  const onChange = useLatestCallback((value: string) => {
    tryon.it.edit.text = value.trim()
  })

  return <Field label={i18n('widget.TextColor')}>
    <Input mono lazy value={value} placeholder="#ffffff" onChange={onChange} />
  </Field>
})
