import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { Gap } from 'component/Space/Gap'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const TextsTab = observer(() => {
  const { data, title, description, agreement, thanks } = tryon.it.edit
  const phone = data === 'phone'

  const onChangeTitle = useLatestCallback((value: string) => {
    tryon.it.edit.title = value.trim() || undefined
  })
  const onChangeDescription = useLatestCallback((value: string) => {
    tryon.it.edit.description = value.trim() || undefined
  })
  const onChangeAgreement = useLatestCallback((value: string) => {
    tryon.it.edit.agreement = value.trim() || undefined
  })
  const onChangeThanks = useLatestCallback((value: string) => {
    tryon.it.edit.thanks = value.trim() || undefined
  })

  const label = phone ? i18n('widget.DefaultPhoneLabel') : i18n('widget.DefaultEmailLabel')
  const placeholder = phone ? i18n('widget.DefaultPhonePlaceholder') : i18n('widget.DefaultEmailPlaceholder')

  return <Vertical>
    <Field label={label}>
      <Input value={title || ''} onChange={onChangeTitle} />
    </Field>
    <Gap height={12} />
    <Field label={placeholder}>
      <Input value={description || ''} onChange={onChangeDescription} />
    </Field>
    <Gap height={12} />
    <Field label={i18n('widget.DefaultUserDataAgreement')}>
      <Input value={agreement || ''} onChange={onChangeAgreement} />
    </Field>
    <Gap height={12} />
    <Field label={i18n('widget.DefaultUserDataThanks')}>
      <Input value={thanks || ''} onChange={onChangeThanks} />
    </Field>
  </Vertical>
})
