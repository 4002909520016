// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocSubtitle-head-0c7b{min-height:32px;width:100%}.DocSubtitle-head-0c7b>*{margin:0 !important}`, "",{"version":3,"sources":["webpack://./src/saas/page/doc/DocSubtitle.module.scss"],"names":[],"mappings":"AAAA,uBACE,eAAA,CACA,UAAA,CAGF,yBACE,mBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": `DocSubtitle-head-0c7b`
};
module.exports = ___CSS_LOADER_EXPORT___;
