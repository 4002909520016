import { clsx } from 'clsx'
import { Card } from 'component/Card'
import { Placement } from 'component/Popover'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Tooltip } from 'component/Popover/Tooltip'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { sidebar } from 'saas/store/SidebarStore'
import css from './SideSelect.module.scss'

type Props = {
  icon: ReactElement
  text: string | undefined
  items: DropdownItem[]
  onClick?(key: string): void
  placement: Placement
}

export const SideSelect = observer((props: Props) => {
  const { icon, text, items, onClick, placement } = props
  const { open } = sidebar
  const tooltip = open ? undefined : text

  return <Tooltip title={tooltip} right>
    <Dropdown items={items} onClick={onClick} placement={placement} stretch={open} space={8}>
      <Card className={clsx(css.item, !open && css.compact)} gap={8} pad={[0, 8]} height={48} wide opposite>
        <Space gap={8} oh>
          <Space className={css.logo} center>
            {icon}
          </Space>
          <Typo className={css.text} size={13} medium ellipsis>
            {text}
          </Typo>
        </Space>
        <Icon className={css.icon} name="unfold_more" size={20} secondary />
      </Card>
    </Dropdown>
  </Tooltip>
})
