import { isEmpty } from 'lodash'
import { CSSProperties } from 'react'
import { omit } from 'util/object'

type CSSVariables = Partial<Record<`--${string}`, string>>

type OptionalNumber = number | null | undefined

export type Position = [OptionalNumber, OptionalNumber, OptionalNumber, OptionalNumber]

export function getLineHeight(line: number | undefined): string | number | undefined {
  if (line == null) return undefined
  if (line < 4) return line
  return line + 'px'
}

export function getPositionStyle(pin: Position | undefined): CSSProperties | undefined {
  if (pin == null) return undefined
  if (pin.length === 4) {
    const [top, right, bottom, left] = pin.map(n => n == null ? undefined : n)
    return { top, right, bottom, left }
  }
  return undefined
}

export type Padding = number | [number, number] | [number, number, number, number]

export function getPaddingStyle(padding: Padding | undefined): CSSProperties | undefined {
  if (padding == null) return undefined
  if (typeof padding === 'number') {
    return { padding }
  }
  if (padding.length === 2) {
    const [paddingBlock, paddingInline] = padding
    return { paddingBlock, paddingInline }
  }
  if (padding.length === 4) {
    const [paddingTop, paddingRight, paddingBottom, paddingLeft] = padding
    return { paddingTop, paddingRight, paddingBottom, paddingLeft }
  }
  return undefined
}

export type Margin = number | [number, number] | [number, number, number, number]

export function getMarginStyle(margin: Margin | undefined): CSSProperties | undefined {
  if (margin == null) return undefined
  if (typeof margin === 'number') {
    return { margin }
  }
  if (margin.length === 2) {
    const [marginBlock, marginInline] = margin
    return { marginBlock, marginInline }
  }
  if (margin.length === 4) {
    const [marginTop, marginRight, marginBottom, marginLeft] = margin
    return { marginTop, marginRight, marginBottom, marginLeft }
  }
  return undefined
}

export type Gap = number | [number, number]

export function getGapStyle(gap: Gap | undefined): CSSProperties | undefined {
  if (gap == null) return undefined
  if (typeof gap === 'number') {
    return { gap }
  }
  if (gap.length === 2) {
    const [rowGap, columnGap] = gap
    return { rowGap, columnGap }
  }
  return undefined
}

export function styles(style: CSSProperties | CSSVariables | undefined): CSSProperties | undefined {
  if (!style) return undefined
  const fixed = omit<CSSProperties>(style ?? {})
  if (isEmpty(fixed)) return undefined
  return style
}
