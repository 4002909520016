import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { makeAutoObservable, runInAction } from 'mobx'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { api } from 'store/api'
import { can } from 'store/can'
import { route } from 'store/route'
import { SaasCompany } from 'type/Company'
import { onceAsync } from 'util/async'

class CompaniesSaasStore {
  ready = false
  companies: CompanySaasStore[] = []

  constructor() {
    makeAutoObservable(this)
  }

  company(company_id: string | undefined): CompanySaasStore | undefined {
    return this.companies.find(c => c.company_id === company_id)
  }

  readonly load = onceAsync(async () => {
    const response = await api.getCompanies()
    runInAction(() => {
      this.companies = response
        .filter(co => can.ViewCompanyInformation(co.company_id))
        .map(co => new CompanySaasStore(co))
      this.ready = true
    })
  })

  async preload(company_id: string | undefined): Promise<CompanySaasStore | undefined> {
    await this.load()
    const company = this.company(company_id)
    if (company) await company.load()
    return company
  }

  addCompany(co: SaasCompany) {
    this.companies.push(new CompanySaasStore(co))
  }

  updateCompany(co: SaasCompany) {
    for (const company of this.companies) {
      if (company.company_id === co.company_id) {
        company.update(co)
      }
    }
  }

  async deleteCompany(company: CompanySaasStore) {
    const index = this.companies.indexOf(company)
    if (index >= 0) this.companies.splice(index, 1)
    route.push('/companies')
    await api.deleteCompany(company.company_id)
    notice.success(i18n('company.CompanyDeleted'))
  }
}

export const companiesSaas = new CompaniesSaasStore()
