// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Image-image-daad{display:flex}.Image-image-daad img{width:100% !important;height:100% !important;object-fit:cover}.Image-image-daad.Image-contain-daad img{object-fit:contain}.Image-full-daad{width:100%;height:100%}.Image-pointer-daad{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/component/Image/Image.module.scss"],"names":[],"mappings":"AAAA,kBACE,YAAA,CAEA,sBACE,qBAAA,CACA,sBAAA,CACA,gBAAA,CAGF,yCACE,kBAAA,CAIJ,iBACE,UAAA,CACA,WAAA,CAOF,oBACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `Image-image-daad`,
	"contain": `Image-contain-daad`,
	"full": `Image-full-daad`,
	"pointer": `Image-pointer-daad`
};
module.exports = ___CSS_LOADER_EXPORT___;
