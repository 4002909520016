import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'

export const TokenInput = observer(() => {
  const { bot_token, bot_error } = bot.it.edit
  const value = bot_token ?? ''
  const error = !value || !!bot_error

  const onChange = useLatestCallback((value: string) => {
    bot.it.edit.bot_token = value
  })

  return <Vertical gap={6}>
    <Field label={i18n('bot.TelegramBotApiToken')}>
      <Input mono lazy value={value} onChange={onChange} error={error} />
    </Field>
    {bot_error && <Typo size={11} error>{bot_error}</Typo>}
  </Vertical>
})
