import { i18n } from 'i18n'
import { SeriesResponse, StatValue } from 'type/Statistics'
import { last } from 'util/array'
import { parseDate } from 'util/date'
import { getLocalFirstDay } from 'util/week'

export type ChartSerieId = 'users' | 'inferences' | 'widgetOpen' | 'share' | string
export type SerieGroup = 'month' | 'day' | 'week'

export type ChartData = {
  x: number
  y: number
}

export type ChartSerie = {
  id: ChartSerieId
  color: string
  data: ChartData[]
}

type Datas = Map<number, ChartData>

const DAY = 24 * 60 * 60 * 1000
const firstDay = getLocalFirstDay()

function extractX(v: StatValue): number {
  return parseDate(v.date)?.getTime() ?? 0
}

function extractXx(response: SeriesResponse): number[] {
  const xx = new Set<number>()
  for (const [, values] of Object.entries(response)) {
    for (const value of values) {
      xx.add(extractX(value))
    }
  }
  return Array.from(xx).sort()
}

function resolveGroup(xx: number[]): SerieGroup {
  const a = xx[0] ?? 0
  const b = last(xx) ?? 0
  const days = Math.ceil((b - a) / DAY)

  if (days > 62) return 'month'
  if (days > 14) return 'week'
  return 'day'
}

function roundTime(x: number, group: SerieGroup) {
  const date = new Date(x)
  date.setHours(0, 0, 0, 0)
  if (group === 'week') {
    const back = (7 + date.getDay() - firstDay) % 7
    const first = date.getDate() - back
    date.setDate(first)
  }
  if (group === 'month') {
    date.setDate(1)
  }
  return date.getTime()
}

function groupXx(xx: number[], group: SerieGroup) {
  const gg = new Set<number>()
  for (const x of xx) {
    const g = roundTime(x, group)
    gg.add(g)
  }
  return Array.from(gg)
}

function createData(x: number, v: StatValue, prev: ChartData | undefined): ChartData {
  const add = prev?.y ?? 0
  const y = v.count + add
  return { x, y }
}

function extractDatas(values: StatValue[], group: SerieGroup): Datas {
  const datas: Datas = new Map()
  for (const value of values) {
    const x = extractX(value)
    const date = roundTime(x, group)
    const prev = datas.get(date)
    datas.set(date, createData(date, value, prev))
  }
  return datas
}

function createSerie(id: ChartSerieId, dates: number[], datas: Datas): ChartSerie {
  const color = getSerieColor(id)
  const data = dates.map(x => datas.get(x) ?? { x, y: 0 })
  return { id, color, data }
}

type SeriesOutput = {
  series: ChartSerie[]
  group: SerieGroup
}

export function createSeries(response: SeriesResponse): SeriesOutput {
  const series: ChartSerie[] = []
  const xx = extractXx(response)
  const group = resolveGroup(xx)
  const dates = groupXx(xx, group)
  for (const [key, values] of Object.entries(response)) {
    const datas = extractDatas(values, group)
    const serie = createSerie(key, dates, datas)
    if (serie.data.length) series.push(serie)
  }
  return { series, group }
}

export function getSerieTitle(id: ChartSerieId): string {
  switch (id) {
    case 'users':
      return i18n('chart.Users')
    case 'inferences':
      return i18n('chart.Generations')
    case 'widgetOpen':
      return i18n('chart.WidgetOpen')
    case 'share':
      return i18n('chart.Share')
    default:
      return id.toUpperCase()
  }
}

export function getSerieColor(id: ChartSerieId) {
  switch (id) {
    case 'widgetOpen':
      return '#527BF4'
    case 'inferences':
      return '#9747FF'
    case 'users':
      return '#36BF91'
    case 'share':
      return '#FFC700'
    default:
      return '#f0f0f0'
  }
}
