// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ItemTag-tag-2cfb{position:relative;display:flex;align-items:center;justify-content:center;margin:0;font-size:13px;background-color:#fff;overflow:hidden;min-width:32px;border-radius:var(--ui-border-radius-half);border:1px solid rgba(30,30,30,.08);height:32px;padding:0 12px;padding-inline:12px 12px}.ItemTag-content-2cfb{overflow:hidden;text-overflow:ellipsis}.ItemTag-close-2cfb{position:absolute;top:0;right:0;bottom:0;width:32px;margin:auto 0;display:flex;align-items:center;justify-content:center;transition:opacity 200ms ease-in-out;opacity:0;cursor:pointer;border-radius:var(--ui-border-radius-half)}.ItemTag-tag-2cfb:hover .ItemTag-close-2cfb{opacity:1}.ItemTag-icon-2cfb{background-color:var(--ui-background-color);border-radius:3px;color:var(--ui-text-color-secondary);transition:color 200ms ease-in-out}.ItemTag-close-2cfb:hover .ItemTag-icon-2cfb{color:var(--ui-text-color)}`, "",{"version":3,"sources":["webpack://./src/component/Tag/ItemTag.module.scss"],"names":[],"mappings":"AAEA,kBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,QAAA,CACA,cAAA,CACA,qBAAA,CACA,eAAA,CACA,cAXK,CAYL,0CAAA,CACA,mCAAA,CACA,WAdK,CAeL,cAAA,CACA,wBAAA,CAGF,sBACE,eAAA,CACA,sBAAA,CAGF,oBACE,iBAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,UA7BK,CA8BL,aAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,oCAAA,CACA,SAAA,CACA,cAAA,CACA,0CAAA,CAGF,4CACE,SAAA,CAGF,mBACE,2CAAA,CACA,iBAAA,CACA,oCAAA,CACA,kCAAA,CAGF,6CACE,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `ItemTag-tag-2cfb`,
	"content": `ItemTag-content-2cfb`,
	"close": `ItemTag-close-2cfb`,
	"icon": `ItemTag-icon-2cfb`
};
module.exports = ___CSS_LOADER_EXPORT___;
