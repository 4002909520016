// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SideLogo-large-832c{width:100%;max-width:min(240px,100vw - 36px)}.SideLogo-home-832c{width:32px;height:32px;color:var(--sidebar-text-color);background:rgba(250,250,250,.04);border-radius:8px}.SideLogo-home-832c:hover{background:var(--sidebar-item-icon-background-hover);color:var(--sidebar-background)}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/SideLogo.module.scss"],"names":[],"mappings":"AAAA,qBACE,UAAA,CACA,iCAAA,CAGF,oBACE,UAAA,CACA,WAAA,CACA,+BAAA,CACA,gCAAA,CACA,iBAAA,CAGF,0BACE,oDAAA,CACA,+BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"large": `SideLogo-large-832c`,
	"home": `SideLogo-home-832c`
};
module.exports = ___CSS_LOADER_EXPORT___;
