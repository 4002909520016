import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { GroupDeleteModal } from 'saas/page/group/components/GroupDeleteModal'
import { MainView } from 'saas/page/group/components/MainView'
import { Title } from 'saas/page/group/components/Title'
import { GroupPreview } from 'saas/page/group/preview/GroupPreview'
import { groupDeleteModal } from 'saas/store/groups/GroupDeleteModalStore'

export const SaasGroupView = observer(() => {
  return (
    <Vertical>
      <Title />
      <Vertical gap={16}>
        <MainView />
        <GroupPreview />
      </Vertical>
      {groupDeleteModal.present && <GroupDeleteModal />}
    </Vertical>
  )
})
