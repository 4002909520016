import { getWindowHost } from 'app/config/window'
import { CopyButton } from 'component/Button/CopyButton'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'

export const Links = observer(() => {
  const { product_id, json } = stand.it
  const { published } = json
  const slug = product_id.substring(0, 8)
  const host = getWindowHost()
  const linkFull = `${host}/${product_id}`
  const linkShort = `${host}/${slug}`
  const linkStand = `${host}/${slug}?stand`

  return <Vertical oh>
    <Typo size={12} secondary>{i18n('label.Link')}</Typo>
    <Gap height={4} />
    <Space gap={8}>
      <Link href={linkFull} disabled={!published}>
        {linkFull.replace('https://', '')}
      </Link>
      <CopyButton data={linkFull} />
    </Space>
    <Gap height={16} />

    <Typo size={12} secondary>{i18n('label.ShortLink')}</Typo>
    <Gap height={4} />
    <Space gap={8}>
      <Link href={linkShort} disabled={!published}>
        {linkShort.replace('https://', '')}
      </Link>
      <CopyButton data={linkShort} />
    </Space>
    <Gap height={16} />

    <Typo size={12} secondary>{i18n('stand.StandLink')}</Typo>
    <Gap height={4} />
    <Space gap={8}>
      <Link href={linkStand} disabled={!published}>
        {linkStand.replace('https://', '')}
      </Link>
      <CopyButton data={linkStand} />
    </Space>
  </Vertical>
})
