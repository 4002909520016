import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { me } from 'store/me'
import { notNil } from 'util/null'
import css from './UserLogo.module.scss'

export const UserLogo = observer(() => {
  const image = me.user.picture
  const names = me.user.name?.split(' ').map(s => s[0]).filter(notNil)
  const letters = names?.slice(0, 2).join('').toUpperCase()

  if (image) return <img className={css.image} src={image} />

  if (letters) return <Space className={css.space} center>
    <Typo size={13} medium>{letters}</Typo>
  </Space>

  return <Space className={css.space} center>
    <Icon size={16} name="account_circle" />
  </Space>
})
