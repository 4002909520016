import { IconButton } from 'component/Button/IconButton'
import { Table } from 'component/Table'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ActionSelect } from 'saas/page/group/components/FilterRow/ActionSelect'
import { TypeSelect } from 'saas/page/group/components/FilterRow/TypeSelect'
import { ValueInput } from 'saas/page/group/components/FilterRow/ValueInput'
import { GroupFilterStore } from 'saas/store/groups/GroupFilterStore'
import { groupFormSaas } from 'saas/store/groups/GroupFormStore'
import css from './FilterRow.module.scss'

type FilterRowProps = {
  filter: GroupFilterStore
}

export const FilterRow = observer(({ filter }: FilterRowProps) => {
  const onDelete = useLatestCallback(() => {
    groupFormSaas.it.filters.data.remove(filter)
  })

  return (
    <Table.Tr>
      <Table.Td className={css.col}>
        <TypeSelect filter={filter} />
      </Table.Td>
      <Table.Td className={css.col}>
        <ActionSelect filter={filter} />
      </Table.Td>
      <Table.Td className={css.col}>
        <ValueInput filter={filter} />
      </Table.Td>
      <Table.Td className={css.colDelete}>
        <IconButton large action={onDelete}>
          <Icon name="delete" />
        </IconButton>
      </Table.Td>
    </Table.Tr>
  )
})
