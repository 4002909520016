import { Input, InputProps } from 'component/Input/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { KeyboardEvent, useState } from 'react'
import { getShortcut } from 'util/keyboard'

export function SearchInput(props: InputProps) {
  const [timer, setTimer] = useState<TimeoutId | undefined>()
  const [text, setText] = useState('')
  const [focus, setFocus] = useState(false)
  const value = focus ? text : props.value

  const scheduleChange = useLatestCallback(() => {
    clearTimeout(timer)
    setTimer(setTimeout(() => fireChange(), 500))
  })

  const fireChange = useLatestCallback((force?: string) => {
    clearTimeout(timer)
    const value = force ?? text
    if (value !== props.value) props.onChange?.(value)
  })

  const onFocus = useLatestCallback((focus: boolean) => {
    setFocus(focus)
    if (focus) setText(props.value)
    else fireChange()
    props.onFocus?.(focus)
  })

  const onChange = useLatestCallback((text: string) => {
    setText(text)
    if (!text) fireChange(text)
    else scheduleChange()
  })

  const onKeyDown = useLatestCallback((event: KeyboardEvent) => {
    const shortcut = getShortcut(event)
    if (shortcut === 'Enter') {
      fireChange()
    }
    if (shortcut === 'Escape') {
      setText('')
      fireChange('')
    }
    props.onKeyDown?.(event)
  })

  const before = <Icon size={16} name="search" secondary style={{ marginRight: 8 }} />
  return <Input prefix={before} wide {...props}
    onFocus={onFocus} onChange={onChange} onKeyDown={onKeyDown} value={value} />
}
