// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocCard-markdown-1853{line-height:1.5;font-size:13px}.DocCard-markdown-1853 p{margin:12px 0}.DocCard-markdown-1853 h1{font-size:16px;margin:8px 0}.DocCard-markdown-1853 h2{font-size:15px;margin:8px 0}.DocCard-markdown-1853 h3{font-size:14px;margin:8px 0}.DocCard-markdown-1853 h4{font-size:13px;margin:8px 0}.DocCard-markdown-1853 h5{font-size:12px;margin:8px 0}.DocCard-markdown-1853 h6{font-size:11px;margin:8px 0}.DocCard-markdown-1853 ul{padding-left:24px}.DocCard-markdown-1853 ul>li>p{margin:0}.DocCard-markdown-1853 blockquote{padding:0 8px;margin:0 8px;border-left:3px solid var(--ui-background-color-soft)}.DocCard-markdown-1853 a{color:rgba(82,123,244,.8784313725);-webkit-text-decoration:underline;text-decoration:underline}.DocCard-markdown-1853 pre code{display:block;padding:8px 12px}`, "",{"version":3,"sources":["webpack://./src/saas/page/doc/DocCard.module.scss"],"names":[],"mappings":"AAAA,uBACE,eAAA,CACA,cAAA,CAGF,yBACE,aAAA,CAGF,0BACE,cAAA,CACA,YAAA,CAGF,0BACE,cAAA,CACA,YAAA,CAGF,0BACE,cAAA,CACA,YAAA,CAGF,0BACE,cAAA,CACA,YAAA,CAGF,0BACE,cAAA,CACA,YAAA,CAGF,0BACE,cAAA,CACA,YAAA,CAGF,0BACE,iBAAA,CAGF,+BACE,QAAA,CAGF,kCACE,aAAA,CACA,YAAA,CACA,qDAAA,CAGF,yBACE,kCAAA,CACA,iCAAA,CAAA,yBAAA,CAGF,gCACE,aAAA,CACA,gBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markdown": `DocCard-markdown-1853`
};
module.exports = ___CSS_LOADER_EXPORT___;
