import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import css from './Page2.module.scss'

type Props = {
  children: [ReactElement, ReactElement]
}

export const Page2 = observer((props: Props) => {
  const [head, main] = props.children

  return <Vertical className={css.page}>
    {head}
    <Space className={css.divider}>
      <Divider secondary />
    </Space>
    {main}
  </Vertical>
})
