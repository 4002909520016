import { CopyIconButton } from 'component/Button/CopyIconButton'
import { Sticker } from 'component/Glass'
import { Scroll } from 'component/Scroll'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import css from './PreContainer.module.scss'

type Props = {
  child: Element
}

export const PreContainer = observer(({ child }: Props) => {
  const [ref, setRef] = useState<HTMLElement | null>(null)
  const code = child.textContent

  useEffect(() => {
    if (!ref) return
    ref.replaceChildren(child)
  }, [ref, child])

  return <Space className={css.container}>
    <Scroll className={css.scroll}>
      <div ref={setRef} className={css.pre} />
    </Scroll>
    <Sticker pin={[8, 8, null, null]}>
      <CopyIconButton data={code} />
    </Sticker>
  </Space>
})
