import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const PrimaryColorInput = observer(() => {
  const { primary } = tryon.it.edit
  const value = primary ?? ''

  const onChange = useLatestCallback((value: string) => {
    tryon.it.edit.primary = value.trim()
  })

  return <Field label={i18n('widget.PrimaryColor')}>
    <Input mono lazy value={value} placeholder="#000000" onChange={onChange} />
  </Field>
})
