// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Status-field-47ea *{cursor:default !important}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/tryon/Details/Status.module.scss"],"names":[],"mappings":"AAAA,qBACE,yBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": `Status-field-47ea`
};
module.exports = ___CSS_LOADER_EXPORT___;
