import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { getSortFieldLabel, SORT_FIELDS, SortField } from 'feature/pagination/SortField'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ui } from 'store/home/ui/HomeUiStore'

type Props = {
  onChange(value: SortField): void
  value: SortField
}

export const SortDropdown = observer(({ onChange, value }: Props) => {
  const { mobile } = ui.window

  const options = SORT_FIELDS.map<DropdownItem>(it => ({
    key: it,
    label: <Typo primary={value === it}>{getSortFieldLabel(it)}</Typo>,
    action: () => onChange(it),
  }))

  return <Space gap={8} oh maxWidth="calc(100vw - 64px)">
    {!mobile && <Typo size={13} secondary>{i18n('search.Sorting')}</Typo>}
    <Dropdown placement="bottomRight" items={options}>
      <Space gap={2} height={20} oh>
        <Typo size={13} medium ellipsis>{getSortFieldLabel(value)}</Typo>
        <Icon name="keyboard_arrow_down" />
      </Space>
    </Dropdown>
  </Space>
})
