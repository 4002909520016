import { captureException } from '@sentry/react'
import type { Extras } from '@sentry/types/build/types/extra'
import { auth } from 'store/auth'
import { buildGetQuery, Data, getBodyContentType, Method, toBody, toExtraJson, toFormData } from 'util/api'
import { omit } from 'util/object'

export type ExtractResponse<T> = (response: Response) => Promise<T>
export type Tune<T = unknown> = {
  extract?: ExtractResponse<T>
}

class Rest {
  async get<T>(path: string, data?: Data, tune?: Tune<T>): Promise<T> {
    const query = buildGetQuery(path, data)
    const headers = omit({
      authorization: auth.token,
    })
    return this.fetch('GET', query, { headers }, tune)
  }

  async post<T>(path: string, data?: Data, tune?: Tune<T>): Promise<T> {
    const body = toBody(data)
    const headers = omit({
      'Content-Type': getBodyContentType(body),
      authorization: auth.token,
    })
    return this.fetch('POST', path, { body, headers }, tune)
  }

  async form<T>(path: string, data?: Data, tune?: Tune<T>): Promise<T> {
    const body = toFormData(data ?? {})
    const headers = omit({
      authorization: auth.token,
    })
    return this.fetch('POST', path, { body, headers }, tune)
  }

  async text(path: string, data?: Data, tune?: Tune<string>): Promise<string> {
    const query = buildGetQuery(path, data)
    const headers = omit({
      authorization: auth.token,
    })
    tune = { ...tune }
    tune.extract ??= r => r.text()
    return this.fetch('GET', query, { headers }, tune)
  }

  private async fetch<T>(method: Method, path: string, options?: RequestInit, tune?: Tune<T>): Promise<T> {
    try {
      const response = await fetch(path, { method, ...options })
      if (!response.ok) {
        const { status, statusText } = response
        let text = ''
        try {
          text = await response.text()
        } catch {
          // empty
        }
        throw new Error(`${status} ${statusText} ${method} ${path}\n${text}`)
      }
      if (tune?.extract) return await tune.extract(response)
      return await response.json()
    } catch (e) {
      const extra = this.extra(method, path, options)
      captureException(e, { extra })
      throw e
    }
  }

  private extra(method: Method, path: string, options: RequestInit | undefined): Extras {
    const url = new URL(path, location.href)
    const query = Object.fromEntries(url.searchParams.entries())
    const body = toExtraJson(options?.body)
    return { request: { method, path, body, query } }
  }
}

export const rest = new Rest()
