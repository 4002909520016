// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo-logo-35e5{transition:color var(--transition) ease-in-out;color:var(--sidebar-text-color);width:100%;height:48px}.Logo-logo-35e5:hover{color:var(--ui-primary-color)}@media(width <= 240px){.Logo-logo-35e5 svg{display:none}}.Logo-sep-35e5{flex:0 0 auto;width:1px;height:16px;background-color:currentColor}@media(width <= 320px){.Logo-sep-35e5{display:none}}.Logo-title-35e5{flex:0 0 auto;font-size:13px;font-weight:700;line-height:16px;white-space:pre}@media(width <= 320px){.Logo-title-35e5{display:none}}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/Logo.module.scss"],"names":[],"mappings":"AAAA,gBACE,8CAAA,CACA,+BAAA,CACA,UAAA,CACA,WAAA,CAGF,sBACE,6BAAA,CAIA,uBADF,oBAEI,YAAA,CAAA,CAIJ,eACE,aAAA,CACA,SAAA,CACA,WAAA,CACA,6BAAA,CAEA,uBANF,eAOI,YAAA,CAAA,CAIJ,iBACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,gBAAA,CACA,eAAA,CAEA,uBAPF,iBAQI,YAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `Logo-logo-35e5`,
	"sep": `Logo-sep-35e5`,
	"title": `Logo-title-35e5`
};
module.exports = ___CSS_LOADER_EXPORT___;
