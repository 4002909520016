import { Segmented } from 'component/Segmented'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const ThemeSelect = observer(() => {
  const { theme } = tryon.it.edit

  const options = [{
    value: 'light',
    label: i18n('widget.ThemeLight'),
    icon: <Icon size={16} name="light_mode" />,
  }, {
    value: 'dark',
    label: i18n('widget.ThemeDark'),
    icon: <Icon size={16} name="dark_mode" />,
  }]

  const onChange = useLatestCallback((theme: string) => {
    tryon.it.edit.theme = theme
  })

  return <Segmented options={options} value={theme ?? 'light'} onChange={onChange} />
})
