import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { makeAutoObservable, observable } from 'mobx'
import { StandEditStore } from 'saas/store/product/stand/StandEditStore'
import { productsConfig, ProductsConfigStore } from 'saas/store/products/ProductsConfigStore'
import { StandProduct } from 'type/product/StandProduct'
import { updateObject } from 'util/object'

export class StandProductStore {
  readonly company_id: string
  private _json: StandProduct
  private _edit!: StandEditStore

  constructor(company_id: string, json: StandProduct) {
    this.company_id = company_id
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
    this._edit = new StandEditStore(this._json)
  }

  get product_id(): string {
    return this._json.product_id
  }

  get json(): StandProduct {
    return this._json
  }

  get busy(): boolean {
    return this.config.busy
  }

  get edit(): StandEditStore {
    return this._edit
  }

  async saveSettings() {
    if (!this.edit.check()) return
    const { updates } = this.edit
    const json = updateObject(this._json, updates)
    this.json = json
    this._edit.update(json)
    await this.save()
    notice.success(i18n('message.Saved'))
  }

  async publish(publish: boolean) {
    if (!!this.json.published === publish) return
    const published = publish ? true : undefined
    const update = { published }
    this.json = updateObject(this._json, update)
    await this.save()
  }

  open() {
    this._edit = new StandEditStore(this._json)
  }

  private get config(): ProductsConfigStore {
    return productsConfig.get(this.company_id)
  }

  private set json(json: StandProduct) {
    json.updated_at = new Date().toISOString()
    this._json = json
    this.edit.json = this.json
  }

  private async save() {
    await this.config.updateProduct(this._json)
  }
}
