import { PaginationPanel } from 'feature/pagination/PaginationPanel'
import { observer } from 'mobx-react-lite'
import { page } from 'page/internal/products/store/ProductsPageStore'

type Props = {
  top?: boolean
}

export const Pagination = observer(({ top }: Props) => {
  return <PaginationPanel top={top} store={page.it} />
})
