import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { CollectionTitle } from 'page/internal/products/CollectionTitle'
import { closeProductsPage, openProductsPage } from 'page/internal/products/store/ProductsPageStore'
import { View } from 'page/internal/products/View'
import { useEffect } from 'react'
import { home } from 'store/home'

export const ProductsView = observer(() => {
  const collection = home.collection.it

  useEffect(() => {
    openProductsPage(collection)
    return () => closeProductsPage()
  }, [collection])

  return <Vertical gap={16}>
    <CollectionTitle />
    <View />
  </Vertical>
})
