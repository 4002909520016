import { ErrorPage } from 'feature/page/ErrorPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { useBodyScrollLock } from 'hook/useBodyScrollLock'
import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { SaasPageRouting } from 'saas/route/SaasPageRouting'
import { Bar } from 'saas/screen/sidebar/Bar'
import { Sidebar } from 'saas/screen/sidebar/Sidebar'
import { saas } from 'saas/store/SaasController'
import { sidebar } from 'saas/store/SidebarStore'
import css from './SaasScreen.module.scss'

export const SaasScreen = observer(() => {
  const { ready, error } = saas
  const { open, small } = sidebar

  useEffectOnce(() => void saas.init())
  useBodyScrollLock(small && open)

  if (error) return <ErrorPage text={error} />
  if (!ready) return <LoadingPage />

  return <div className={css.screen}>
    {small ? <Bar /> : <Sidebar />}
    <div className={css.center}>
      <div className={css.content}>
        <SaasPageRouting />
      </div>
    </div>
  </div>
})
