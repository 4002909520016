import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { ItemTag } from 'component/Tag'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ProductStore } from 'store/product'
import { emdash } from 'util/typo'
import css from './TagsList.module.scss'

type Props = {
  product: ProductStore
}

export const TagsList = observer(({ product }: Props) => {
  const { tags } = product.tags

  if (!window) return <Card gap={4} pad={4}>
    {tags.value.map(tag => <ItemTag key={tag} data={tag}>{tag}</ItemTag>)}
  </Card>

  return <Space gap={4} wrap>
    <Typo className={css.title} size={12} secondary ellipsis>
      {i18n('label.Tags')}
    </Typo>
    {!tags.value.length && <Typo size={12} tertiary>{emdash}</Typo>}
    {tags.value.map(tag => <Typo key={tag} className={css.tag} size={12}>{tag}</Typo>)}
  </Space>
})
