import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'

export const TitleEdit = observer(() => {
  const { name } = productSaas.it.edit

  return <TextField field={name} label={i18n('label.Name')} />
})
