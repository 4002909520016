// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.View-root-c3c8{gap:16px;justify-content:stretch;align-items:start}@media(width >= 720px){.View-root-c3c8{flex-direction:row-reverse}}.View-center-c3c8{flex:1000000000 1 900px;width:100%;max-width:100vw}.View-right-c3c8{flex:1 0 500px;width:100%;max-width:100vw;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/common/View/View.module.scss"],"names":[],"mappings":"AAAA,gBACE,QAAA,CACA,uBAAA,CACA,iBAAA,CAEA,uBALF,gBAMI,0BAAA,CAAA,CAIJ,kBACE,uBAAA,CACA,UAAA,CACA,eAAA,CAGF,iBACE,cAAA,CACA,UAAA,CACA,eAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `View-root-c3c8`,
	"center": `View-center-c3c8`,
	"right": `View-right-c3c8`
};
module.exports = ___CSS_LOADER_EXPORT___;
