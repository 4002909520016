import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { api } from 'store/api'
import { ArrayStore } from 'store/base/ArrayStore'
import { LazyStore } from 'store/base/LazyStore'
import { mx } from 'store/base/mx'
import { SingleProcessStore } from 'store/base/process/SingleProcessStore'
import { CollectionDataStore } from 'store/collection/CollectionDataStore'
import { me } from 'store/me'
import { ProductStore } from 'store/product'
import { ProductItem } from 'store/product/ProductItem'
import { ProductStubStore } from 'store/product/ProductStubStore'
import { Collection } from 'type/Collection'
import { ExternalStatus } from 'type/ExternalStatus'
import { InternalStatus } from 'type/InternalStatus'
import { CreateItemRequest, Item } from 'type/Item'
import { uniq } from 'util/array'
import { onceAsync, strongAsync } from 'util/async'
import { compareIgnoreCase } from 'util/string'

export const collectionStoreCache: Record<string, CollectionStore | undefined> = {}

export class CollectionStore {
  readonly companyId: string
  readonly collectionId: string
  private readonly _data = mx.ref<CollectionDataStore>()
  readonly items = new LazyStore<ArrayStore<ProductItem>>()

  static fromCache(collection_id: string): CollectionStore | undefined {
    return collectionStoreCache[collection_id]
  }

  static fromJson(json: Collection): CollectionStore {
    const collection = this.fromCache(json.collection_id)
    if (collection) {
      collection.updateData(new CollectionDataStore(json))
      return collection
    } else {
      return new CollectionStore(new CollectionDataStore(json))
    }
  }

  /** @deprecated */
  constructor(data: CollectionDataStore) {
    this.companyId = data.companyId
    this.collectionId = data.collectionId
    this._data.it = data
    collectionStoreCache[this.collectionId] = this
  }

  get tags(): string[] {
    const items = this.items.optional?.value
    if (!items) return []
    const flat = items.flatMap(it => it.store.optional?.tags.original ?? [])
    return uniq(flat).sort(compareIgnoreCase)
  }

  get statuses() {
    const statuses: Record<InternalStatus, number> =
      { check: 0, disabled: 0, done: 0, edit: 0, in_progress: 0 }
    if (!this.items.present) return statuses
    for (const item of this.items.it.value) {
      const status = item.store.optional?.data.json.internal_status
      if (status) ++statuses[status]
    }
    return statuses
  }

  get reviews() {
    const reviews: Record<ExternalStatus, number> =
      { cancel: 0, public: 0, retry: 0, review: 0, wait: 0 }
    if (!this.items.present) return reviews
    for (const item of this.items.it.value) {
      const status = item.store.optional?.data.json.external_status
      if (status) ++reviews[status]
    }
    return reviews
  }

  get data(): CollectionDataStore {
    return this._data.it
  }

  updateData(data: CollectionDataStore): void {
    this._data.it = data
  }

  loadItems = onceAsync(() => this.loadItemsInner(false))

  reloadItems = strongAsync(() => this.loadItemsInner(true))

  setItems(items: Item[]) {
    this.items.it = this.createItems(items)
  }

  findStoredProduct(productId: string | undefined): ProductItem | undefined {
    if (productId == null) return undefined
    return this.items.optional?.find(item =>
      item.store.present && item.store.it.itemId === productId)
  }

  findRoute(productId: string | undefined): ProductItem | undefined {
    if (productId == null) return undefined
    return this.items.optional?.find(item => item.store.present && productId === item.store.it.itemId)
  }

  readonly createItem = new SingleProcessStore(async (): Promise<void> => {
    const product_name = 'New'
    const request: CreateItemRequest = {
      user_id: me.user.user_id,
      collection_id: this.collectionId,
      product_name,
    }

    const item = new ProductItem(new ProductStubStore(product_name))
    this.items.optional?.addFirst(item)
    await item.store.open(async () => {
      try {
        const response = await api.createItem(request)
        notice.success(i18n('item.ItemCreated'))
        return new ProductStore(response, this.companyId)
      } catch (e) {
        console.error(e)
        this.items.optional?.remove(item)
        throw e
      }
    })
  })

  deleteItem(item: ProductItem): void {
    this.items.optional?.remove(item)
  }

  private async loadItemsInner(reload: boolean): Promise<void> {
    await this.items.open(async () => {
      const { items } = await api.getCollectionItems(this.collectionId)
      return this.createItems(items)
    }, reload)
  }

  private createItems(items: Item[]) {
    return new ArrayStore(items.map(item => {
      const store = new ProductStore(item, this.companyId)
      return new ProductItem(undefined, store)
    }))
  }
}
