import { DropdownItem } from 'component/Popover/Dropdown'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { CompanyLogo } from 'saas/screen/sidebar/CompanyLogo'
import { SideSelect } from 'saas/screen/sidebar/SideSelect'
import { home } from 'store/home'
import { emdash } from 'util/typo'

export const CompanyItem = observer(() => {
  const company = home.company.optional
  const text = company?.title || emdash

  const items: DropdownItem[] = home.companies.companies.map(co => {
    const json = co.company
    const id = json.company_id
    const selected = co === company
    const title = co.title
    const label = <Typo primary={selected}>{title}</Typo>
    const icon = <CompanyLogo company={co} />
    return { key: id, label, icon }
  })

  const onSelect = useLatestCallback((id: string) => {
    const company = home.companies.findCompany(id)
    if (company) void home.openCompany(company)
  })

  const icon = <CompanyLogo company={company} />

  return <SideSelect icon={icon} text={text} items={items} onClick={onSelect} placement="bottomLeft" />
})
