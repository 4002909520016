import { Card } from 'component/Card'
import { Input } from 'component/Input'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'
import { parseIntFinite } from 'util/number'

export const TimeoutInput = observer(() => {
  const { timeout } = tryon.it.edit
  const value = timeout?.toFixed() ?? ''

  const onChange = useLatestCallback((value: string) => {
    tryon.it.edit.timeout = parseIntFinite(value)
  })

  return <Card gap={8} pad={12} secondary vertical opposite minWidth={160}>
    <Typo size={13} medium>{i18n('widget.PauseUntilNextDataCollectionAttempt')}</Typo>
    <Input lazy value={value} placeholder="0" onChange={onChange}
      clear={false} suffix={i18n('widget.InHours')} />
  </Card>
})
