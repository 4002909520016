// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Banner-image-771c{max-width:100%}.Banner-pale-771c{opacity:.2}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/stand/SettingsCard/Banner.module.scss"],"names":[],"mappings":"AAAA,mBACE,cAAA,CAGF,kBACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `Banner-image-771c`,
	"pale": `Banner-pale-771c`
};
module.exports = ___CSS_LOADER_EXPORT___;
