// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopLogo-menu-badf{flex:0 0 auto;border:1px solid rgba(250,250,250,.1607843137);color:#fff;background-color:rgba(0,0,0,0)}.TopLogo-menu-badf:hover,.TopLogo-menu-badf:active{color:rgba(30,30,30,.8784313725) !important;background-color:#fff !important}.TopLogo-profile-badf{flex:0 0 auto;border:1px solid rgba(250,250,250,.1607843137);color:#fff;background-color:rgba(0,0,0,0);border-radius:12px}.TopLogo-profile-badf img{width:32px;height:32px;border-radius:6px}.TopLogo-profile-badf:hover,.TopLogo-profile-badf:active{color:rgba(30,30,30,.8784313725) !important;background-color:#fff !important}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/TopLogo.module.scss"],"names":[],"mappings":"AAAA,mBACE,aAAA,CACA,8CAAA,CACA,UAAA,CACA,8BAAA,CAEA,mDACE,2CAAA,CACA,gCAAA,CAIJ,sBACE,aAAA,CACA,8CAAA,CACA,UAAA,CACA,8BAAA,CAEA,kBAAA,CAEA,0BACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,yDACE,2CAAA,CACA,gCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": `TopLogo-menu-badf`,
	"profile": `TopLogo-profile-badf`
};
module.exports = ___CSS_LOADER_EXPORT___;
