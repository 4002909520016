import { Input as InputAntd, InputRef } from 'antd'
import { clsx } from 'clsx'
import { Typo } from 'component/Typo'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useFocus } from 'hook/useFocus'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import {
  ChangeEvent,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  ReactElement,
  type ReactNode,
  useRef,
  useState,
} from 'react'
import { setRef } from 'util/react'
import css from './Input.module.scss'

export type InputProps = {
  after?: ReactNode
  before?: ReactNode
  borderless?: boolean
  className?: string
  clear?: boolean
  disabled?: boolean
  error?: boolean
  focus?: boolean
  large?: boolean
  lazy?: boolean
  limit?: number
  mono?: boolean
  onChange?(value: string): void
  onFocus?(focus: boolean): void
  onKeyDown?(event: KeyboardEvent): void
  password?: boolean
  placeholder?: string
  prefix?: ReactNode
  readonly?: boolean
  small?: boolean
  style?: CSSProperties
  suffix?: string | ReactElement
  value: string
  wide?: boolean
  width?: number
}

export const Input = forwardRef((props: InputProps, ref: ForwardedRef<HTMLInputElement | HTMLElement>) => {
  const {
    after, before, borderless, className, clear, disabled,
    error, focus, large, lazy, limit, mono, onKeyDown,
    password, placeholder, prefix, readonly, small, width,
  } = props
  const [text, setText] = useState('')
  const refInner = useRef<HTMLInputElement | null>(null)

  const foc = useFocus(props)
  const onFocus = useLatestCallback(() => {
    setText(value)
    foc.onFocus()
  })

  const value = lazy && foc.focus ? text : props.value

  useEffectOnce(() => {
    if (focus) refInner.current?.focus()
  })

  const wide = props.wide || !!width
  const classes = clsx(className, css.input, mono && css.mono,
    borderless && css.borderless, wide && css.wide)
  const style = { ...props.style, maxWidth: width }
  const size = large ? 'large' : small ? 'small' : undefined
  const status = error ? 'error' : undefined
  const clearIcon = <Icon name="close" size={16} />
  const allowClear = clear === false ? undefined : { clearIcon }
  const type = password ? 'password' : undefined

  const suffix = typeof props.suffix === 'string' ? <Typo size={13} secondary>
    {props.suffix}
  </Typo> : props.suffix

  const onChange = useLatestCallback((event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value
    setText(text)
    props.onChange?.(text)
  })

  const onRef = useLatestCallback((input: InputRef | null) => {
    setRef(refInner, input?.input)
    setRef(ref, input?.input)
  })

  return <InputAntd
    addonAfter={after}
    addonBefore={before}
    allowClear={allowClear}
    className={classes}
    disabled={disabled}
    maxLength={limit}
    onBlur={foc.onBlur}
    onChange={onChange}
    onFocus={onFocus}
    onKeyDown={onKeyDown}
    placeholder={placeholder}
    prefix={prefix}
    readOnly={readonly}
    ref={onRef}
    size={size}
    status={status}
    style={style}
    suffix={suffix}
    type={type}
    value={value}
  />
})
