import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { DocSectionStore } from 'saas/page/doc/store/DocSectionStore'
import { doc } from 'saas/page/doc/store/DocStore'
import css from './DocSubtitle.module.scss'

type Props = {
  section: DocSectionStore
}

export const DocSubtitle = observer(({ section }: Props) => {
  const { expanded } = section

  const onHead = useLatestCallback((ref: HTMLElement | null) => {
    const child = section.data.head
    section.ref = ref ?? undefined
    if (ref && child) ref.append(child)
  })

  const onExpand = useLatestCallback(() => {
    doc.expand(section, !expanded)
  })

  return <Space gap={16} top opposite>
    {section.data.head && <Space className={css.head} ref={onHead}></Space>}
    <Space>
      <IconButton action={onExpand}>
        <Icon size={16} name={expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
      </IconButton>
    </Space>
  </Space>
})
