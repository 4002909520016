import { Card } from 'component/Card'
import { Checkbox } from 'component/Checkbox'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'

export const UiControls = observer(() => {
  const { edit } = stand.it
  const {
    brandFilter, styleFilter, genderFilter, priceSorting,
    shareCatalogButton, prices, buyButtons, brandNames, articles, search,
  } = edit

  const onChangeBrandFilter = useLatestCallback((value: boolean) => edit.brandFilter = value)
  const onChangeStyleFilter = useLatestCallback((value: boolean) => edit.styleFilter = value)
  const onChangeGenderFilter = useLatestCallback((value: boolean) => edit.genderFilter = value)
  const onChangePriceSorting = useLatestCallback((value: boolean) => edit.priceSorting = value)
  const onChangeShareCatalogButton = useLatestCallback((value: boolean) => edit.shareCatalogButton = value)
  const onChangePrices = useLatestCallback((value: boolean) => edit.prices = value)
  const onChangeBuyButtons = useLatestCallback((value: boolean) => edit.buyButtons = value)
  const onChangeBrandNames = useLatestCallback((value: boolean) => edit.brandNames = value)
  const onChangeArticles = useLatestCallback((value: boolean) => edit.articles = value)
  const onChangeSearch = useLatestCallback((value: boolean) => edit.search = value)

  return <Space gap={12} columns parallel wrap>
    <Card secondary pad={12} minWidth={200} vertical>
      <Typo size={13} semi>{i18n('stand.Filters')}</Typo>
      <Gap height={8} />
      <Checkbox checked={brandFilter} onChange={onChangeBrandFilter}>
        {i18n('stand.BrandFilter')}
      </Checkbox>
      <Gap height={4} />
      <Checkbox checked={styleFilter} onChange={onChangeStyleFilter}>
        {i18n('stand.StyleFilter')}
      </Checkbox>
      <Gap height={4} />
      <Checkbox checked={genderFilter} onChange={onChangeGenderFilter}>
        {i18n('stand.GenderFilter')}
      </Checkbox>
    </Card>

    <Card secondary pad={12} minWidth={200} vertical>
      <Typo size={13} semi>{i18n('stand.Interface')}</Typo>
      <Gap height={8} />
      <Checkbox checked={search} onChange={onChangeSearch}>
        {i18n('stand.SearchBar')}
      </Checkbox>
      <Gap height={4} />
      <Checkbox checked={priceSorting} onChange={onChangePriceSorting}>
        {i18n('stand.PriceSorting')}
      </Checkbox>
      <Gap height={4} />
      <Checkbox checked={shareCatalogButton} onChange={onChangeShareCatalogButton}>
        {i18n('stand.ShareCatalogButton')}
      </Checkbox>
      <Gap height={4} />
      <Checkbox checked={prices} onChange={onChangePrices}>
        {i18n('stand.Prices')}
      </Checkbox>
    </Card>

    <Card secondary pad={12} minWidth={200} vertical>
      <Typo size={13} semi>{i18n('stand.Items')}</Typo>
      <Gap height={8} />
      <Checkbox checked={buyButtons} onChange={onChangeBuyButtons}>
        {i18n('stand.BuyButtons')}
      </Checkbox>
      <Gap height={4} />
      <Checkbox checked={brandNames} onChange={onChangeBrandNames}>
        {i18n('stand.BrandNames')}
      </Checkbox>
      <Gap height={4} />
      <Checkbox checked={articles} onChange={onChangeArticles}>
        {i18n('stand.Articles')}
      </Checkbox>
    </Card>
  </Space>
})
