import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { KeyBox } from 'saas/page/company/manage/KeyBox'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { can } from 'store/can'

export const KeySection = observer(() => {
  const { company_id, keys } = companyEdit.it

  const onAdd = useLatestCallback(async () => {
    companyEdit.it.openKeyEditModal()
  })

  return <Vertical gap={16} wide>
    <Space gap={16} opposite>
      <Typo size={16} bold>
        {i18n('company.ApiKeys')}
      </Typo>
      {can.ManageApiKeys(company_id) && <Button icon={<Icon size={16} name="add_circle" />} primary action={onAdd}>
        {i18n('company.CreateApiKey')}
      </Button>}
    </Space>
    <Vertical gap={8} wide>
      {keys.empty && <Typo size={12} secondary>
        {i18n('company.NoApiKeys')}
      </Typo>}
      {keys.map(key => <KeyBox key={key.key} store={key} />)}
    </Vertical>
  </Vertical>
})
