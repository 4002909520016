// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyCard-image-a65a{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;height:120px}.CompanyCard-image-a65a>*{border-radius:var(--ui-border-radius-half);background:var(--ui-background-color-weak);max-width:100%;max-height:100%;overflow:hidden}.CompanyCard-blank-a65a{width:120px;height:120px;background-color:var(--ui-background-color-weak);border-radius:var(--ui-border-radius)}`, "",{"version":3,"sources":["webpack://./src/saas/page/companies/CompanyCard.module.scss"],"names":[],"mappings":"AAAA,wBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAEA,UAAA,CACA,YAAA,CAEA,0BACE,0CAAA,CACA,0CAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CAIJ,wBACE,WAAA,CACA,YAAA,CACA,gDAAA,CACA,qCAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `CompanyCard-image-a65a`,
	"blank": `CompanyCard-blank-a65a`
};
module.exports = ___CSS_LOADER_EXPORT___;
