import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Campaigns } from 'saas/page/product/bot/FeedCard/Campaigns/Campaigns'
import { NewForm } from 'saas/page/product/bot/FeedCard/NewForm/NewForm'

export const FeedCard = observer(() => {
  return <Vertical wide>
    <Gap height={24} />
    <Divider secondary />
    <Gap height={24} />
    <Space gap={16} opposite>
      <Space gap={8}>
        <Icon size={24} name="mail" secondary />
        <Typo size={15} semi>Mailing</Typo>
      </Space>
    </Space>
    <Gap height={16} />
    <NewForm />
    <Gap height={24} />
    <Campaigns />
  </Vertical>
})
