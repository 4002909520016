import { notice } from 'app/notice'
import { FormStore } from 'form/store/FormStore'
import { i18n } from 'i18n'
import { ModesCatalog } from 'modal/EditCollectionModal/ModesCatalog'
import { modal } from 'modal/index'
import { api } from 'store/api'
import { ArrayStore } from 'store/base/ArrayStore'
import { ImageFile } from 'store/base/ImageFile'
import { StringStore } from 'store/base/StringStore'
import { CollectionStore } from 'store/collection'
import { CollectionDataStore } from 'store/collection/CollectionDataStore'
import { home } from 'store/home'
import { me } from 'store/me'
import { BaseCollectionRequest, CreateCollectionRequest, UpdateCollectionRequest } from 'type/Collection'
import { split } from 'util/array'

export class EditCollectionModalStore {
  readonly creating: boolean
  readonly collectionId: string | undefined
  readonly collection: CollectionStore | undefined
  readonly data?: CollectionDataStore
  readonly form = new FormStore()
  readonly modesCatalog = new ModesCatalog()

  readonly name = this.form.field(new StringStore(''), { required: true })
  readonly title = this.form.field(new StringStore(''))
  readonly description = this.form.field(new StringStore(''))
  readonly link = this.form.field(new StringStore(''))
  readonly promoCodes = this.form.field(new ArrayStore<string>([]))
  readonly privateViewers = this.form.field(new ArrayStore<string>([]))
  readonly styleId = this.form.field(new StringStore(''))
  readonly gender = this.form.field(new StringStore(''))
  readonly countries = this.form.field(new ArrayStore<string>([]))
  readonly modes = this.form.field(new ArrayStore<string>([]))
  readonly images = this.form.field(new ArrayStore<ImageFile>([]))
  readonly tags = this.form.field(new ArrayStore<string>([]))

  constructor(collection?: CollectionStore) {
    this.creating = !collection
    this.collectionId = collection?.collectionId
    this.collection = collection
    if (collection) this.update(collection)
  }

  get sources(): string[] {
    return this.images.value.map(image => image.url)
  }

  onCheckMode = (checked: boolean, value: string) => {
    const modes = new Set(this.modes.value)
    const { modesCatalog } = this
    const group = modesCatalog.findValueGroup(value)

    if (group?.radio) {
      group.options.forEach(option => modes.delete(option.value))
    }

    if (checked) modes.add(value)
    else modes.delete(value)

    this.modes.value = Array.from(modes)
  }

  async create(): Promise<void> {
    const request = this.buildCreate()
    const response = await api.createCollection(request)
    const data = new CollectionDataStore(response)
    await this.uploadImages(response.collection_id, data)
    home.collections.it.collections.addFirst(new CollectionStore(data))
    notice.success(i18n('collection.CollectionCreated'))
  }

  async save(): Promise<void> {
    if (!this.collection) throw new Error('no collection')

    await this.removeImages()
    await this.uploadImages(this.collection.collectionId)

    const request = this.buildUpdate()
    const response = await api.updateCollection(request)

    const data = new CollectionDataStore(response)
    this.collection.updateData(data)
    notice.success(i18n('collection.CollectionUpdated'))
  }

  close(): void {
    if (modal.editCollectionModal.optional === this) {
      modal.editCollectionModal.close()
    }
  }

  private update(collection: CollectionStore): void {
    const { json, images } = collection.data

    this.name.value = json.collection_name ?? ''
    this.title.value = json.collection_title ?? ''
    this.description.value = json.description ?? ''
    this.link.value = json.links ?? ''
    this.promoCodes.value = split(json.promo_codes)
    this.privateViewers.value = split(json.private_viewers)
    this.styleId.value = json.collection_style_id ?? ''
    this.gender.value = json.collection_gender ?? ''
    this.countries.value = split(json.countries)
    this.modes.value = split(json.modes)
    this.images.value = images?.map(image => ImageFile.fromUrl(image)) ?? []
    this.tags.value = split(json.tags)
  }

  private buildCreate(): CreateCollectionRequest {
    const user_id = me.user.user_id
    const data = this.assembleData()
    return { user_id, ...data }
  }

  private buildUpdate(): UpdateCollectionRequest {
    if (!this.collectionId) throw new Error('no id')
    const user_id = me.user.user_id
    const collection_id = this.collectionId
    const data = this.assembleData()
    return { user_id, collection_id, ...data }
  }

  private assembleData(): BaseCollectionRequest {
    const company_id = home.company.optional?.company.company_id
    return {
      company_id,
      collection_name: this.name.value,
      collection_title: this.title.value,
      description: this.description.value,
      links: this.link.value,
      promo_codes: this.promoCodes.value.join(','),
      private_viewers: this.privateViewers.value.join(','),
      collection_style_id: this.styleId.value,
      collection_gender: this.gender.value,
      countries: this.countries.value.join(','),
      modes: this.modes.value.join(','),
      tags: this.tags.value.join(','),
    }
  }

  private async removeImages(): Promise<void> {
    const old = new Map(this.collection?.data.assets.map(image => [image.url, image]))
    const images = this.images.value.map(image => image.url)
    for (const image of images) {
      old.delete(image)
    }
    let errors = 0
    for (const [_, image] of old) {
      try {
        const { imageId } = image
        await api.deleteCollectionImage(imageId)
      } catch (e) {
        console.error('remove image failed', e)
        ++errors
      }
    }
    if (errors) notice.error(i18n('collection.FailedToDeleteCountImages', { count: errors }))
  }

  private async uploadImages(collectionId: string, data?: CollectionDataStore): Promise<void> {
    let errors = 0
    for (const image of this.images.value) {
      const file = image.file
      if (file) {
        try {
          const response = await api.uploadCollectionImage(collectionId, file)
          image.update(response.url)
          data?.addImage(response)
        } catch (e) {
          console.error('upload image failed', e)
          ++errors
        }
      }
    }
    if (errors) notice.error(i18n('collection.FailedToUploadCountImages', { count: errors }))
  }
}
