import { observer } from 'mobx-react-lite'
import { FC, HTMLAttributes, RefAttributes, useEffect, useMemo, useState } from 'react'

type Props = {
  child: ChildNode
}

function getChildren(node: Node): (ChildNode | string)[] {
  if (node instanceof Element) return Array.from(node.cloneNode(true).childNodes)

  const text = node.textContent?.replace(/\s/g, '') ?? ''
  return text ? [text] : []
}

type A = HTMLAttributes<unknown> & RefAttributes<unknown>

export const ChildContainer = observer(({ child }: Props) => {
  const [ref, setRef] = useState<HTMLElement | null>(null)
  const nodes = useMemo(() => getChildren(child), [child])

  useEffect(() => {
    if (!ref) return
    ref.innerHTML = ''
    ref.append(...nodes)
  }, [ref, nodes])

  const tag = child instanceof Element ? child.tagName.toLowerCase() : 'span'
  const Tag = tag as unknown as FC<A>
  const className = child instanceof Element ? child.className : ''

  if (!nodes.length) return null

  return <Tag ref={setRef} className={className || undefined} />
})
