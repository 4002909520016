import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'

export const ButtonInput = observer(() => {
  const { button } = bot.it.edit
  const value = button
  const error = !value

  const onChange = useLatestCallback((value: string) => {
    bot.it.edit.button = value
  })

  return <Vertical gap={6}>
    <Field label={i18n('bot.MenuButtonTitle')}>
      <Input mono lazy value={value} onChange={onChange} error={error} />
    </Field>
  </Vertical>
})
