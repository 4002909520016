// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ImageStack-stack-b870{display:flex;flex-direction:row-reverse;justify-content:left}.ImageStack-image-b870{width:60px;height:100px;border-radius:var(--ui-border-radius-half);border:2px solid var(--ui-background-color);background-color:var(--ui-background-color-soft);overflow:hidden}.ImageStack-image-b870:not(:last-child){margin-left:-16px}@media(width < 300px){.ImageStack-image-b870:nth-child(1){display:none}}@media(width < 255px){.ImageStack-image-b870:nth-child(2){display:none}}@media(width < 210px){.ImageStack-image-b870:nth-child(3){display:none}}@media(width < 165px){.ImageStack-image-b870:nth-child(4){display:none}}`, "",{"version":3,"sources":["webpack://./src/page/internal/collections/ImageStack/ImageStack.module.scss"],"names":[],"mappings":"AAAA,uBACE,YAAA,CACA,0BAAA,CACA,oBAAA,CAGF,uBACE,UAAA,CACA,YAAA,CACA,0CAAA,CACA,2CAAA,CACA,gDAAA,CACA,eAAA,CAGF,wCACE,iBAAA,CAIA,sBADF,oCAEI,YAAA,CAAA,CAKF,sBADF,oCAEI,YAAA,CAAA,CAKF,sBADF,oCAEI,YAAA,CAAA,CAKF,sBADF,oCAEI,YAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stack": `ImageStack-stack-b870`,
	"image": `ImageStack-image-b870`
};
module.exports = ___CSS_LOADER_EXPORT___;
