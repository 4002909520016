import { Card } from 'component/Card'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Text } from 'component/Typo/Text'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ImageStack } from 'page/internal/collections/ImageStack/ImageStack'
import { StatsSection } from 'page/internal/collections/StatsSection'
import { CollectionStore } from 'store/collection'
import { home } from 'store/home'
import { formatDate, parseDate } from 'util/date'
import { emdash, space } from 'util/typo'
import css from './CollectionCard.module.scss'

type Props = {
  collection: CollectionStore
}

export const CollectionCard = observer(({ collection }: Props) => {
  const { name, json, stats } = collection.data
  const { description } = json
  const updated = parseDate(json.updated_at)

  const onOpen = useLatestCallback(async () => {
    await home.openCollection(collection)
  })

  return <Card vertical pad={12} className={css.card} secondary action={onOpen} gap={16} opposite>
    <Vertical>
      <ImageStack collection={collection} />
      <Gap height={12} />
      <Text size={15} semi line={1.3} rows={2}>
        {name}
      </Text>
      <Gap height={4} />
      <Typo size={11} secondary>
        {i18n('label.LastUpdate')}
        {space}
        {formatDate(updated) ?? emdash}
      </Typo>
      {description && <Gap height={4} />}
      {description && <Text size={13} rows={3}>
        {description}
      </Text>}
    </Vertical>
    {stats && <Vertical>
      <Divider secondary />
      <Gap height={12} />
      <StatsSection stats={stats} />
    </Vertical>}
  </Card>
})
