import { Field } from 'component/Field'
import { Option, Select } from 'component/Select/Select'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productCreate } from 'saas/store/product/ProductCreateStore'
import { getProductTypeTitle, PRODUCT_TYPES, ProductType } from 'type/product/ProductType'

export const TypeSelect = observer(() => {
  const { value, error, onChange } = productCreate.it.type

  const options: Option<never, ProductType>[] = [
    ...PRODUCT_TYPES.map(type => ({
      value: type,
      label: getProductTypeTitle(type),
    })),
    {
      label: i18n('product.API'),
      disabled: true,
    },
  ]

  return <Field label={i18n('product.ProductType')}>
    <Select value={value} options={options} onChange={onChange} error={error} wide allowClear={false} />
  </Field>
})
