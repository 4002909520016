import { AlignType } from '@rc-component/trigger/lib/interface'
import { Popover as PopoverAntd } from 'antd'
import type { TooltipPlacement } from 'antd/es/tooltip'
import { clsx } from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { ReactNode } from 'react'
import { getPaddingStyle, Padding, styles } from 'util/style'
import css from './Popover.module.scss'
import { PopoverControl } from './PopoverControl'

export type Placement = TooltipPlacement
export type Trigger = 'hover' | 'focus' | 'click' | 'contextMenu';

type Props = {
  className?: string
  children: ReactNode
  content: ReactNode | (() => ReactNode)
  control?: PopoverControl
  pad?: Padding
  raw?: boolean
  rootClassName?: string
  space?: number
  stretch?: boolean
  placement?: Placement
  trigger?: Trigger | Trigger[];
}

function getPlacement(props: Props): Placement {
  return props.placement ?? 'bottom'
}

function getOffset(placement: string, space: number): [number, number] {
  if (placement.startsWith('top')) {
    return [0, -space]
  } else if (placement.startsWith('bottom')) {
    return [0, space]
  } else if (placement.startsWith('left')) {
    return [-space, 0]
  } else if (placement.startsWith('right')) {
    return [space, 0]
  }
  console.error('unexpected placement', placement)
  return [0, 0]
}

function getAlign(props: Props): AlignType {
  const { space } = props
  const placement = getPlacement(props)
  const offset = getOffset(placement, space ?? 8)
  return { offset }
}

export const Popover = (props: Props) => {
  const { className, children, content, control, pad, raw, stretch } = props
  const { open, setOpen } = control ?? {}

  const align = getAlign(props)
  const placement = getPlacement(props)
  const triggerProps = stretch ? { stretch: 'width' } : {}
  const overlayInnerStyle = styles(getPaddingStyle(pad ?? 8))
  const trigger = props.trigger ? props.trigger : 'click'
  const rootClassName = clsx(props.rootClassName, raw ? css.raw : css.popover)

  const onOpenChange = useLatestCallback((open: boolean) => {
    return setOpen?.(open)
  })

  return <PopoverAntd className={className} rootClassName={rootClassName} arrow={false}
    overlayInnerStyle={overlayInnerStyle} align={align}
    open={open} content={content} onOpenChange={onOpenChange}
    trigger={trigger} placement={placement} destroyTooltipOnHide {...triggerProps}>
    <div className={css.child}>{children}</div>
  </PopoverAntd>
}
