import { getWindowHost } from 'app/config/window'
import { FormStore } from 'form/store/FormStore'
import { i18n } from 'i18n'
import { makeAutoObservable, observable } from 'mobx'
import { StringStore } from 'store/base/StringStore'
import { BotProduct } from 'type/product/BotProduct'
import { TelegramBotMe, TelegramResponse } from 'type/Telegram'
import { fixText } from 'util/form'
import { equal, updateObject } from 'util/object'

export class BotEditStore {
  private _json: BotProduct
  private form = new FormStore()
  readonly name = this.form.field(new StringStore(), { required: true, fix: fixText })
  private _bot_token = ''
  bot_id: number | undefined
  bot_username = ''
  bot_error = ''
  private _window = ''
  private _button = ''

  constructor(json: BotProduct) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
    this.applyJson(json)
  }

  get json(): BotProduct {
    return this._json
  }

  get bot_token(): string | undefined {
    return this._bot_token
  }

  set bot_token(value: string | undefined) {
    this.bot_error = ''
    this.bot_id = undefined
    this._bot_token = value || ''
  }

  get window(): string {
    return this._window
  }

  set window(value: string) {
    this._window = value
  }

  get button(): string {
    return this._button
  }

  set button(value: string) {
    this._button = value
  }

  get changed(): boolean {
    const updated = updateObject(this.json, this.updates)
    return !equal(this.json, updated)
  }

  get canSave(): boolean {
    return !this.form.error
  }

  check() {
    return this.form.check() && this.canSave
  }

  update(json: BotProduct) {
    this._json = json
    this.applyJson(json)
  }

  reset() {
    this.applyJson(this._json)
  }

  async connect() {
    const bot = await this.getBotId()
    if (bot) await this.setupBotMenu()
    this.connection(bot)
  }

  private connection(bot: TelegramBotMe | undefined) {
    if (bot) {
      this.bot_id = bot.id
      this.bot_username = bot.username
    } else {
      this.bot_id = undefined
      this.bot_username = ''
      if (this.bot_token) this.bot_error = i18n('bot.FailedToConnectToTheBot')
    }
  }

  private async getBotId(): Promise<TelegramBotMe | undefined> {
    const token = this.bot_token
    if (!token) return undefined
    try {
      const response = await fetch(`https://api.telegram.org/bot${token}/getMe`)
      const json: TelegramResponse<TelegramBotMe> = await response.json()
      return json.result
    } catch (e) {
      console.error('bot connect failed', e)
      return undefined
    }
  }

  private async setupBotMenu(): Promise<void> {
    const token = this.bot_token
    if (!token) return
    try {
      const request = this.buildMenuRequest()
      await fetch(`https://api.telegram.org/bot${token}/setChatMenuButton`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
      })
    } catch (e) {
      console.error('bot menu setup failed', e)
    }
  }

  private buildMenuRequest() {
    const { window, button } = this
    if (!window) return {}
    const host = getWindowHost()
    const url = `${host}/${window}`
    return {
      menu_button: {
        type: 'web_app',
        text: button || 'SHOP',
        web_app: { url },
      },
    }
  }

  get updates(): Partial<BotProduct> {
    const published = !!this.bot_id
    const name = this.name.value || undefined
    const bot_token = this.bot_token || undefined
    const bot_id = this.bot_id || undefined
    const bot_username = this.bot_username || undefined
    const window_product_id = this.window || undefined
    const bot_button_text = this.button || undefined
    return { name, published, bot_token, bot_id, bot_username, window_product_id, bot_button_text }
  }

  private applyJson(json: BotProduct) {
    this.name.value = json.name || ''
    this._bot_token = json.bot_token || ''
    this.bot_id = json.bot_id
    this.bot_username = json.bot_username || ''
    this._window = json.window_product_id || ''
    this._button = json.bot_button_text || ''
  }
}
