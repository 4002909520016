import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { IconInput } from 'saas/page/product/stand/IconInput/IconInput'
import { LogoInput } from 'saas/page/product/stand/LogoInput/LogoInput'
import { ShopButtonInput } from 'saas/page/product/stand/SettingsCard/ShopButtonInput'
import { ShopUrlInput } from 'saas/page/product/stand/SettingsCard/ShopUrlInput'
import { stand } from 'saas/store/product/ProductSaasController'

export const AppearanceSettings = observer(() => {
  const { edit } = stand.it
  const { title } = edit

  return <Space gap={[16, 32]} top wrap>
    <Vertical flex="1 1 240px">
      <TextField field={title} label={i18n('stand.Title')} placeholder="" />
      <Gap height={12} />
      <ShopUrlInput />
      <Gap height={12} />
      <ShopButtonInput />
    </Vertical>
    <Space gap={12} wrap>
      <LogoInput />
      <IconInput />
    </Space>
  </Space>
})
