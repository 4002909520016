import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { KeyEditModal } from 'saas/page/company/modal/KeyEditModal/KeyEditModal'
import { ApyKeyModal } from 'saas/page/product/tryon/ApiKey/ApyKeyModal'
import { EmbedCode } from 'saas/page/product/tryon/Details/EmbedCode'
import { Status } from 'saas/page/product/tryon/Details/Status'
import { keyModal } from 'saas/store/key/ApiKeyModalStore'
import { tryon } from 'saas/store/product/ProductSaasController'
import css from './Details.module.scss'

export const Details = observer(() => {
  return <Space className={css.root} gap={16} top wide wrap oh>
    <Status />
    <EmbedCode />
    {keyModal.present ? <KeyEditModal /> : tryon.it.keyEdit ? <ApyKeyModal /> : null}
  </Space>
})
