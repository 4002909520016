import { Scroll, ScrollRef } from 'component/Scroll'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useEffectOnce } from 'hook/useEffectOnce'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { commenting } from 'store/comment/CommentingStore'
import { CommentItem } from './CommentItem'
import css from './CommentsList.module.scss'

export const CommentsList = observer(() => {
  const store = commenting.comments
  const { inline } = commenting
  const comments = store.comments.it
  const length = comments.length

  const ref = useRef<ScrollRef>(null)

  useEffectOnce(() => {
    void store.reload()
  })

  useEffect(() => {
    if (!length) return
    const content = ref.current?.osInstance()?.elements()?.content
    if (!content) console.error('no ref')
    if (content) content.scrollTop = 1e9
  }, [length])

  if (!length) {
    return <Space height={200} center>
      <Vertical gap={4} center>
        <Typo size={13}>{i18n('comment.NoCommentsYet')}</Typo>
        <Typo size={13} secondary>{i18n('comment.LeaveFirstComment')}</Typo>
      </Vertical>
    </Space>
  }

  if (inline) {
    return <Vertical gap={16} pad={[8, 4]}>
      {comments.map(comment => <CommentItem key={comment.key} comment={comment} />)}
    </Vertical>
  }

  return <Scroll ref={ref} className={css.scroll}>
    <Vertical gap={16}>
      {comments.map(comment => <CommentItem key={comment.key} comment={comment} />)}
    </Vertical>
  </Scroll>
})
