import { Space } from 'component/Space'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { CompanyStore } from 'store/company/CompanyStore'
import css from './CompanyLogo.module.scss'

type Props = {
  company: CompanyStore | undefined
}

export const CompanyLogo = observer(({ company }: Props) => {
  const image = company?.company.logo_url

  if (image) return <img className={css.image} src={image} />

  return <Space className={css.space} center>
    <Icon size={16} name="apartment" />
  </Space>
})
