import './theme/github.css'
import { Affix } from 'antd'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { AgendaCard } from 'saas/page/doc/AgendaCard'
import { DocCard } from 'saas/page/doc/DocCard'
import { SettingsCard } from 'saas/page/doc/SettingsCard'
import { doc } from 'saas/page/doc/store/DocStore'
import { Page2 } from 'saas/screen/page/Page2/Page2'
import { ui } from 'store/home/ui/HomeUiStore'
import { route } from 'store/route'
import { getDocTitle } from 'type/Doc'
import { renderLoadingState } from 'util/render'

export const DocPage = observer(() => {
  const { mobile } = ui.window
  const path = route.pathname
  useMemo(() => void doc.update(path), [path])
  const stub = renderLoadingState(doc.state)

  useEffect(() => {
    doc.expandAll(!mobile)
  }, [mobile])

  if (stub) return stub

  return <Page2>
    <Vertical gap={12}>
      <Link path={`/docs`} small>
        <Space gap={8} oh>
          <Icon size={16} name="arrow_back" />
          <Typo size={13} ellipsis>{i18n('docs.ToListOfDocuments')}</Typo>
        </Space>
      </Link>
      <Typo size={23} bold>{getDocTitle(path)}</Typo>
    </Vertical>
    <Space gap={16} top wide>
      <Vertical gap={16} margin={mobile ? [0, -16] : 0} width={0} minWidth={320} flex="1 1 0">
        <SettingsCard />
        <DocCard />
      </Vertical>
      {!mobile && <Space>
        <Affix offsetTop={16}>
          <div>
            <AgendaCard />
          </div>
        </Affix>
      </Space>}
    </Space>
  </Page2>
})
