import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { Details } from 'saas/page/product/bot/Details/Details'
import { FeedCard } from 'saas/page/product/bot/FeedCard/FeedCard'
import { PreviewCard } from 'saas/page/product/bot/PreviewCard/PreviewCard'
import { SettingsCard } from 'saas/page/product/bot/SettingsCard/SettingsCard'
import { View } from 'saas/page/product/common/View/View'
import { groupPreviewSaas, GroupPreviewStore } from 'saas/store/groups/GroupPreviewStore'
import { bot } from 'saas/store/product/ProductSaasController'
import { StandProductStore } from 'saas/store/product/stand/StandProductStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'

function findGroupId() {
  const product_id = bot.it.json.window_product_id
  const product = !product_id ? undefined : productsSaas.it.products.find(it => it.product_id === product_id)
  return product instanceof StandProductStore ? product.json.group : undefined
}

export const BotView = observer(() => {
  const group_id = findGroupId()

  useMemo(() => {
    void bot.it.load()
  }, [])

  useMemo(() => {
    if (group_id) groupPreviewSaas.open(new GroupPreviewStore(group_id))
    else groupPreviewSaas.close()
  }, [group_id])

  return <View>
    <Vertical pad={16} wide>
      <SettingsCard />
      <FeedCard />
      {groupPreviewSaas.present && <PreviewCard />}
    </Vertical>
    <Details />
  </View>
})
