import { Card } from 'component/Card'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { StatusTag } from 'saas/page/product/common/StatusTag/StatusTag'

export const Status = observer(() => {
  return <Vertical maxWidth={480}>
    <Typo size={15} semi>
      {i18n('product.PublishStatus')}
    </Typo>
    <Gap height={12} />
    <Card pad={16} secondary vertical top medium>
      <Typo size={13} semi>
        {i18n('bot.ConnectBot')}
      </Typo>
      <Gap height={12} />
      <StatusTag />
    </Card>
  </Vertical>
})
