// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SaasScreen-screen-ead2{--transition: 200ms;--sidebar-background: #1B182E;--sidebar-text-color: rgba(250, 250, 250, 0.8);--sidebar-text-color-hover: rgba(250, 250, 250, 1);--sidebar-item-background: rgba(250, 250, 250, 0.08);--sidebar-item-icon-background: rgba(250, 250, 250, 0.04);--sidebar-item-icon-background-hover: rgba(250, 250, 250, 1)}.SaasScreen-content-ead2{background-color:#f5f5f5}@media(width >= 760px){.SaasScreen-screen-ead2{position:relative;display:flex}.SaasScreen-center-ead2{display:flex;flex-direction:column;flex:1 1 0;min-height:100vh}.SaasScreen-content-ead2{flex:1 0 auto}}@media(width < 760px){.SaasScreen-screen-ead2{position:relative;display:flex;flex-direction:column;min-height:100vh}.SaasScreen-center-ead2{display:flex;flex-direction:column;flex:1 1 0}.SaasScreen-content-ead2{flex:1 0 auto}}`, "",{"version":3,"sources":["webpack://./src/saas/screen/SaasScreen.module.scss"],"names":[],"mappings":"AAAA,wBACE,mBAAA,CACA,6BAAA,CACA,8CAAA,CACA,kDAAA,CACA,oDAAA,CACA,yDAAA,CACA,4DAAA,CAGF,yBACE,wBAAA,CAGF,uBACE,wBACE,iBAAA,CACA,YAAA,CAGF,wBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,gBAAA,CAGF,yBACE,aAAA,CAAA,CAIJ,sBACE,wBACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,gBAAA,CAGF,wBACE,YAAA,CACA,qBAAA,CACA,UAAA,CAGF,yBACE,aAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen": `SaasScreen-screen-ead2`,
	"content": `SaasScreen-content-ead2`,
	"center": `SaasScreen-center-ead2`
};
module.exports = ___CSS_LOADER_EXPORT___;
