import { security } from 'app/controller/security'
import { clsx } from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Connection } from 'type/Connection'
import css from './ConnectButton.module.scss'

type Props = {
  index: number
  connection: Connection
}

function getIconClass(name: string) {
  switch (name) {
    case 'apple':
      return css.apple
    case 'discord':
      return css.discord
    case 'email':
      return css.email
    case 'Username-Password-Authentication':
      return css.email
    case 'facebook':
      return css.facebook
    case 'google-oauth2':
      return css.google
    default:
      return css.unknown
  }
}

function getTitle(connection: Connection): string {
  switch (connection.name) {
    case 'email':
      return i18n('login.SignInWithCode')
    case 'Username-Password-Authentication':
      return i18n('login.SignInViaEmail')
    default:
      return connection.title
  }
}

export function ConnectButton({ index, connection }: Props) {
  const { name, url } = connection
  const className = clsx(css.button, getIconClass(name))
  const style = { animationDelay: `${index * 0.1}s` }

  const onClick = useLatestCallback(() => {
    security.login(url)
  })

  return (
    <div className={className} style={style} onClick={onClick}>
      <span className={css.icon} />
      <span className={css.title}>{getTitle(connection)}</span>
    </div>
  )
}
