import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Vertical } from 'component/Vertical'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'

export const NewForm = observer(() => {
  const { name, message } = bot.it.feed

  const onCreate = useLatestCallback(async () => {
    await bot.it.createFeed()
  })

  return <Vertical wide>
    <TextField field={name} label={i18n('label.Name')} />
    <Gap height={12} />
    <TextField rows={4} field={message} label="Message" />
    <Gap height={12} />
    <Space>
      <Button primary action={onCreate}>
        {i18n('common.Create')}
      </Button>
    </Space>
  </Vertical>
})
