import { Button } from 'component/Button'
import { PageHeader } from 'component/PageHeader'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { groupsSaas } from 'saas/store/groups/GroupsCache'

export const Title = observer(() => {
  const { busy } = groupsSaas.it

  const onCreate = useLatestCallback(() => {
    void groupsSaas.it.createGroup()
  })

  return (
    <PageHeader title={i18n('title.ItemGroups')}>
      <Button
        icon={<Icon size={16} name="add_circle" />}
        primary
        disabled={busy}
        action={onCreate}
      >
        {i18n('group.CreateGroup')}
      </Button>
    </PageHeader>
  )
})
