import { production } from 'app/config/constants'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Page2 } from 'saas/screen/page/Page2/Page2'
import { DocCard } from './DocCard'

export const DocsPage = observer(() => {
  return <Page2>
    <Space>
      <Typo size={23} bold>{i18n('docs.Documentation')}</Typo>
    </Space>
    <Space gap={16} wrap>
      <DocCard path="/docs/api" />
      <DocCard path="/docs/widget" />
      {!production && <DocCard path="/docs/new.api" />}
    </Space>
  </Page2>
})
