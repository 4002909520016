import { Button } from 'component/Button'
import { Table } from 'component/Table'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { BotFeedStore } from 'saas/store/product/bot/BotFeedStore'
import { cfg } from 'store/cfg'
import { formatDateTime, parseDate } from 'util/date'

type Props = {
  feed: BotFeedStore
}

export const FeedRow = observer(({ feed }: Props) => {
  const { bot_feed } = cfg.cfg
  const {
    campaign_name,
    updated_at,
    campaign_status,
  } = feed.json
  const canStart = !!bot_feed && campaign_status === 'created'

  const onStart = useLatestCallback(async () => {
    await feed.start()
  })

  return (
    <Table.Tr>
      <Table.Td>
        <Typo size={13} wrap>
          {campaign_name}
        </Typo>
      </Table.Td>
      <Table.Td>
        {formatDateTime(parseDate(updated_at))}
      </Table.Td>
      <Table.Td>
        {campaign_status}
      </Table.Td>
      <Table.Td>
        {canStart && <Button action={onStart}>
          Start
        </Button>}
      </Table.Td>
    </Table.Tr>
  )
})
