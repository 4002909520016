import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { DocSectionStore } from 'saas/page/doc/store/DocSectionStore'
import { doc } from 'saas/page/doc/store/DocStore'
import css from './AgendaItem.module.scss'

type Props = {
  section: DocSectionStore
}

export const AgendaItem = observer(({ section }: Props) => {
  const shift = section.data.level - doc.minLevel
  const left = shift * 16

  const onHead = useLatestCallback((ref: HTMLElement | null) => {
    if (!ref) return
    const child = section.data.head
    ref.innerHTML = ''
    if (child) ref.append(child.cloneNode(true))
  })

  const onClick = useLatestCallback(() => {
    doc.show(section)
  })

  return <Space ref={onHead} className={css.item} top opposite
    margin={[0, 0, 0, left]} onClick={onClick} />
})
