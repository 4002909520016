// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hljs{color:#24292e;background:#fff}.hljs-doctag,.hljs-keyword,.hljs-meta .hljs-keyword,.hljs-template-tag,.hljs-template-variable,.hljs-type,.hljs-variable.language_{color:#d73a49}.hljs-title,.hljs-title.class_,.hljs-title.class_.inherited__,.hljs-title.function_{color:#6f42c1}.hljs-attr,.hljs-attribute,.hljs-literal,.hljs-meta,.hljs-number,.hljs-operator,.hljs-variable,.hljs-selector-attr,.hljs-selector-class,.hljs-selector-id{color:#005cc5}.hljs-regexp,.hljs-string,.hljs-meta .hljs-string{color:#032f62}.hljs-built_in,.hljs-symbol{color:#e36209}.hljs-comment,.hljs-code,.hljs-formula{color:#6a737d}.hljs-name,.hljs-quote,.hljs-selector-tag,.hljs-selector-pseudo{color:#22863a}.hljs-subst{color:#24292e}.hljs-section{color:#005cc5;font-weight:bold}.hljs-bullet{color:#735c0f}.hljs-emphasis{color:#24292e;font-style:italic}.hljs-strong{color:#24292e;font-weight:bold}.hljs-addition{color:#22863a;background-color:#f0fff4}.hljs-deletion{color:#b31d28;background-color:#ffeef0}`, "",{"version":3,"sources":["webpack://./src/saas/page/doc/theme/github.css"],"names":[],"mappings":"AAEA,MACE,aAAA,CACA,eAAA,CAGF,mIAQE,aAAA,CAGF,oFAKE,aAAA,CAGF,0JAWE,aAAA,CAGF,kDAIE,aAAA,CAGF,4BAGE,aAAA,CAGF,uCAIE,aAAA,CAGF,gEAKE,aAAA,CAGF,YAEE,aAAA,CAGF,cAEE,aAAA,CACA,gBAAA,CAGF,aAEE,aAAA,CAGF,eAEE,aAAA,CACA,iBAAA,CAGF,aAEE,aAAA,CACA,gBAAA,CAGF,eAEE,aAAA,CACA,wBAAA,CAGF,eAEE,aAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hljs": `hljs`,
	"hljsDoctag": `hljs-doctag`,
	"hljsKeyword": `hljs-keyword`,
	"hljsMeta": `hljs-meta`,
	"hljsTemplateTag": `hljs-template-tag`,
	"hljsTemplateVariable": `hljs-template-variable`,
	"hljsType": `hljs-type`,
	"hljsVariable": `hljs-variable`,
	"language": `language_`,
	"hljsTitle": `hljs-title`,
	"class": `class_`,
	"inherited": `inherited__`,
	"function": `function_`,
	"hljsAttr": `hljs-attr`,
	"hljsAttribute": `hljs-attribute`,
	"hljsLiteral": `hljs-literal`,
	"hljsNumber": `hljs-number`,
	"hljsOperator": `hljs-operator`,
	"hljsSelectorAttr": `hljs-selector-attr`,
	"hljsSelectorClass": `hljs-selector-class`,
	"hljsSelectorId": `hljs-selector-id`,
	"hljsRegexp": `hljs-regexp`,
	"hljsString": `hljs-string`,
	"hljsBuiltIn": `hljs-built_in`,
	"hljsSymbol": `hljs-symbol`,
	"hljsComment": `hljs-comment`,
	"hljsCode": `hljs-code`,
	"hljsFormula": `hljs-formula`,
	"hljsName": `hljs-name`,
	"hljsQuote": `hljs-quote`,
	"hljsSelectorTag": `hljs-selector-tag`,
	"hljsSelectorPseudo": `hljs-selector-pseudo`,
	"hljsSubst": `hljs-subst`,
	"hljsSection": `hljs-section`,
	"hljsBullet": `hljs-bullet`,
	"hljsEmphasis": `hljs-emphasis`,
	"hljsStrong": `hljs-strong`,
	"hljsAddition": `hljs-addition`,
	"hljsDeletion": `hljs-deletion`
};
module.exports = ___CSS_LOADER_EXPORT___;
