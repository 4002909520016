import { DropdownItem } from 'component/Popover/Dropdown'
import { observer } from 'mobx-react-lite'
import { buildProfileItems } from 'saas/screen/sidebar/ProfileDropdown'
import { SideSelect } from 'saas/screen/sidebar/SideSelect'
import { UserLogo } from 'saas/screen/sidebar/UserLogo'
import { me } from 'store/me'

export const UserItem = observer(() => {
  const text = me.user.name
  const items: DropdownItem[] = buildProfileItems()

  const icon = <UserLogo />
  return <SideSelect icon={icon} text={text} items={items} placement="topLeft" />
})
