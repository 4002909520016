import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { CommentStore } from 'store/comment/CommentStore'
import { getReviewStatusLabel } from 'type/ExternalStatus'
import { getItemStatusLabel } from 'type/InternalStatus'
import { emdash } from 'util/typo'

type Props = {
  comment: CommentStore
}

export function getCommentContent(comment: CommentStore): string {
  const { like, system, int_status, ext_status } = comment
  const message = comment.message?.replace(/\s+/g, ' ').trim() ?? ''

  if (like) {
    if (like === 1) return '\u{1F44D}'
    if (like === 2) return '\u{1F44E}'
    return emdash
  }

  if (system) {
    const status = int_status && '' +
      i18n('label.ItemStatus') +
      ': ' +
      (getItemStatusLabel(int_status.from) ?? i18n('moderation.None')) +
      ' \u2192 ' +
      (getItemStatusLabel(int_status.to) ?? i18n('moderation.None'))

    const review = ext_status && '' +
      i18n('label.ReviewStatus') +
      ': ' +
      (getReviewStatusLabel(ext_status.from) ?? i18n('review.None')) +
      ' \u2192 ' +
      (getReviewStatusLabel(ext_status.to) ?? i18n('review.None'))

    return [status, review].join('\n') || emdash
  }

  return message || emdash
}

export const CommentContent = observer(({ comment }: Props) => {
  const { message, like, system, int_status, ext_status } = comment
  const empty = message?.trim().length === 0

  if (like) {
    return <Typo size={12} primary={like === 1} error={like === 2}>
      {like === 1 && <Icon name="thumb_up" size={16} />}
      {like === 2 && <Icon name="thumb_down" size={16} />}
    </Typo>
  }

  if (system) {
    return <Vertical gap={4}>
      {int_status && <Space gap={4} wrap>
        <Typo size={12} wrap secondary>
          {i18n('label.ItemStatus')}
        </Typo>
        <Space gap={4} wrap>
          <Space gap={4} wrap>
            <Typo size={12} wrap>
              {getItemStatusLabel(int_status.from) ?? i18n('moderation.None')}
            </Typo>
            <Typo size={12} tertiary>{'\u2192'}</Typo>
          </Space>
          <Typo size={12} wrap>
            {getItemStatusLabel(int_status.to) ?? i18n('moderation.None')}
          </Typo>
        </Space>
      </Space>}
      {ext_status && <Space gap={4} wrap>
        <Typo size={12} wrap secondary>
          {i18n('label.ReviewStatus')}
        </Typo>
        <Space gap={4} wrap>
          <Space gap={4} wrap>
            <Typo size={12} wrap>
              {getReviewStatusLabel(ext_status.from) ?? i18n('review.None')}
            </Typo>
            <Typo size={12} tertiary>{'\u2192'}</Typo>
          </Space>
          <Typo size={12} wrap>
            {getReviewStatusLabel(ext_status.to) ?? i18n('review.None')}
          </Typo>
        </Space>
      </Space>}
    </Vertical>
  }

  return <Typo size={12} pre wrap tertiary={empty}>
    {empty ? emdash : message}
  </Typo>
})
