import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Table } from 'component/Table'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Item } from 'saas/page/group/preview/Item'
import { Pages } from 'saas/page/group/preview/Pages'
import { groupPreviewSaas } from 'saas/store/groups/GroupPreviewStore'

export const Items = observer(() => {
  const { busy, visibleItems } = groupPreviewSaas.it
  const empty = !visibleItems.length

  if (busy) {
    return <Space height={400} center>
      <Spinner />
    </Space>
  }

  if (empty) {
    return <Space height={400} center>
      <Typo size={14} secondary>
        {i18n('item.NoItems')}
      </Typo>
    </Space>
  }

  return <Vertical gap={12}>
    <Table>
      <Table.THead>
        <Table.Td>{i18n('label.Title')}</Table.Td>
        <Table.Td>{i18n('label.Gender')}</Table.Td>
        <Table.Td>{i18n('collection.Brand')}</Table.Td>
      </Table.THead>
      <Table.TBody>
        {visibleItems.map((item) => (
          <Item key={item.item_id} item={item} />
        ))}
      </Table.TBody>
    </Table>
    <Pages />
  </Vertical>
})
