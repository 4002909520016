import { CopyButton } from 'component/Button/CopyButton'
import { Card } from 'component/Card'
import { Image } from 'component/Image'
import { Space } from 'component/Space'
import { Table } from 'component/Table'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { GroupItem } from 'type/ItemGroup'
import { capitalize } from 'util/string'
import { emdash } from 'util/typo'

type ItemProps = {
  item: GroupItem
}

export const Item = observer(({ item }: ItemProps) => {
  const {
    product_name,
    brand_name,
    dress_gender,
    article,
    item_id,
    external_item_id,
    images,
  } = item
  const sources = images ?? []
  const source = sources[0]

  return (
    <Table.Tr>
      <Table.Td>
        <Space gap={12}>
          <Card height={64} width={34} style={{ minWidth: 34 }} pic shadow>
            {source && <Image src={source} sources={sources} />}
          </Card>
          <Vertical>
            <Typo wrap size={13} ellipsis tertiary={!product_name}>
              {product_name || emdash}
            </Typo>
            <Typo size={10} secondary>
              {i18n('label.Article')}
            </Typo>
            <Space gap={4}>
              <Typo size={10} secondary tertiary={!article} ellipsis>
                {article || emdash}
              </Typo>
              {article && <CopyButton data={article} />}
            </Space>
          </Vertical>
        </Space>
      </Table.Td>
      <Table.Td>
        <Vertical gap={4}>
          <Typo size={13} ellipsis tertiary={!dress_gender}>
            {capitalize(dress_gender) || emdash}
          </Typo>
          <Typo size={10} secondary>
            {i18n('label.ItemId')}
          </Typo>
          <Space gap={4}>
            <Typo size={10} secondary tertiary={!item_id} ellipsis>
              {item_id || emdash}
            </Typo>
            {item_id && <CopyButton data={item_id} />}
          </Space>
        </Vertical>
      </Table.Td>
      <Table.Td>
        <Vertical gap={4}>
          <Typo size={13} ellipsis tertiary={!brand_name}>
            {brand_name || emdash}
          </Typo>

          <Typo size={10} secondary>
            {i18n('label.Sku')}
          </Typo>
          <Space gap={4}>
            <Typo size={10} secondary tertiary={!external_item_id} ellipsis>
              {external_item_id || emdash}
            </Typo>
            {external_item_id && <CopyButton data={external_item_id} />}
          </Space>
        </Vertical>
      </Table.Td>
    </Table.Tr>
  )
})
