import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { FiltersPanel } from 'page/internal/search/FiltersPanel'
import { ItemsList } from 'page/internal/search/ItemsList'
import { Pagination } from 'page/internal/search/Pagination'
import { QueryInput } from 'page/internal/search/QueryInput'
import { Page2 } from 'saas/screen/page/Page2/Page2'
import { ui } from 'store/home/ui/HomeUiStore'
import { managerSearchStore } from 'store/manager/search'

function content() {
  if (ui.window.mobile) {
    return <Vertical gap={16}>
      <FiltersPanel />
      <Card vertical gap={16} pad={16}>
        <Pagination top />
        <QueryInput />
        <ItemsList />
        <Divider secondary />
        <Pagination />
      </Card>
    </Vertical>
  } else {
    return <Space gap={24} top>
      <Card vertical gap={16} pad={16} flex="1 1 0">
        <Pagination top />
        <QueryInput />
        <ItemsList />
        <Divider secondary />
        <Pagination />
      </Card>
      <FiltersPanel />
    </Space>
  }
}

export const SearchSaasView = observer(() => {
  const { busy } = managerSearchStore

  return <Page2>
    <Space gap={12}>
      <Typo size={23} bold>{i18n('label.Search')}</Typo>
      {busy && <Spinner size={16} />}
    </Space>

    {content()}
  </Page2>
})
