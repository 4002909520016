import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { PasswordField } from 'modal/ProfileModal/PasswordField'

export const PasswordTab = observer(() => {
  const store = modal.profileModal.it.password
  const { current, password, confirmation, warn, canSave, done, error } = store.it

  const onRetry = useLatestCallback(() => {
    store.it.retry()
  })

  const onBack = useLatestCallback(() => {
    store.close()
  })

  const onClose = useLatestCallback(() => {
    modal.profileModal.close()
  })

  const onSave = useLatestCallback(async () => {
    if (!store.it.check()) return
    await store.it.save()
  })

  if (done && error) return <Vertical gap={24}>
    <Card gap={8} pad={12} round={8} center error>
      <Icon size={16} name="error" fill error />
      <Typo size={12} line={16}>{error}</Typo>
    </Card>
    <Button filled action={onRetry}>{i18n('common.Back')}</Button>
  </Vertical>

  if (done && !error) return <Vertical gap={24}>
    <Card gap={8} pad={12} round={8} center success>
      <Icon size={16} name="check_circle" fill success />
      <Typo size={12} line={16}>{i18n('user.ProfilePasswordChangedSuccessfully')}</Typo>
    </Card>
    <Button primary action={onClose}>{i18n('common.Thanks')}</Button>
  </Vertical>

  return <Vertical gap={24}>
    <Vertical gap={12}>
      <Space>
        <Link action={onBack}>
          <Space gap={4} height={32} oh>
            <Icon size={16} name="arrow_back" />
            <Typo size={13} ellipsis>{i18n('user.BackToProfile')}</Typo>
          </Space>
        </Link>
      </Space>
      <PasswordField field={current} label={i18n('label.CurrentPassword')} autofocus />
      <PasswordField field={password} label={i18n('label.NewPassword')} error={!!warn} />
      <Vertical gap={6}>
        <PasswordField field={confirmation} label={i18n('label.ConfirmPassword')} error={!!warn} />
        {warn && <Typo size={11} error>{warn}</Typo>}
      </Vertical>
    </Vertical>
    <Space gap={12} columns>
      <Button filled action={onBack}>{i18n('common.Cancel')}</Button>
      <Button primary disabled={!canSave} action={onSave}>{i18n('common.Save')}</Button>
    </Space>
  </Vertical>
})
