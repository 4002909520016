import { Card } from 'component/Card'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { try_on_preview } from 'feature/widget/TryOnPreviewStore'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { tryon } from 'saas/store/product/ProductSaasController'
import { ui } from 'store/home/ui/HomeUiStore'

export const Preview = observer(() => {
  const { small } = ui.window
  const [container, setContainer] = useState<HTMLElement | null>(null)

  const update = useLatestCallback((container: HTMLElement | null) => {
    const { optionsPreview } = tryon.it
    if (container) void try_on_preview.preview(optionsPreview, container)
  })

  const onRef = useLatestCallback((container: HTMLElement | null) => {
    setContainer(container)
  })

  const onPreview = useLatestCallback(async () => {
    update(container)
  })

  return <Vertical gap={12} wide>
    <Space gap={16} opposite wrap>
      <Typo size={15} semi>{i18n('widget.Preview')}</Typo>
      <Link action={onPreview}>
        {i18n('widget.Update')}
      </Link>
    </Space>
    <Card pad={16} round={12} panel>
      <Card ref={onRef} round={6} wide height={720} overflow pale />
    </Card>
  </Vertical>
})
