import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ButtonInput } from 'saas/page/product/bot/SettingsCard/ButtonInput/ButtonInput'
import { StandSelect } from 'saas/page/product/bot/SettingsCard/StandSelect/StandSelect'
import { TokenInput } from 'saas/page/product/bot/SettingsCard/TokenInput/TokenInput'
import { TitleEdit } from 'saas/page/product/common/Title/TitleEdit'

export const SettingsCard = observer(() => {
  return <Vertical wide>
    <Space gap={8}>
      <Icon size={24} name="tune" secondary />
      <Typo size={15} semi>{i18n('label.Settings')}</Typo>
    </Space>
    <Gap height={12} />
    <TitleEdit />
    <Gap height={12} />
    <TokenInput />
    <Gap height={12} />
    <StandSelect />
    <Gap height={12} />
    <ButtonInput />
  </Vertical>
})
