import { CopyButton } from 'component/Button/CopyButton'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { bot } from 'saas/store/product/ProductSaasController'
import { emdash } from 'util/typo'

export const Links = observer(() => {
  const { bot_username } = bot.it.edit
  const href = `https://t.me/${bot_username}`
  return <Vertical oh>
    <Typo size={12} secondary>{i18n('label.Link')}</Typo>
    <Gap height={4} />
    {bot_username ? <Space gap={8}>
      <Link href={href}>
        {href.replace('https://', '')}
      </Link>
      <CopyButton data={href} />
    </Space> : <Typo size={12} tertiary>{emdash}</Typo>}
  </Vertical>
})
