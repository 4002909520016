import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { ProductCard } from 'saas/page/products/components/ProductCard'
import { productsSaas } from 'saas/store/products/ProductsSaasController'

export const ProductsList = observer(() => {
  const { products } = productsSaas.it

  return <Space gap={16} top wrap>
    {products.map(it => <ProductCard key={it.product_id} product={it} />)}
  </Space>
})
