// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoInput-logo-e14d{overflow:hidden;width:100%;height:100%;min-width:48px;min-height:48px}.LogoInput-logo-e14d svg{width:auto;height:24px}.LogoInput-logo-e14d img{max-width:100%;max-height:100%;object-fit:contain;border-radius:var(--ui-border-radius-medium)}.LogoInput-pale-e14d{opacity:.2}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/stand/LogoInput/LogoInput.module.scss"],"names":[],"mappings":"AAAA,qBACE,eAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,eAAA,CAEA,yBACE,UAAA,CACA,WAAA,CAGF,yBACE,cAAA,CACA,eAAA,CACA,kBAAA,CACA,4CAAA,CAIJ,qBACE,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `LogoInput-logo-e14d`,
	"pale": `LogoInput-pale-e14d`
};
module.exports = ___CSS_LOADER_EXPORT___;
