import { clsx } from 'clsx'
import { CopyIconButton } from 'component/Button/CopyIconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { observer } from 'mobx-react-lite'
import css from './EndpointContainer.module.scss'

export type Endpoint = {
  method: string
  path: string
}

export function parseEndpointProps(child: ChildNode): Endpoint | undefined {
  const text = child.textContent?.trim() ?? ''
  const m = text.match(/^(\w+)(:|\s)\s*(\w+)\s+(\S+)$/)
  if (!m) return undefined

  const method = m[3]?.toUpperCase() ?? ''
  const path = m[4]?.toLowerCase() ?? ''

  return { method, path }
}

type Props = {
  endpoint: Endpoint
}

export const EndpointContainer = observer(({ endpoint }: Props) => {
  const { method, path } = endpoint
  const data = method + ' ' + path

  const type = method === 'GET' ? css.get : css.post

  return <Space className={clsx(css.endpoint, type)} gap={16}>
    <Typo className={css.method}>{method}</Typo>
    <Typo className={css.path} ellipsis>{path}</Typo>
    <Space>
      <CopyIconButton data={data} />
    </Space>
  </Space>
})
