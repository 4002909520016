// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table-table-f3ba{border-collapse:collapse;width:100%}.Table-table-f3ba thead td{white-space:nowrap;vertical-align:top;padding:12px 8px;font-size:13px;font-weight:600;color:var(--ui-text-color);background:rgba(30,30,30,.04);position:relative}.Table-table-f3ba thead td+td:before{content:"";position:absolute;width:1px;left:0;top:8px;bottom:8px;background:#e4e4e4}.Table-table-f3ba thead td:first-child{border-radius:8px 0 0 0}.Table-table-f3ba thead td:last-child{border-radius:0 8px 0 0}.Table-table-f3ba tbody td{vertical-align:top;padding:12px 8px;border-top:1px solid var(--ui-border-color-tertiary)}`, "",{"version":3,"sources":["webpack://./src/component/Table/Table.module.scss"],"names":[],"mappings":"AAAA,kBACE,wBAAA,CACA,UAAA,CAIA,2BACE,kBAAA,CACA,kBAAA,CACA,gBAAA,CACA,cAAA,CACA,eAAA,CACA,0BAAA,CACA,6BAAA,CACA,iBAAA,CAGE,qCACE,UAAA,CACA,iBAAA,CACA,SAAA,CACA,MAAA,CACA,OAAA,CACA,UAAA,CACA,kBAAA,CAKJ,uCACE,uBAAA,CAGF,sCACE,uBAAA,CAMJ,2BACE,kBAAA,CACA,gBAAA,CACA,oDAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `Table-table-f3ba`
};
module.exports = ___CSS_LOADER_EXPORT___;
