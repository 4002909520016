// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(width >= 760px){.CollectionCard-card-f321{width:260px;min-height:300px}}@media(width < 760px){.CollectionCard-card-f321{width:calc(100vw - 32px)}}`, "",{"version":3,"sources":["webpack://./src/page/internal/collections/CollectionCard.module.scss"],"names":[],"mappings":"AACE,uBADF,0BAEI,WAAA,CACA,gBAAA,CAAA,CAGF,sBANF,0BAOI,wBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `CollectionCard-card-f321`
};
module.exports = ___CSS_LOADER_EXPORT___;
