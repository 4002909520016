// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DocSection-content-19f6>:first-child{margin-top:0 !important}.DocSection-content-19f6>:last-child{margin-bottom:0 !important}.DocSection-collapse-19f6{display:none}`, "",{"version":3,"sources":["webpack://./src/saas/page/doc/DocSection.module.scss"],"names":[],"mappings":"AAAA,sCACE,uBAAA,CAGF,qCACE,0BAAA,CAGF,0BACE,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `DocSection-content-19f6`,
	"collapse": `DocSection-collapse-19f6`
};
module.exports = ___CSS_LOADER_EXPORT___;
