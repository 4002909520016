import { CopyIconButton } from 'component/Button/CopyIconButton'
import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { KeyDropdown } from 'saas/page/docs/KeyDropdown'
import { home } from 'store/home'

export const SettingsCard = observer(() => {
  const company_id = home.company.optional?.company_id

  return <Card vertical pad={16} wide>
    <Space gap={8}>
      <Icon size={24} name="tune" tertiary />
      <Typo size={18} semi>{i18n('label.Settings')}</Typo>
    </Space>
    <Gap height={16} />
    <Divider secondary />
    <Gap height={16} />
    <Card pale gap={16} pad={[8, 12]} round={8} opposite>
      <Vertical>
        <Typo size={11} secondary>{i18n('label.CompanyId')}</Typo>
        <Typo size={13} mono>{company_id}</Typo>
      </Vertical>
      <CopyIconButton data={company_id} />
    </Card>
    <Gap height={12} />
    <KeyDropdown />
  </Card>
})
