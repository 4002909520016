import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Spinner } from 'component/Spinner'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { CommentEditor } from 'page/internal/comments/CommentEditor'
import { CommentsList } from 'page/internal/comments/CommentsList'
import { CommentTitle } from 'page/internal/comments/CommentTitle'
import { commenting } from 'store/comment/CommentingStore'

type Props = {
  className?: string
}

export const CommentsSection = observer(({ className }: Props) => {
  const store = commenting.comments
  const { ready } = store

  const onClose = useLatestCallback(() => {
    commenting.close()
  })

  return <Vertical className={className}>
    <Space gap={16} pad={[4, 12]} opposite>
      <CommentTitle />
      <IconButton action={onClose}>
        <Icon size={16} name="close" />
      </IconButton>
    </Space>
    <Space pad={[0, 12]}>
      <Divider secondary />
    </Space>
    <Vertical flex="1 1 0" oh>
      {!ready && <Space height={200} center>
        <Spinner />
      </Space>}
      {ready && <CommentsList />}
    </Vertical>
    <CommentEditor />
  </Vertical>
})
