import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal/index'
import { MainTab } from 'modal/ProfileModal/MainTab'
import { PasswordTab } from 'modal/ProfileModal/PasswordTab'
import { UserPicture } from 'section/Head/UserPicture'
import { me } from 'store/me'

export const ProfileModal = observer(() => {
  const password = modal.profileModal.it.password.present

  const onCancel = useLatestCallback(() => {
    modal.profileModal.close()
  })

  return <Modal onCancel={onCancel}>
    <Space opposite gap={16}>
      <Space gap={12}>
        <UserPicture size={48} picture={me.user.picture} />
        <Vertical gap={2}>
          <Typo size={16} bold>
            {password ? i18n('user.ChangePassword') : i18n('label.YourProfile')}
          </Typo>
          <Typo size={12}>
            {me.user.email}
          </Typo>
        </Vertical>
      </Space>
    </Space>
    {password ? <PasswordTab /> : <MainTab />}
  </Modal>
})
