import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Link } from 'component/Link'
import { Divider } from 'component/Space/Divider'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { home } from 'store/home'
import { getDocImage, getDocTitle } from 'type/Doc'

type Props = {
  path: string
}

export const DocCard = observer(({ path }: Props) => {
  const { company_name } = home.company.it.company
  const title = getDocTitle(path)
  const image = getDocImage(path)

  return <Card vertical gap={12} pad={12}>
    {image ? <img src={image} width={280} /> : <Card panel width={280} height={120} />}
    <Vertical gap={4}>
      <Typo size={13} line={1.2} secondary>{company_name}</Typo>
      <Typo size={15} line={1.3} semi>{title}</Typo>
    </Vertical>
    <Divider secondary />
    <Link path={path}>
      <Button filled wide>
        {<Icon size={16} name="article" />}
        <Typo>{i18n('docs.Open')}</Typo>
      </Button>
    </Link>
  </Card>
})
