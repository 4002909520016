import { Button } from 'component/Button'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Logo } from 'saas/screen/sidebar/Logo'
import { ProfileDropdown } from 'saas/screen/sidebar/ProfileDropdown'
import { UserLogo } from 'saas/screen/sidebar/UserLogo'
import { sidebar } from 'saas/store/SidebarStore'
import css from './TopLogo.module.scss'

export const TopLogo = observer(() => {
  const onOpen = useLatestCallback(() => {
    sidebar.open = !sidebar.open
  })

  return <Space className={css.space} gap={8} pad={[0, 16]} wide>
    <Button className={css.menu} text large square action={onOpen}>
      <Icon size={24} name="menu" />
    </Button>
    <Space center flex="1 1 auto" spread oh>
      <Link path="/">
        <Logo />
      </Link>
    </Space>
    <ProfileDropdown>
      <Button className={css.profile} text large square>
        <UserLogo />
      </Button>
    </ProfileDropdown>
  </Space>
})
