import { FormStore } from 'form/store/FormStore'
import { PasswordFormStore } from 'modal/ProfileModal/store/PasswordFormStore'
import { mx } from 'store/base/mx'
import { StringStore } from 'store/base/StringStore'
import { me } from 'store/me'

export class ProfileModalStore {
  readonly form = new FormStore()
  readonly name = this.form.field(new StringStore(''), { required: true })
  readonly phone = this.form.field(new StringStore(''))
  readonly password = mx.ref<PasswordFormStore>()

  constructor() {
    this.name.value = me.user.name ?? ''
  }
}
