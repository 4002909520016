import { Typography } from 'antd'
import { Typo, TypoProps } from 'component/Typo/Typo'
import { ReactNode } from 'react'
import css from './Text.module.scss'

type Props = TypoProps & {
  children?: ReactNode
  rows?: number
}

export function Text(props: Props) {
  const { children, rows, ...typo } = props

  return <Typography.Paragraph className={css.text} ellipsis={{ rows }}>
    <Typo {...typo}>{children}</Typo>
  </Typography.Paragraph>
}
