// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgendaItem-item-a51b>*{margin:0 !important;font-size:13px;font-weight:400}.AgendaItem-item-a51b>h1,.AgendaItem-item-a51b>h2{font-weight:600}`, "",{"version":3,"sources":["webpack://./src/saas/page/doc/AgendaItem.module.scss"],"names":[],"mappings":"AAAA,wBACE,mBAAA,CACA,cAAA,CACA,eAAA,CAGF,kDACE,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `AgendaItem-item-a51b`
};
module.exports = ___CSS_LOADER_EXPORT___;
