import { Input } from 'component/Input'
import { Space } from 'component/Space'
import { FormField } from 'form/FormField'
import { FieldStore } from 'form/store/FieldStore'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'

type Props = {
  field: FieldStore<string>
  label: string
  autofocus?: boolean
  error?: boolean
}

export const PasswordField = observer((props: Props) => {
  const { field, label, autofocus } = props
  const [focus, setFocus] = useState(false)
  const [visible, setVisible] = useState(false)
  const { value } = field
  const error = props.error || field.error

  const ref = useRef<HTMLInputElement | null>(null)

  useEffectOnce(() => {
    if (autofocus) ref.current?.focus()
  })

  const onVisible = useLatestCallback(() => {
    setVisible(!visible)
    ref.current?.focus()
  })

  const onChange = useLatestCallback((value: string) => {
    field.value = value
  })

  const suffix = <Space width={32} height={32} margin={[0, -4]} center onClick={onVisible}>
    <Icon size={16} name={visible ? 'visibility_off' : 'visibility'} secondary />
  </Space>

  return <FormField field={field} label={label}>
    <Input ref={ref} value={value} suffix={suffix} password={!visible}
      error={!focus && error} clear={false} onChange={onChange} onFocus={setFocus} />
  </FormField>
})
