import { Typography } from 'antd'
import { clsx } from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { MouseEvent, ReactNode } from 'react'
import { route } from 'store/route'
import { getPaddingStyle, Padding, styles } from 'util/style'
import css from './Link.module.scss'

interface Props {
  action?(event: MouseEvent): unknown

  children?: ReactNode
  className?: string
  disabled?: boolean
  href?: string
  pad?: Padding
  path?: string
  primary?: boolean
  secondary?: boolean
  small?: boolean
  text?: boolean
  wide?: boolean
  wrap?: boolean
}

const LinkAntd = Typography.Link

export const Link = (all: Props) => {
  const { action, children, href, path, primary, secondary, small, text, wide, wrap, ...props } = all

  const className = clsx(props.className, css.link, primary && css.primary, secondary && css.secondary,
    text && css.text, small && css.small, wide && css.wide, wrap && css.wrap)

  const pad = getPaddingStyle(props.pad)
  const style = styles(pad)

  const link = href ?? path ?? ''
  const target = href ? '_blank' : undefined
  const rel = href ? 'noopener' : undefined

  const disabled = props.disabled || (!link && !action)

  const onClick = useLatestCallback((event: MouseEvent) => {
    const { metaKey, ctrlKey, altKey, shiftKey } = event
    const meta = Boolean(metaKey || ctrlKey || altKey || shiftKey)
    if (meta) return

    let prevent = false
    if (action) {
      action(event)
      prevent = event.isDefaultPrevented()
      event.preventDefault()
    }
    if (path && !prevent) {
      event.preventDefault()
      route.push(path)
    }
  })

  return <LinkAntd className={className} style={style} href={link}
    target={target} rel={rel} disabled={disabled} onClick={onClick}>
    {children}
  </LinkAntd>
}
