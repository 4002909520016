import { makeAutoObservable, reaction } from 'mobx'
import { ui } from 'store/home/ui/HomeUiStore'

const DEFAULT_OPEN = true

function loadOpen(): boolean {
  const text = localStorage.getItem('sidebar')
  return text ? text === 'true' : DEFAULT_OPEN
}

function saveOpen(open: boolean) {
  if (open === DEFAULT_OPEN) localStorage.removeItem('sidebar')
  else localStorage.setItem('sidebar', 'false')
}

class SidebarStore {
  private _open = loadOpen()
  private _start = true
  private _small = true

  constructor() {
    makeAutoObservable(this)
    reaction(() => ui.window.scrollY === 0, start => this.start = start, { fireImmediately: true })
    reaction(() => ui.window.mobile, small => this.small = small, { fireImmediately: true })
  }

  get open(): boolean {
    return this._open
  }

  set open(open: boolean) {
    this._open = open
    saveOpen(open)
  }

  get start(): boolean {
    return this._start
  }

  get small(): boolean {
    return this._small
  }

  private set start(value: boolean) {
    this._start = value
  }

  private set small(small: boolean) {
    this._small = small
    if (small) this.open = false
  }
}

export const sidebar = new SidebarStore()
