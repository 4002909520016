import { development } from 'app/config/constants'
import { mx } from 'store/base/mx'
import { Permissions } from 'type/Permissions'
import { User } from 'type/User'

class MeStore {
  private readonly _user = mx.ref<User>()
  private readonly _permissions = mx.ref<Permissions>()

  get present(): boolean {
    return this._user.present
  }

  get admin(): boolean {
    return !!this._permissions.optional?.roles.is_admin
  }

  get user(): User {
    if (!this._user.present) throw new Error('no user')
    return this._user.it
  }

  set user(user: User) {
    if (development) user.picture = undefined
    this._user.it = user
  }

  get permissions(): Permissions {
    if (!this._permissions.present) throw new Error('no permissions')
    return this._permissions.it
  }

  set permissions(permissions: Permissions) {
    this._permissions.it = permissions
  }

  clear(): void {
    this._user.it = undefined
    this._permissions.it = undefined
  }
}

export const me = new MeStore()
