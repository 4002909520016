import { IconButton } from 'component/Button/IconButton'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'

type Props = {
  page: number
  pages: number
  onChange(page: number): void
}

export const PageInput = observer(({ page, pages, onChange }: Props) => {
  const start = page <= 1
  const end = page >= pages
  const none = start && end

  const shiftPage = useLatestCallback((shift: number) => {
    const value = Math.max(1, Math.min(page + shift, pages))
    if (value === page) return
    onChange(value)
    document.documentElement.scrollTop = 0
  })

  const onLeft = useLatestCallback(() => shiftPage(-1))
  const onRight = useLatestCallback(() => shiftPage(+1))

  if (none) return null

  return <Space>
    <IconButton disabled={start} action={onLeft}>
      <Icon size={16} name="keyboard_arrow_left" />
    </IconButton>
    <Space gap={4} center>
      <Typo size={13} medium>{page}</Typo>
      <Typo size={13} medium tertiary>/</Typo>
      <Typo size={13} medium tertiary>{pages}</Typo>
    </Space>
    <IconButton disabled={end} action={onRight}>
      <Icon size={16} name="keyboard_arrow_right" />
    </IconButton>
  </Space>
})
