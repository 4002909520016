// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Text-text-f220{margin-bottom:0 !important}.Text-text-f220>*{display:block}`, "",{"version":3,"sources":["webpack://./src/component/Typo/Text.module.scss"],"names":[],"mappings":"AAAA,gBACE,0BAAA,CAGF,kBACE,aAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `Text-text-f220`
};
module.exports = ___CSS_LOADER_EXPORT___;
