import apiPng from 'asset/doc/api.png'
import widgetPng from 'asset/doc/widget.png'
import { i18n } from 'i18n'

export type HeadLevel = 1 | 2 | 3 | 4 | 5 | 6

export type DocSectionData = {
  level: HeadLevel | 0
  head?: Element
  children: ChildNode[]
}

export type DocData = {
  html: string
  sections: DocSectionData[]
}

export function getDocImage(path: string): string | undefined {
  switch (path) {
    case '/docs/api':
      return apiPng
    case '/docs/widget':
      return widgetPng
    default:
      return undefined
  }
}

export function getDocTitle(path: string): string {
  switch (path) {
    case '/docs/api':
      return i18n('docs.ApiDocumentation')
    case '/docs/widget':
      return i18n('docs.WidgetForWebsite')
    case '/docs/new.api':
      return 'API NEW'
    default:
      return i18n('docs.Documentation')
  }
}

function parseLevel(child: ChildNode | undefined): HeadLevel | undefined {
  if (!(child instanceof Element)) return undefined
  const match = child?.tagName.match(/^H(\d)$/)?.[1]
  if (!match) return undefined
  return parseInt(match, 10) as HeadLevel
}

export function parseDoc(content: string): DocData {
  const html = content
  const sections: DocSectionData[] = []

  const div = document.createElement('div')
  div.innerHTML = html

  let head: Element | undefined
  let children: ChildNode[] = []

  function add() {
    if (!head && !children.length) return

    const level = parseLevel(head) ?? 0
    sections.push({ level, head, children })
  }

  for (const child of div.childNodes) {
    if (child instanceof HTMLHeadingElement) {
      add()
      head = child
      children = []
    } else {
      children.push(child)
    }
  }
  add()

  return { html, sections }
}
