import { PaginationPanel } from 'feature/pagination/PaginationPanel'
import { observer } from 'mobx-react-lite'
import { managerSearchStore } from 'store/manager/search'

type Props = {
  top?: boolean
}

export const Pagination = observer(({ top }: Props) => {
  return <PaginationPanel top={top} store={managerSearchStore} />
})
