import { Sticker } from 'component/Glass'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { TextField } from 'form/TextField'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ImageInput } from 'saas/page/product/stand/ImageInput/ImageInput'
import { stand } from 'saas/store/product/ProductSaasController'

export const MetaSettings = observer(() => {
  const { edit } = stand.it
  const { description } = edit
  const limit = 256
  const left = limit - description.value.length

  return <Space gap={[16, 32]} top wrap>
    <Space flex="1 1 400px">
      <TextField rows={11} limit={limit} field={description} label={i18n('stand.MetaDescription')} />
      <Sticker pin={[null, 8, 8, null]}>
        <Typo size={12} secondary>
          {i18n('text.CountCharactersLeft', { count: left })}
        </Typo>
      </Sticker>
    </Space>
    <ImageInput />
  </Space>
})
