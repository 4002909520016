import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Sticker } from 'component/Glass'
import { Scroll, ScrollRef } from 'component/Scroll'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { TextArea } from 'component/TextArea'
import { Vertical } from 'component/Vertical'
import { useEffectOnce } from 'hook/useEffectOnce'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { CommentPreview } from 'page/internal/comments/CommentPreview'
import { KeyboardEvent, useEffect, useRef } from 'react'
import { commenting } from 'store/comment/CommentingStore'
import css from './CommentEditor.module.scss'

export const CommentEditor = observer(() => {
  const store = commenting.comments
  const { inline } = commenting
  const { focus, message, replyTo, canSend, editing, send } = store

  const scroll = useRef<ScrollRef>(null)
  const ref = useRef<HTMLTextAreaElement>(null)
  const last = useRef('')

  const onResize = useLatestCallback(() => {
    const area = ref.current
    if (area) {
      area.style.height = '0'
      const height = area.scrollHeight
      area.style.height = height + 'px'
    }
  })

  useEffect(() => {
    if (focus) {
      ref.current?.focus()
      store.focus = undefined
    }
  }, [focus, store])

  const onChange = useLatestCallback((value: string) => {
    last.current = value
    store.message = value
    onResize()
  })

  const onReaction = useLatestCallback((value: string) => {
    // dirty hack to resize after external changes
    if (value !== last.current) setTimeout(() => {
      onResize()
      const content = scroll.current?.osInstance()?.elements()?.content
      if (content) content.scrollTop = 1e9
    })
    last.current = value
  })

  useEffectOnce(() => {
    onResize()
    reaction(() => store.message, onReaction)
  })

  const onKeyDown = useLatestCallback((event: KeyboardEvent) => {
    const ctrl = event.metaKey || event.ctrlKey
    const enter = event.key === 'Enter'
    if (ctrl && enter && canSend) void send.call()
  })

  return <Vertical gap={12} className={clsx(css.editor, inline && css.inline)}>
    <Space pad={[0, 12]}>
      <Divider secondary />
    </Space>
    {!editing && replyTo && <CommentPreview type="reply" comment={replyTo} />}
    {editing && <CommentPreview type="edit" comment={editing} />}
    <Space pad={[0, 12, 12, 12]}>
      <Scroll ref={scroll} className={css.scroll}>
        <TextArea ref={ref} className={css.input} rows={1} value={message}
          onChange={onChange} onKeyDown={onKeyDown} />
      </Scroll>
      <Sticker pin={[0, 12, 12, null]}>
        <IconButton title="Ctrl + Enter"
          disabled={!canSend} action={send.action}>
          <Icon size={16} name="send" fill primary={canSend} tertiary={!canSend} />
        </IconButton>
      </Sticker>
    </Space>
  </Vertical>
})
