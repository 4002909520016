import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { Sticker } from 'component/Glass'
import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Upload } from 'component/Upload'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'
import css from './IconInput.module.scss'
import TabsPng from './tabs.png'

export const IconInput = observer(() => {
  const original = stand.it.json.icon
  const { icon } = stand.it.edit
  const { value, busy, src } = icon
  const changed = busy || value !== original

  const onFile = useLatestCallback((file: File) => {
    icon.file = file
  })

  const onDelete = useLatestCallback(() => {
    icon.value = undefined
  })

  const onUndo = useLatestCallback(() => {
    icon.value = original
  })

  return <Space>
    <Upload active={!!src} accept="image" onFile={onFile}>
      <Vertical pad={12} gap={8} width={200} maxWidth="calc(100vw - 48px)">
        <Space gap={8}>
          <Typo size={13} semi>{i18n('stand.Favicon')}</Typo>
          {busy && <Spinner size={16} />}
        </Space>
        <Space height={112} top width={200}>
          <img className={css.back} src={TabsPng} />
          <Space className={clsx(css.icon, css.light, !value && css.pale)} center>
            {src ? <img src={src} /> : <Icon size={16} name="globe" />}
          </Space>
          <Space className={clsx(css.icon, css.dark, !value && css.pale)} center>
            {src ? <img src={src} /> : <Icon size={16} name="globe" />}
          </Space>
        </Space>
      </Vertical>
    </Upload>
    {src && <Sticker pin={[6, 6, null, null]}>
      <IconButton action={onDelete}>
        <Icon size={16} name="delete" />
      </IconButton>
    </Sticker>}
    {changed && <Sticker pin={[null, 6, 6, null]}>
      <IconButton action={onUndo}>
        <Icon size={16} name="undo" />
      </IconButton>
    </Sticker>}
  </Space>
})
