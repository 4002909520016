import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Items } from 'saas/page/group/preview/Items'
import { Search } from 'saas/page/group/preview/Search'

export const GroupPreview = observer(() => {
  return <Card gap={24} pad={24} minWidth={400} vertical>
    <Space gap={16} opposite>
      <Typo size={16} semi>{i18n('group.Items')}</Typo>
      <Search />
    </Space>
    <Items />
  </Card>
})
