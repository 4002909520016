import { Card } from 'component/Card'
import { SearchInput } from 'component/Input/SearchInput'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useEffectOnce } from 'hook/useEffectOnce'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { CollectionCard } from 'page/internal/collections/CollectionCard'
import { home } from 'store/home'
import { ui } from 'store/home/ui/HomeUiStore'
import { renderStubIcon } from 'util/render'

export const View = observer(() => {
  const { mobile } = ui.window
  const { collections } = home.collections.optional ?? {}
  const stub = !home.collections.present
  const none = !collections?.length

  useEffectOnce(() => {
    void home.company.it.loadCollections()
  })

  return stub ? <Stub /> : none ? <None /> : mobile ? <Mobile /> : <Desktop />
})

const Stub = observer(() => {
  return <Space height={400} center>
    {renderStubIcon(home.collections)}
  </Space>
})

const None = observer(() => {
  return <Space height={300} center>
    <Typo size={14} secondary>
      {i18n('collection.NoCollections')}
    </Typo>
  </Space>
})

const Desktop = observer(() => {
  const { collectionsVisible, search } = home.collections.it

  useEffectOnce(() => {
    window.scroll(0, 0)
  })

  return <Card vertical gap={16} pad={16}>
    <SearchInput placeholder={i18n('search.Search')}
      value={search.value} onChange={search.onChange} />

    {!collectionsVisible.length && <None />}

    {collectionsVisible.length > 0 && <Space gap={16} top wrap>
      {collectionsVisible.map(collection => (
        <CollectionCard key={collection.collectionId} collection={collection} />
      ))}
    </Space>}
  </Card>
})

const Mobile = observer(() => {
  const { collectionsVisible, search } = home.collections.it

  useEffectOnce(() => {
    window.scroll(0, 0)
  })

  return <Vertical gap={16}>
    <SearchInput placeholder={i18n('search.Search')}
      value={search.value} onChange={search.onChange} />

    {!collectionsVisible.length && <None />}

    {collectionsVisible.length > 0 && <Space gap={16} top wrap>
      {collectionsVisible.map(collection => (
        <CollectionCard key={collection.collectionId} collection={collection} />
      ))}
    </Space>}
  </Vertical>
})
