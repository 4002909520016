import { Card } from 'component/Card'
import { LoadingPage } from 'feature/page/LoadingPage'
import { observer } from 'mobx-react-lite'
import { closeProductsPage } from 'page/internal/products/store/ProductsPageStore'
import { View } from 'page/internal/products/View'
import { useEffect, useState } from 'react'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { Title } from 'saas/page/collection/Title'
import { Page2 } from 'saas/screen/page/Page2/Page2'
import { saas } from 'saas/store/SaasController'
import { home } from 'store/home'
import { ui } from 'store/home/ui/HomeUiStore'
import { route } from 'store/route'

export const CollectionPage = observer(() => {
  const { mobile } = ui.window
  const collectionId = route.unit(1)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    saas.initCollection(collectionId).then(() => setLoading(false))
    return () => closeProductsPage()
  }, [collectionId])

  if (loading) return <LoadingPage />
  if (!home.collection.present) return <NotFoundPage />

  return <Page2>
    <Title />
    <Card pad={16} margin={mobile ? [0, -16] : 0}>
      <View />
    </Card>
  </Page2>
})
