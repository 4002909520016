import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { FeedTable } from 'saas/page/product/bot/FeedCard/Campaigns/FeedTable'
import { bot } from 'saas/store/product/ProductSaasController'

export const Campaigns = observer(() => {
  const present = bot.it.feeds.present

  return <Vertical wide>
    <Typo semi>Campaigns</Typo>
    <Gap height={16} />
    {!present && <Space height={400} center>
      <Spinner />
    </Space>}
    {present && <FeedTable />}
  </Vertical>
})
