import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { View } from 'saas/page/product/common/View/View'
import { Details } from 'saas/page/product/stand/Details/Details'
import { SettingsCard } from 'saas/page/product/stand/SettingsCard/SettingsCard'

export const StandView = observer(() => {
  return <View>
    <Space pad={16} wide>
      <SettingsCard />
    </Space>
    <Details />
  </View>
})
