import { Divider } from 'antd'
import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { PageHeader } from 'component/PageHeader'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { DataSection } from 'saas/page/company/manage/DataSection'
import { KeySection } from 'saas/page/company/manage/KeySection'
import { MemberSection } from 'saas/page/company/manage/MemberSection'
import { CompanyDeleteModal } from 'saas/page/company/manage/modal/CompanyDeleteModal'
import { EditMemberModal } from 'saas/page/company/manage/modal/EditMemberModal/EditMemberModal'
import { KeyDeleteModal } from 'saas/page/company/manage/modal/KeyDeleteModal'
import { MemberDeleteModal } from 'saas/page/company/manage/modal/MemberDeleteModal'
import { WebhookSection } from 'saas/page/company/manage/WebhookSection'
import { KeyEditModal } from 'saas/page/company/modal/KeyEditModal/KeyEditModal'
import { companyEdit } from 'saas/store/CompanyEditStore'
import { keyModal } from 'saas/store/key/ApiKeyModalStore'
import { memberEdit } from 'saas/store/MemberEditStore'
import { can } from 'store/can'

export const CompanyManageView = observer(() => {
  const { company_id, companyDeleting, keyDeleting, memberDeleting } = companyEdit.it

  const canDelete = can.DeleteCompany(company_id)

  const onDelete = useLatestCallback(async () => {
    companyEdit.it.companyDeleting = true
  })

  return <Vertical>
    <PageHeader title={i18n('company.ManageCompany')}>
      {canDelete && <Button link error large icon={<Icon size={16} name="delete" />} onClick={onDelete}>
        {i18n('company.DeleteCompany')}
      </Button>}
    </PageHeader>
    <Space gap={16} wrap top wide>
      <Card gap={24} pad={24} vertical wide>
        <DataSection />
      </Card>
      {can.ViewApiKeys(company_id) && <Card pad={24} wide>
        <Vertical gap={16} wide>
          <KeySection />
          <Divider style={{ margin: 0 }} />
          <WebhookSection />
        </Vertical>
      </Card>}
    </Space>
    <Gap height={16} />
    <MemberSection />

    {companyDeleting && <CompanyDeleteModal />}
    {keyModal.present && <KeyEditModal />}
    {keyDeleting && <KeyDeleteModal />}
    {memberEdit.present && <EditMemberModal />}
    {memberDeleting && <MemberDeleteModal />}
  </Vertical>
})
