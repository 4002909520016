import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { FiltersPanel } from 'page/internal/search/FiltersPanel'
import { ItemsList } from 'page/internal/search/ItemsList'
import { Pagination } from 'page/internal/search/Pagination'
import { SearchPanel } from 'page/internal/search/SearchPanel'
import { managerSearchStore } from 'store/manager/search'

export const SearchView = observer(() => {
  const { showFilterPanel, busy } = managerSearchStore

  return <Vertical gap={24}>
    <Space gap={12}>
      <Typo size={18} bold>{i18n('label.Search')}</Typo>
      {busy && <Spinner size={16} />}
    </Space>

    <Space top gap={24}>
      {showFilterPanel && <FiltersPanel />}
      <Vertical gap={16} wide>
        <Pagination top />
        <SearchPanel />
        <ItemsList />
      </Vertical>
    </Space>
    <Pagination />
  </Vertical>
})
