// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Space-row-482d{position:relative;display:flex;align-items:center}.Space-column-482d{position:relative;display:flex;flex-direction:column}.Space-oh-482d{overflow:hidden}.Space-pointer-482d{cursor:pointer;-webkit-user-select:none;user-select:none}.Space-row-482d.Space-top-482d{align-items:start}.Space-row-482d.Space-bottom-482d{align-items:end}.Space-row-482d.Space-center-482d{justify-content:center}.Space-row-482d.Space-right-482d{justify-content:right}.Space-column-482d.Space-center-482d{align-items:center;justify-content:center}.Space-column-482d.Space-top-482d{justify-content:start}.Space-column-482d.Space-right-482d{align-items:end}.Space-opposite-482d{justify-content:space-between}.Space-shrink-482d{flex-shrink:1;min-width:0}.Space-shrink-482d>*{flex-shrink:1}.Space-spread-482d>*{flex:1 0 auto}.Space-stable-482d>*{flex:0 0 auto}.Space-parallel-482d>*{align-self:stretch}.Space-row-482d.Space-columns-482d>*{flex:1 1 0}.Space-fit-482d{width:fit-content;max-width:100%}.Space-wide-482d{width:100%}.Space-tall-482d{height:100%}.Space-wrap-482d{flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/component/Space/Space.module.scss"],"names":[],"mappings":"AAAA,gBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CAGF,mBACE,iBAAA,CACA,YAAA,CACA,qBAAA,CAGF,eACE,eAAA,CAGF,oBACE,cAAA,CACA,wBAAA,CAAA,gBAAA,CAGF,+BACE,iBAAA,CAGF,kCACE,eAAA,CAGF,kCACE,sBAAA,CAGF,iCACE,qBAAA,CAGF,qCACE,kBAAA,CACA,sBAAA,CAGF,kCACE,qBAAA,CAGF,oCACE,eAAA,CAGF,qBACE,6BAAA,CAGF,mBACE,aAAA,CACA,WAAA,CAEA,qBACE,aAAA,CAKF,qBACE,aAAA,CAKF,qBACE,aAAA,CAKF,uBACE,kBAAA,CAKF,qCACE,UAAA,CAIJ,gBACE,iBAAA,CACA,cAAA,CAGF,iBACE,UAAA,CAGF,iBACE,WAAA,CAGF,iBACE,cAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `Space-row-482d`,
	"column": `Space-column-482d`,
	"oh": `Space-oh-482d`,
	"pointer": `Space-pointer-482d`,
	"top": `Space-top-482d`,
	"bottom": `Space-bottom-482d`,
	"center": `Space-center-482d`,
	"right": `Space-right-482d`,
	"opposite": `Space-opposite-482d`,
	"shrink": `Space-shrink-482d`,
	"spread": `Space-spread-482d`,
	"stable": `Space-stable-482d`,
	"parallel": `Space-parallel-482d`,
	"columns": `Space-columns-482d`,
	"fit": `Space-fit-482d`,
	"wide": `Space-wide-482d`,
	"tall": `Space-tall-482d`,
	"wrap": `Space-wrap-482d`
};
module.exports = ___CSS_LOADER_EXPORT___;
