import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { ui } from 'store/home/ui/HomeUiStore'

const LIMITS = [10, 20, 50, 100]

type Props = {
  busy?: boolean
  onChange(value: number): void
  value: number
}

export const LimitDropdown = observer(({ busy, onChange, value }: Props) => {
  const { mobile } = ui.window

  const options = LIMITS.map<DropdownItem>(it => ({
    key: it.toFixed(),
    label: <Typo primary={value === it}>{it}</Typo>,
    action: busy ? false : () => onChange(it),
  }))

  return <Space oh>
    <Typo size={13} secondary ellipsis>{mobile ? i18n('search.ItemsPerPageShort') : i18n('search.ItemsPerPage')}</Typo>
    <Dropdown placement="bottomRight" items={options}>
      <Space gap={2} pad={[0, 8]} height={20}>
        <Typo size={13} medium>{value}</Typo>
        <Icon size={16} name="keyboard_arrow_down" />
      </Space>
    </Dropdown>
  </Space>
})
