import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { ProductCard } from 'page/internal/products/ProductCard'
import { page } from 'store/product'

export const ProductList = observer(() => {
  return <Space gap={16} top wrap>
    {page.it.items.map((item, index) => {
      return <ProductCard key={item.key} item={item} num={index + 1} />
    })}
  </Space>
})
