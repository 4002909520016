import { Scroll } from 'component/Scroll'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Items } from 'saas/page/group/preview/Items'
import { Search } from 'saas/page/group/preview/Search'

export const PreviewCard = observer(() => {
  return <Vertical wide>
    <Gap height={24} />
    <Divider secondary />
    <Gap height={24} />
    <Space gap={16} opposite>
      <Space gap={8}>
        <Icon size={24} name="list" secondary />
        <Typo size={15} semi>{i18n('group.Items')}</Typo>
      </Space>
      <Search />
    </Space>
    <Gap height={16} />
    <Space height={600}>
      <Scroll>
        <Items />
      </Scroll>
    </Space>
  </Vertical>
})
