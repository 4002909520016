// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentItem-comment-a123{padding:4px 8px;margin:-4px -8px;border-radius:var(--ui-border-radius-half);transition:opacity 200ms ease-in-out}.CommentItem-weak-a123{opacity:.4}.CommentItem-exclude-a123{opacity:.2}.CommentItem-comment-a123.CommentItem-selected-a123{background:var(--ui-background-color-soft);opacity:1}`, "",{"version":3,"sources":["webpack://./src/page/internal/comments/CommentItem.module.scss"],"names":[],"mappings":"AAAA,0BACE,eAAA,CACA,gBAAA,CACA,0CAAA,CACA,oCAAA,CAGF,uBACE,UAAA,CAGF,0BACE,UAAA,CAGF,oDACE,0CAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comment": `CommentItem-comment-a123`,
	"weak": `CommentItem-weak-a123`,
	"exclude": `CommentItem-exclude-a123`,
	"selected": `CommentItem-selected-a123`
};
module.exports = ___CSS_LOADER_EXPORT___;
