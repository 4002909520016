import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { commenting } from 'store/comment/CommentingStore'

export const CommentTitle = observer(() => {
  const store = commenting.comments
  const { loading, count } = store

  return <>
    <Space gap={4} oh>
      <Typo size={13} semi ellipsis>{i18n('item.Comments')}</Typo>
      {!!count && !loading && <Typo size={13} semi secondary>{count}</Typo>}
      {loading && <Icon name="progress_activity" size={12} tertiary />}
    </Space>
  </>
})
