import { Page } from 'feature/page/Page/Page'
import { observer } from 'mobx-react-lite'
import { View } from 'page/internal/collections/View'
import { ProductView } from 'page/internal/product/ProductView'
import { ProductsView } from 'page/internal/products/ProductsView'
import { SearchView } from 'page/internal/search/SearchView'
import { home } from 'store/home'
import { managerStore } from 'store/manager/store'

export const ManagerHome = observer(() => {
  const product = home.product.present
  const collection = home.collection.present

  return <Page unknown={!managerStore.page} content={() => {
    switch (managerStore.page) {
      case 'search':
        return <SearchView />
      case 'product':
        return product ? <ProductView /> : null
      case 'collection':
        return collection ? <ProductsView /> : null
      case 'company':
        return <View />
      default:
        return null
    }
  }} />
})
