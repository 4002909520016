import { rest } from 'app/rest'
import { FormStore } from 'form/store/FormStore'
import { i18n } from 'i18n'
import { makeAutoObservable } from 'mobx'
import { StringStore } from 'store/base/StringStore'

type PasswordResponse = {
  error?: string
}

function getErrorMessage(error: string | undefined): string | undefined {
  if (!error) return undefined
  switch (error) {
    case 'wrong current password':
      return i18n('error.WrongCurrentPassword')
    case 'password is too weak':
      return i18n('error.PasswordIsTooWeak')
    default:
      return i18n('error.SomethingWentWrong')
  }
}

export class PasswordFormStore {
  private readonly form = new FormStore()

  readonly current = this.form.field(new StringStore(''), { required: true })
  readonly password = this.form.field(new StringStore(''), { required: true })
  readonly confirmation = this.form.field(new StringStore(''), { required: true })

  private _done: boolean = false
  private _error: string | undefined

  constructor() {
    makeAutoObservable(this)
  }

  get done(): boolean {
    return this._done
  }

  get warn(): string | undefined {
    if (this.password.empty) return undefined
    if (this.same) return i18n('error.TheNewPasswordMustBeDifferent')
    if (!this.confirmed) return i18n('error.PasswordsDoNotMatch')
    return undefined
  }

  get error(): string | undefined {
    return this._error
  }

  get canSave(): boolean {
    return !this.form.error && !this.same && this.confirmed
  }

  check(): boolean {
    return this.form.check() && this.canSave
  }

  retry() {
    this.done = false
    this.error = undefined
  }

  async save() {
    try {
      this.done = false
      const request = {
        current: this.current.value,
        password: this.password.value,
        confirmation: this.confirmation.value,
      }
      const { error } = await rest.post<PasswordResponse>('/api/auth/password', request)
      this.error = getErrorMessage(error)
    } catch {
      this.error = i18n('error.SomethingWentWrong')
    } finally {
      this.done = true
    }
  }

  private get same(): boolean {
    return this.current.value === this.password.value
  }

  private get confirmed(): boolean {
    return this.password.value === this.confirmation.value
  }

  private set done(value: boolean) {
    this._done = value
  }

  private set error(value: string | undefined) {
    this._error = value
  }
}
