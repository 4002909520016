import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { TextField } from 'form/TextField'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { inviteUsers } from 'page/invite/store/InviteUsersStore'
import { UserFormStore } from 'page/invite/store/UserFormStore'
import css from './UserRow.module.scss'

type Props = {
  user: UserFormStore
}

export const UserRow = observer(({ user }: Props) => {
  const { key, name, email } = user

  const onRemove = useLatestCallback(async () => {
    await inviteUsers.it.removeUser(key)
  })

  return <Space gap={12}>
    <TextField small field={name} label={i18n('label.UserName')} />
    <TextField small field={email} label={i18n('label.Email')} />
    <Button action={onRemove} className={css.remove} filled>
      <Icon size={16} name="delete" />
    </Button>
  </Space>
})
