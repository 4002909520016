// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Scroll-scroll-68ca{width:100%;height:100%;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/component/Scroll/Scroll.module.scss"],"names":[],"mappings":"AAAA,oBACE,UAAA,CACA,WAAA,CACA,eAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": `Scroll-scroll-68ca`
};
module.exports = ___CSS_LOADER_EXPORT___;
