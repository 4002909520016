import { clsx } from 'clsx'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { PartialOptions } from 'overlayscrollbars'
import { useOverlayScrollbars } from 'overlayscrollbars-react'
import { Menu } from 'saas/screen/sidebar/Menu'
import { SideLogo } from 'saas/screen/sidebar/SideLogo'
import { sidebar } from 'saas/store/SidebarStore'
import css from './Sidebar.module.scss'

export const Sidebar = observer(() => {
  const { open } = sidebar
  const className = clsx('os-transitionend', css.sidebar, open && css.open)

  const options: PartialOptions = {
    overflow: { x: 'hidden', y: 'scroll' },
    scrollbars: { theme: 'os-theme-dark os-contrast' },
  }
  const [initialize, _instance] = useOverlayScrollbars({ defer: true, options })
  const onRef = useLatestCallback((element: HTMLDivElement | null) => {
    if (element) initialize(element)
  })

  return <div className={className}>
    <div className={css.fixed}>
      <div className={css.over}>
        <div ref={onRef} className={css.content}>
          <Vertical gap={12} pad={[24, 0]} tall>
            <Space center>
              <SideLogo />
            </Space>
            <Menu />
          </Vertical>
        </div>
      </div>
    </div>
  </div>
})
