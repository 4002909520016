// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyLogo-image-3fd7{display:block;width:100%;height:100%;object-fit:cover;object-position:left;background-color:var(--ui-background-color-weak)}.CompanyLogo-space-3fd7{width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/CompanyLogo.module.scss"],"names":[],"mappings":"AAAA,wBACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,oBAAA,CACA,gDAAA,CAGF,wBACE,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `CompanyLogo-image-3fd7`,
	"space": `CompanyLogo-space-3fd7`
};
module.exports = ___CSS_LOADER_EXPORT___;
