import { makeAutoObservable } from 'mobx'
import { DocSectionData } from 'type/Doc'
import { getNextKey } from 'util/id'

export class DocSectionStore {
  readonly key = getNextKey()
  readonly data: DocSectionData

  private _ref: HTMLElement | undefined
  private _expanded = true
  private _hide = false

  constructor(data: DocSectionData) {
    makeAutoObservable(this, { key: false, data: false })
    this.data = data
  }

  get ref(): HTMLElement | undefined {
    return this._ref
  }

  set ref(value: HTMLElement | undefined) {
    this._ref = value
  }

  get expanded(): boolean {
    return this._expanded
  }

  set expanded(value: boolean) {
    this._expanded = value
  }

  get hide(): boolean {
    return this._hide
  }

  set hide(value: boolean) {
    this._hide = value
  }
}
