import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'

type Props = {
  store: ApiKeyStore
}

export const Origins = observer(({ store }: Props) => {
  return <>
    <Gap height={8} />
    <Vertical gap={4} pad={[0, 4]}>
      {store.origins.map((origin, index) => <Typo size={12} key={index} wrap>
        {origin}
      </Typo>)}
    </Vertical>
  </>
})
