import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { NameInput } from 'saas/page/products/components/NameInput'
import { TypeSelect } from 'saas/page/products/components/TypeSelect'
import { productCreate } from 'saas/store/product/ProductCreateStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'

export const ProductCreateModal = observer(() => {
  const { busy } = productsSaas.it
  const { canSave } = productCreate.it

  const onSave = useLatestCallback(async () => {
    if (!productCreate.it.check()) return
    productCreate.it.save()
  })

  const onCancel = useLatestCallback(async () => {
    productCreate.close()
  })

  return <Modal title={i18n('product.NewProduct')} onCancel={onCancel}>
    <Vertical gap={20}>
      <TypeSelect />
      <NameInput />
      <Space gap={12} spread wrap>
        <Button filled compact action={onCancel}>
          {i18n('common.Cancel')}
        </Button>
        <Button primary disabled={busy || !canSave} action={onSave}>
          {i18n('common.Create')}
        </Button>
      </Space>
    </Vertical>
  </Modal>
})
