import { SearchInput } from 'component/Input/SearchInput'
import { SelectOption } from 'component/Select'
import { Select } from 'component/Select/Select'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { page } from 'store/product'
import { ExternalStatus, getReviewStatusLabel, reviewStatusValues } from 'type/ExternalStatus'
import { getItemStatusLabel, InternalStatus, itemStatusValues } from 'type/InternalStatus'
import { nil } from 'util/null'
import css from './ProductFilters.module.scss'

export const ProductFilters = observer(() => {
  const { collection, search, status, review } = page.it
  const { statuses, reviews } = collection

  const statusOptions: SelectOption<InternalStatus | ''>[] = [
    { value: '', label: <Typo tertiary>{i18n('moderation.All')}</Typo> },
    ...itemStatusValues.map(status => ({
      value: status,
      disabled: !statuses[status],
      label: <Space gap={8} opposite>
        <Typo>{getItemStatusLabel(status)}</Typo>
        <Typo tertiary>{statuses[status] || ''}</Typo>
      </Space>,
    })),
  ]

  const reviewOptions: SelectOption<ExternalStatus | ''>[] = [
    { value: '', label: <Typo tertiary>{i18n('review.All')}</Typo> },
    ...reviewStatusValues.map(status => ({
      value: status,
      disabled: !reviews[status],
      label: <Space gap={8} opposite>
        <Typo>{getReviewStatusLabel(status)}</Typo>
        <Typo tertiary>{reviews[status] || ''}</Typo>
      </Space>,
    })),
  ]

  const onChangeStatus = useLatestCallback((value: InternalStatus | '' | nil) => {
    page.it.status = value || ''
  })

  const onChangeReview = useLatestCallback((value: ExternalStatus | '' | nil) => {
    page.it.review = value || ''
  })

  const onChangeSearch = useLatestCallback((value: string | '' | nil) => {
    page.it.search = value || ''
  })

  return <Space gap={12} wide wrap>
    <Select className={css.status} value={status} options={statusOptions} onChange={onChangeStatus}
      placeholder={i18n('placeholder.ItemStatus')} />
    <Select className={css.status} value={review} options={reviewOptions} onChange={onChangeReview}
      placeholder={i18n('placeholder.ReviewStatus')} />
    <SearchInput className={css.search} value={search} onChange={onChangeSearch}
      placeholder={i18n('placeholder.SearchItems')} />
  </Space>
})
