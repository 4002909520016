// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommentEditor-inline-6edd{border:1px solid var(--ui-border-color-secondary);border-radius:var(--ui-border-radius)}.CommentEditor-editor-6edd:focus-within{border-color:var(--ui-primary-color)}.CommentEditor-scroll-6edd{max-height:128px;background-color:var(--ui-background-color-soft) !important;border-radius:var(--ui-border-radius-half)}.CommentEditor-input-6edd{resize:none !important;border:none;box-shadow:none !important;background-color:rgba(0,0,0,0) !important;overflow:hidden !important}`, "",{"version":3,"sources":["webpack://./src/page/internal/comments/CommentEditor.module.scss"],"names":[],"mappings":"AAKA,2BACE,iDAAA,CACA,qCAAA,CAGF,wCACE,oCAAA,CAGF,2BACE,gBAAA,CACA,2DAAA,CACA,0CAAA,CAGF,0BACE,sBAAA,CACA,WAAA,CACA,0BAAA,CACA,yCAAA,CACA,0BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inline": `CommentEditor-inline-6edd`,
	"editor": `CommentEditor-editor-6edd`,
	"scroll": `CommentEditor-scroll-6edd`,
	"input": `CommentEditor-input-6edd`
};
module.exports = ___CSS_LOADER_EXPORT___;
