import { Popover } from 'component/Popover'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { CollectionStats } from 'type/Collection'
import { getReviewStatusLabel } from 'type/ExternalStatus'
import { getItemStatusLabel } from 'type/InternalStatus'
import css from './StatsSection.module.scss'

type Props = {
  stats: CollectionStats
}

export const StatsSection = observer(({ stats }: Props) => {
  const { full_count } = stats
  const status = stats.internal_statuses.length
  const review = stats.external_statuses.length

  return <Space gap={[8, 12]} columns wrap>
    <Space gap={4}>
      <Typo size={11}>{i18n('item.Items')}</Typo>
      <Typo size={11} semi>{full_count}</Typo>
    </Space>
    <Pop content={<IntStats stats={stats} />}>
      <Space gap={4}>
        <Typo size={11}>{i18n('label.ItemStatusShort')}</Typo>
        <Typo size={11} semi>{status}</Typo>
      </Space>
    </Pop>
    <Pop content={<ExtStats stats={stats} />}>
      <Space gap={4}>
        <Typo size={11}>{i18n('label.ReviewStatusShort')}</Typo>
        <Typo size={11} semi>{review}</Typo>
      </Space>
    </Pop>
  </Space>
})

type PopProps = {
  children: ReactNode
  content: ReactNode
}

const Pop = ({ children, content }: PopProps) => {
  return <Popover content={content} trigger="hover" placement="topLeft" space={8} pad={8}>
    <div className={css.pop}>{children}</div>
  </Popover>
}

const IntStats = observer(({ stats }: Props) => {
  return <Vertical>
    {stats.internal_statuses.map(stat => (
      <Space key={stat.status} gap={8} height={32} opposite>
        <Typo size={13} ellipsis>{getItemStatusLabel(stat.status)}</Typo>
        <Typo size={13} secondary>{stat.count}</Typo>
      </Space>
    ))}
  </Vertical>
})

const ExtStats = observer(({ stats }: Props) => {
  return <Vertical gap={8}>
    {stats.external_statuses.map(stat => (
      <Space key={stat.status} gap={8} opposite>
        <Typo size={13} ellipsis>{getReviewStatusLabel(stat.status)}</Typo>
        <Typo size={13} secondary>{stat.count}</Typo>
      </Space>
    ))}
  </Vertical>
})
