// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PreContainer-container-b6fc{margin:12px 0;border-radius:8px;color:#1f2328;background:#f5f5f5}.PreContainer-scroll-b6fc{max-height:300px}.PreContainer-pre-b6fc>pre{margin:0}.PreContainer-pre-b6fc code{background:rgba(0,0,0,0) !important;padding-right:24px !important}`, "",{"version":3,"sources":["webpack://./src/saas/page/doc/PreContainer.module.scss"],"names":[],"mappings":"AAAA,6BACE,aAAA,CACA,iBAAA,CACA,aAAA,CACA,kBAAA,CAGF,0BACE,gBAAA,CAGF,2BACE,QAAA,CAGF,4BACE,mCAAA,CACA,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PreContainer-container-b6fc`,
	"scroll": `PreContainer-scroll-b6fc`,
	"pre": `PreContainer-pre-b6fc`
};
module.exports = ___CSS_LOADER_EXPORT___;
