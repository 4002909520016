import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { getProductTypeTitle } from 'type/product/ProductType'
import { formatDateTime, parseDate } from 'util/date'
import { emdash, space } from 'util/typo'

export const TitleName = observer(() => {
  const { json } = productSaas.it
  const { name, type, created_at, updated_at } = json

  return <Vertical>
    <Typo size={23} bold wrap>
      {name}
    </Typo>
    <Space gap={8} wrap>
      <Typo size={11}>
        {getProductTypeTitle(type)}
      </Typo>
      <Space gap={8} wrap>
        <Typo size={11} secondary>
          {i18n('label.CreatedAt')}
          {space}
          {formatDateTime(parseDate(created_at)) ?? emdash}
        </Typo>
        <Typo size={11} secondary>
          {i18n('label.LastUpdate')}
          {space}
          {formatDateTime(parseDate(updated_at)) ?? emdash}
        </Typo>
      </Space>
    </Space>
  </Vertical>
})
