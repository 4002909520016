// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EndpointContainer-endpoint-e666{--endpoint-color: #61AFFD;--endpoint-background: #61AFFD1F;font-size:13px;font-weight:600;padding:4px;border-radius:8px;background-color:var(--endpoint-background);border:1px solid var(--endpoint-color)}.EndpointContainer-post-e666{--endpoint-background: #4ACC901F;--endpoint-color: #4ACC90}.EndpointContainer-method-e666{padding:0 16px;line-height:36px;border-radius:6px;background:var(--endpoint-color);color:#fff}.EndpointContainer-path-e666{line-height:36px;flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/saas/page/doc/EndpointContainer.module.scss"],"names":[],"mappings":"AAAA,iCACE,yBAAA,CACA,gCAAA,CAEA,cAAA,CACA,eAAA,CACA,WAAA,CACA,iBAAA,CAEA,2CAAA,CACA,sCAAA,CAGF,6BACE,gCAAA,CACA,yBAAA,CAGF,+BACE,cAAA,CACA,gBAAA,CACA,iBAAA,CACA,gCAAA,CACA,UAAA,CAGF,6BACE,gBAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"endpoint": `EndpointContainer-endpoint-e666`,
	"post": `EndpointContainer-post-e666`,
	"method": `EndpointContainer-method-e666`,
	"path": `EndpointContainer-path-e666`
};
module.exports = ___CSS_LOADER_EXPORT___;
