import { Switch as SwitchAntd } from 'antd'

type Props = {
  checked: boolean
  disabled?: boolean
  onChange(checked: boolean): void
}

export function Switch({ checked, disabled, onChange }: Props) {
  return <SwitchAntd checked={checked} onChange={onChange} disabled={disabled} />
}
