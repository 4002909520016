// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatsSection-pop-536f:hover{color:var(--ui-primary-color)}`, "",{"version":3,"sources":["webpack://./src/page/internal/collections/StatsSection.module.scss"],"names":[],"mappings":"AAAA,6BACE,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pop": `StatsSection-pop-536f`
};
module.exports = ___CSS_LOADER_EXPORT___;
