type Value = number | string | null | undefined

type Comparator<T> = {
  (a: T, b: T): number
}

function compare<V>(a: V, b: V, nilLast?: boolean): number {
  if (a === b) return 0
  if (a == null) return nilLast ? 1 : -1
  if (b == null) return nilLast ? -1 : 1
  return a > b ? 1 : a < b ? -1 : 0
}

export function by<T>(field: (item: T) => Value, desc?: boolean, nilLast?: boolean): Comparator<T> {
  return (left: T, right: T) => {
    const a = field(left)
    const b = field(right)
    return desc ? compare(b, a, !nilLast) : compare(a, b, nilLast)
  }
}
