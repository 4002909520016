import { getAppTitle } from 'app/config/title'
import { Link } from 'component/Link'
import { Tooltip } from 'component/Popover/Tooltip'
import { Space } from 'component/Space'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { Logo } from 'saas/screen/sidebar/Logo'
import { sidebar } from 'saas/store/SidebarStore'
import css from './SideLogo.module.scss'

export const SideLogo = observer(() => {
  const { open } = sidebar
  const tooltip = getAppTitle()

  if (!open) return <Space height={48}>
    <Tooltip title={tooltip} right>
      <Link path="/">
        <Space className={css.home} center>
          <Icon size={16} name="home" />
        </Space>
      </Link>
    </Tooltip>
  </Space>

  return <Link className={css.large} path="/">
    <Logo />
  </Link>
})
