import { Button } from 'component/Button'
import { PageHeader } from 'component/PageHeader'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import {
  groupDeleteModal,
  GroupDeleteModalStore,
} from 'saas/store/groups/GroupDeleteModalStore'
import { groupSaas } from 'saas/store/groups/GroupStore'
import { route } from 'store/route'

export const Title = observer(() => {
  const { company_id } = groupSaas.it
  const back = `/company/${company_id}/groups`

  const onDelete = useLatestCallback(() => {
    groupDeleteModal.open(new GroupDeleteModalStore(groupSaas.it))
  })

  const handleBack = () => {
    route.push(back)
  }

  return (
    <PageHeader title={i18n('group.Group')} onBack={handleBack}>
      <Button
        link
        error
        icon={<Icon size={16} name="delete" />}
        action={onDelete}
      >
        {i18n('group.DeleteGroup')}
      </Button>
    </PageHeader>
  )
})
