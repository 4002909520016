import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { View } from 'saas/page/product/common/View/View'
import { Details } from 'saas/page/product/tryon/Details/Details'
import { Preview } from 'saas/page/product/tryon/Preview/Preview'
import { SettingsCard } from 'saas/page/product/tryon/SettingsCard/SettingsCard'
import css from './TryOnView.module.scss'

export const TryOnView = observer(() => {
  return <View>
    <Space className={css.center} gap={16} pad={16} wide top opposite wrap>
      <SettingsCard />
      <Preview />
    </Space>
    <Details />
  </View>
})
