// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatusTag-status-3f36{height:24px;padding:0 6px;gap:6px;border-radius:4px;font-size:11px;width:fit-content;background-color:var(--ui-background-color-hover)}.StatusTag-success-3f36{color:rgba(45,159,121,.8784313725);background-color:rgba(44,201,149,.1215686275)}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/common/StatusTag/StatusTag.module.scss"],"names":[],"mappings":"AAAA,uBACE,WAAA,CACA,aAAA,CACA,OAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,iDAAA,CAGF,wBACE,kCAAA,CACA,6CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `StatusTag-status-3f36`,
	"success": `StatusTag-success-3f36`
};
module.exports = ___CSS_LOADER_EXPORT___;
