import { Button } from 'component/Button'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { TitleName } from 'saas/page/product/common/Title/TitleName'
import { productDeleteModal, ProductDeleteModalStore } from 'saas/store/product/ProductDeleteModalStore'
import { productSaas } from 'saas/store/product/ProductSaasController'
import { ui } from 'store/home/ui/HomeUiStore'

export const Title = observer(() => {
  const { mobile } = ui.window
  const { company_id, busy, edit } = productSaas.it
  const { canSave } = edit

  const onSave = useLatestCallback(() => {
    void productSaas.it.saveSettings()
  })

  const onDelete = useLatestCallback(() => {
    productDeleteModal.open(new ProductDeleteModalStore(productSaas.it))
  })

  return <Space gap={16} pad={[16, mobile ? 16 : 0]} opposite wrap>
    <Vertical gap={8} flex="1 1 auto">
      <Link path={`/company/${company_id}/products`} small secondary>
        <Space gap={8}>
          <Icon size={16} name="arrow_back" />
          <Typo>{i18n('product.ToListOfProducts')}</Typo>
        </Space>
      </Link>
      <TitleName />
    </Vertical>
    <Space gap={8} wrap>
      <Button primary disabled={busy || !canSave} action={onSave}>
        <Icon size={16} name="done_all"/>
        {i18n('common.Save')}
      </Button>
      <Button error disabled={busy} action={onDelete}>
        <Icon size={16} name="delete"/>
        {i18n('product.DeleteProduct')}
      </Button>
    </Space>
  </Space>
})
