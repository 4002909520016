import { clsx } from 'clsx'
import { IconButton } from 'component/Button/IconButton'
import { useLatestCallback } from 'hook/useLatestCallback'
import { managerSearchStore } from 'store/manager/search'
import css from './FiltersTag.module.scss'

export function FiltersTag() {
  const { filterCount } = managerSearchStore
  const className = clsx(css.tag, filterCount && css.primary)

  const onClick = useLatestCallback(() => {
    managerSearchStore.pin = !managerSearchStore.pin
  })

  return <IconButton action={onClick}>
    <div className={className}>
      {filterCount}
    </div>
  </IconButton>
}
