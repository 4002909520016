import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import { Links } from 'saas/page/product/bot/Details/Links'
import { Status } from 'saas/page/product/bot/Details/Status'
import css from './Details.module.scss'

export const Details = observer(() => {
  return <Space className={css.root} gap={16} top wide wrap oh>
    <Status />
    <Links />
  </Space>
})
