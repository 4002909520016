import { clsx } from 'clsx'
import { Divider } from 'component/Space/Divider'
import { observer } from 'mobx-react-lite'
import { Fragment } from 'react'
import { ChildContainer } from 'saas/page/doc/ChildContainer'
import { DocSubtitle } from 'saas/page/doc/DocSubtitle'
import { EndpointContainer, parseEndpointProps } from 'saas/page/doc/EndpointContainer'
import { PreContainer } from 'saas/page/doc/PreContainer'
import { DocSectionStore } from 'saas/page/doc/store/DocSectionStore'
import css from './DocSection.module.scss'

type Props = {
  section: DocSectionStore
}

function renderChild(child: ChildNode) {
  const pre = child instanceof Element && child.tagName === 'PRE'
  if (pre) return <PreContainer child={child} />

  const endpoint = parseEndpointProps(child)
  if (endpoint) return <EndpointContainer endpoint={endpoint} />

  return <ChildContainer child={child} />
}

export const DocSection = observer(({ section }: Props) => {
  const { expanded } = section
  const children = section.data.children

  return <>
    {section.data.level < 3 && <Divider key={section.key} secondary />}
    <DocSubtitle section={section} />
    <div className={clsx(css.content, !expanded && css.collapse)}>
      {children.map((it, i) => <Fragment key={i}>
        {renderChild(it)}
      </Fragment>)}
    </div>
  </>
})
