import { useEffectOnce } from 'hook/useEffectOnce'
import { observer } from 'mobx-react-lite'
import { SearchSaasView } from 'page/internal/search/SearchSaasView'
import { home } from 'store/home'
import { managerSearchStore } from 'store/manager/search'

export const SearchPage = observer(() => {
  useEffectOnce(() => {
    home.collection.close()

    const companyId = managerSearchStore.parseCompanyId() ?? home.company.optional?.company.company_id
    const company = home.companies.findRoute(companyId)
    if (!company) return

    managerSearchStore.init(company.company_id)
  })

  return <SearchSaasView />
})
