import { clsx } from 'clsx'
import { IconName } from 'icon/types'
import { CSSProperties, MouseEvent } from 'react'
import { omit } from 'util/object'
import css from './Icon.module.scss'

type Props = {
  name: IconName
  size?: number
  title?: string
  fill?: boolean
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  error?: boolean
  success?: boolean
  className?: string
  style?: CSSProperties
  onClick?(event: MouseEvent): void
  onMouseDown?(event: MouseEvent): void
}

function getSizeClass(size: number | undefined): string | undefined {
  if (!size) return undefined
  if (size >= 48) return css.size48
  if (size >= 40) return css.size40
  if (size >= 24) return css.size24
  if (size >= 20) return css.size20
  return undefined
}

/**
 * Preview with search - https://fonts.google.com/icons
 */
export function Icon(props: Props) {
  const { name, size, title, fill, primary, secondary, tertiary, error, success, onClick, onMouseDown } = props

  const color = error ? css.error : success ? css.success : primary ? css.primary :
    secondary ? css.secondary : tertiary ? css.tertiary : undefined
  const className = clsx(props.className, css.icon, getSizeClass(size), fill && css.fill, color)
  const style = omit<CSSProperties>({ ...props.style, fontSize: size })

  return <span className={className} title={title} style={style} onClick={onClick} onMouseDown={onMouseDown}>
    {name}
  </span>
}
