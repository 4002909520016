// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AgendaCard-scroll-474e{max-height:calc(100vh - 32px)}`, "",{"version":3,"sources":["webpack://./src/saas/page/doc/AgendaCard.module.scss"],"names":[],"mappings":"AAAA,wBACE,6BAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scroll": `AgendaCard-scroll-474e`
};
module.exports = ___CSS_LOADER_EXPORT___;
