import { Spinner } from 'component/Spinner'
import { ErrorPage } from 'feature/page/ErrorPage'
import { LoadingPage } from 'feature/page/LoadingPage'
import { Icon } from 'icon/Icon'
import { ReactElement } from 'react'
import { NotFoundPage } from 'saas/page/404/NotFoundPage'
import { AsyncStoreController } from 'store/base/async/AsyncStoreController'
import { LazyStore } from 'store/base/LazyStore'
import { LoadingState } from 'type/Store'

export function renderStubIcon<T>(form: LazyStore<T> | undefined): ReactElement {
  if (form?.opening) return <Spinner />
  if (form?.fail) return <Icon name="error" size={20} error />
  if (form?.present) return <Icon name="check_circle" size={20} tertiary />
  return <Icon name="remove" size={20} tertiary style={{ opacity: 0.2 }} />
}

export function renderAsyncState(controller: AsyncStoreController): ReactElement | undefined {
  const entry = controller.entry
  if (!entry) return <NotFoundPage />
  if (entry.error) return <ErrorPage text={entry.error} />
  if (!entry.ready) return <LoadingPage />
  if (entry.absent) return <NotFoundPage />
  return undefined
}

export function renderLoadingState(state: LoadingState, error?: string): ReactElement | undefined {
  switch (state) {
    case 'none':
      return <LoadingPage />
    case 'error':
      return <ErrorPage text={error} />
    case 'unknown':
      return <NotFoundPage />
    case 'ready':
      return undefined
    default:
      console.error('unknown state', state)
      return <ErrorPage />
  }
}
