import { Field } from 'component/Field'
import { SearchSelect, SelectOption } from 'component/Select'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { groupsSaas } from 'saas/store/groups/GroupsCache'
import { stand } from 'saas/store/product/ProductSaasController'

export const GroupSelect = observer(() => {
  const { group } = stand.it.edit
  const { focus, error, onChange, onFocus } = group

  const options: SelectOption[] = groupsSaas.it.groups.map(group => ({
    value: group.group_id,
    label: group.json.group_name,
  }))

  return <Field label={i18n('stand.ProductGroup')}>
    <SearchSelect wide onChange={onChange} onFocus={onFocus} value={group.value} options={options}
      placeholder={i18n('stand.ProductGroup')} error={!focus && error} />
  </Field>
})
