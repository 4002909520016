import { observer } from 'mobx-react-lite'
import { SaasGroupsView } from 'saas/page/groups/components/SaasGroupsView'
import { saasGroupsPageController } from 'saas/page/groups/SaasGroupsPageController'
import { Page } from 'saas/screen/Page'
import { usePageController } from 'store/base/page/usePageController'
import { route } from 'store/route'

export const SaasGroupsPage = observer(() => {
  const company_id = route.unit(1)

  const stub = usePageController(saasGroupsPageController, { company_id })
  if (stub) return stub

  return (
    <Page>
      <SaasGroupsView />
    </Page>
  )
})
