import { FitImage } from 'component/Image/FitImage'
import { Space } from 'component/Space'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { CollectionStore } from 'store/collection'
import { times } from 'util/array'
import css from './ImageStack.module.scss'

type ImgProps = {
  source: string | undefined
}

const Img = ({ source }: ImgProps) => {

  return <Space className={css.image} center>
    {source ? <FitImage src={source} /> : <Icon name="image" size={16} tertiary />}
  </Space>
}

type Props = {
  collection: CollectionStore
}

export const ImageStack = observer(({ collection }: Props) => {
  const sources = collection.data.json.previews || []

  return <div className={css.stack}>
    {times(5).map((index) => {
      const source = sources[index]
      return <Img key={index + ' ' + source} source={source} />
    }).reverse()}
  </div>
})
