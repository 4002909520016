// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown-divider-52d5{height:0;border-bottom:1px solid var(--ui-border-color-tertiary)}.Dropdown-button-52d5{min-height:32px;height:fit-content;padding:0 8px}.Dropdown-icon-52d5{min-width:16px;height:16px;font-size:16px;display:flex}`, "",{"version":3,"sources":["webpack://./src/component/Popover/Dropdown.module.scss"],"names":[],"mappings":"AAAA,uBACE,QAAA,CACA,uDAAA,CAGF,sBACE,eAAA,CACA,kBAAA,CACA,aAAA,CAGF,oBACE,cAAA,CACA,WAAA,CACA,cAAA,CACA,YAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `Dropdown-divider-52d5`,
	"button": `Dropdown-button-52d5`,
	"icon": `Dropdown-icon-52d5`
};
module.exports = ___CSS_LOADER_EXPORT___;
