import { clsx } from 'clsx'
import { Space } from 'component/Space'
import { useLatestCallback } from 'hook/useLatestCallback'
import { observer } from 'mobx-react-lite'
import { PartialOptions } from 'overlayscrollbars'
import { useOverlayScrollbars } from 'overlayscrollbars-react'
import { Menu } from 'saas/screen/sidebar/Menu'
import { TopLogo } from 'saas/screen/sidebar/TopLogo'
import { sidebar } from 'saas/store/SidebarStore'
import css from './Bar.module.scss'

export const Bar = observer(() => {
  const { open } = sidebar
  const className = clsx('os-transitionend', css.bar, open && css.open)

  const options: PartialOptions = {
    overflow: { x: 'hidden', y: 'scroll' },
    scrollbars: { theme: 'os-theme-dark os-contrast' },
  }
  const [initialize, _instance] = useOverlayScrollbars({ defer: true, options })
  const onRef = useLatestCallback((element: HTMLDivElement | null) => {
    if (element) initialize(element)
  })

  return <div className={className}>
    <Space className={css.top} wide>
      <TopLogo />
    </Space>
    <div className={css.fixed}>
      <div className={css.over}>
        <div ref={onRef} className={css.content}>
          <Menu />
        </div>
      </div>
    </div>
  </div>
})
