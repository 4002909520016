import { Card } from 'component/Card'
import { SearchInput } from 'component/Input/SearchInput'
import { Gap } from 'component/Space/Gap'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { GroupRow } from 'saas/page/groups/components/GroupRow'
import { groupsSaas } from 'saas/store/groups/GroupsCache'
import { Table } from 'component/Table'

export const GroupsTable = observer(() => {
  const { filter, filteredGroups } = groupsSaas.it
  const empty = !filteredGroups.length

  const onChangeFilter = useLatestCallback((value: string) => {
    groupsSaas.it.filter = value
  })

  return (
    <Card pad={24} vertical>
      <SearchInput
        value={filter}
        onChange={onChangeFilter}
        placeholder={i18n('placeholder.Search')}
      />
      <Gap height={16} />
      <Table>
        <Table.THead>
          <Table.Td>{i18n('label.Name')}</Table.Td>
          <Table.Td>{i18n('group.Conditions')}</Table.Td>
        </Table.THead>
        <Table.TBody>
          {filteredGroups.map((group) => (
            <GroupRow key={group.group_id} group={group} />
          ))}
          {empty && (
            <Table.Tr>
              <Table.Td colSpan={2}>
                <Typo size={14} tertiary>
                  {i18n('group.NoItemGroups')}
                </Typo>
              </Table.Td>
            </Table.Tr>
          )}
        </Table.TBody>
      </Table>
    </Card>
  )
})
