// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Bar-bar-b3ca{--top: 72px;--height: 0;z-index:99;flex:0 0 auto;position:relative;width:max(100vw,100%);height:var(--top);transition:height var(--transition) ease-in-out;box-sizing:border-box;overflow:hidden}.Bar-open-b3ca{--height: calc(100vh - var(--top))}.Bar-top-b3ca{position:fixed;top:0;left:0;right:0;height:var(--top);background-color:var(--sidebar-background)}.Bar-fixed-b3ca{position:fixed;top:var(--top);left:0;right:0;height:var(--height);transition:height var(--transition) ease-in-out}.Bar-over-b3ca{position:relative;width:100%;height:100%;overflow:hidden;background-color:var(--sidebar-background)}.Bar-content-b3ca{position:absolute;top:0;left:0;right:0;height:var(--height);visibility:hidden}.Bar-open-b3ca .Bar-content-b3ca{visibility:visible}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/Bar.module.scss"],"names":[],"mappings":"AAAA,cACE,WAAA,CACA,WAAA,CAEA,UAAA,CACA,aAAA,CACA,iBAAA,CACA,qBAAA,CACA,iBAAA,CACA,+CAAA,CACA,qBAAA,CACA,eAAA,CAGF,eACE,kCAAA,CAGF,cACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,iBAAA,CACA,0CAAA,CAGF,gBACE,cAAA,CACA,cAAA,CACA,MAAA,CACA,OAAA,CACA,oBAAA,CACA,+CAAA,CAGF,eACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,0CAAA,CAGF,kBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,OAAA,CACA,oBAAA,CACA,iBAAA,CAGF,iCACE,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bar": `Bar-bar-b3ca`,
	"open": `Bar-open-b3ca`,
	"top": `Bar-top-b3ca`,
	"fixed": `Bar-fixed-b3ca`,
	"over": `Bar-over-b3ca`,
	"content": `Bar-content-b3ca`
};
module.exports = ___CSS_LOADER_EXPORT___;
