import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { DocSection } from 'saas/page/doc/DocSection'
import { doc } from 'saas/page/doc/store/DocStore'
import css from './DocCard.module.scss'

export const DocCard = observer(() => {
  const { sections } = doc

  return <Card className={css.markdown} vertical gap={16} pad={16} wide oh>
    <Space gap={8}>
      <Icon size={24} name="code" tertiary />
      <Typo size={18} semi>{i18n('docs.Documentation')}</Typo>
    </Space>
    {sections.map(it => it.hide ? null : <DocSection key={it.key} section={it} />)}
  </Card>
})
