// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Condition-wrapper-d4e9{background:#f5f5f5;border-radius:8px}.Condition-button-d4e9.Condition-button-d4e9{min-height:28px;height:28px;padding:0 16px;border-radius:6px}`, "",{"version":3,"sources":["webpack://./src/saas/page/group/components/Condition.module.scss"],"names":[],"mappings":"AAAA,wBACE,kBAAA,CACA,iBAAA,CAGF,6CACE,eAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Condition-wrapper-d4e9`,
	"button": `Condition-button-d4e9`
};
module.exports = ___CSS_LOADER_EXPORT___;
