import { i18n } from 'i18n'

export const SORT_FIELDS = ['updated_at', 'created_at'] as const
export type SortField = typeof SORT_FIELDS[number]

export function parseSortField(text: string | null | undefined): SortField | undefined {
  if (SORT_FIELDS.includes(text as SortField)) return text as SortField
  return undefined
}

export function getSortFieldLabel(field: SortField): string {
  if (!field) return i18n('label.None')

  switch (field) {
    case 'updated_at':
      return i18n('sort.LastUpdate')
    case 'created_at':
      return i18n('sort.CreationDate')
    default:
      return ('' + field).toUpperCase()
  }
}
