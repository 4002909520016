import { FormStore } from 'form/store/FormStore'
import { makeAutoObservable, observable } from 'mobx'
import { UploadImageFile } from 'saas/store/product/basic/UploadImageFile'
import { StringStore } from 'store/base/StringStore'
import { StandProduct } from 'type/product/StandProduct'
import { fixText, validateLink } from 'util/form'
import { equal, updateObject } from 'util/object'

export class StandEditStore {
  private _json: StandProduct
  private form = new FormStore()
  readonly name = this.form.field(new StringStore(), { required: true, fix: fixText })
  private _lang: string | undefined
  readonly logo = new UploadImageFile()
  readonly shopUrl = this.form.field(new StringStore(), { fix: fixText, validate: validateLink })
  readonly shopButton = this.form.field(new StringStore(), { fix: fixText })
  readonly group = this.form.field(new StringStore(), { required: true })
  readonly title = this.form.field(new StringStore(), { fix: fixText })
  readonly icon = new UploadImageFile()
  readonly description = this.form.field(new StringStore(), {})
  readonly image = new UploadImageFile()
  private _brandFilter = false
  private _styleFilter = false
  private _genderFilter = false
  private _priceSorting = false
  private _shareCatalogButton = false
  private _prices = false
  private _buyButtons = false
  private _brandNames = false
  private _articles = false
  private _search = false
  private _mobileBannerEnabled = false
  private _desktopBannerEnabled = false
  readonly mobileBanner = new UploadImageFile()
  readonly desktopBanner = new UploadImageFile({ size: 1920 })
  private _widget: string | undefined

  constructor(json: StandProduct) {
    makeAutoObservable<this, '_json'>(this, { _json: observable.ref })
    this._json = json
    this.applyJson(json)
  }

  get json(): StandProduct {
    return this._json
  }

  set json(value: StandProduct) {
    this._json = value
  }

  get lang(): string | undefined {
    return this._lang
  }

  set lang(value: string | undefined) {
    this._lang = value
  }

  get brandFilter(): boolean {
    return this._brandFilter
  }

  set brandFilter(value: boolean) {
    this._brandFilter = value
  }

  get styleFilter(): boolean {
    return this._styleFilter
  }

  set styleFilter(value: boolean) {
    this._styleFilter = value
  }

  get genderFilter(): boolean {
    return this._genderFilter
  }

  set genderFilter(value: boolean) {
    this._genderFilter = value
  }

  get priceSorting(): boolean {
    return this._priceSorting
  }

  set priceSorting(value: boolean) {
    this._priceSorting = value
  }

  get shareCatalogButton(): boolean {
    return this._shareCatalogButton
  }

  set shareCatalogButton(value: boolean) {
    this._shareCatalogButton = value
  }

  get prices(): boolean {
    return this._prices
  }

  set prices(value: boolean) {
    this._prices = value
  }

  get buyButtons(): boolean {
    return this._buyButtons
  }

  set buyButtons(value: boolean) {
    this._buyButtons = value
  }

  get brandNames(): boolean {
    return this._brandNames
  }

  set brandNames(value: boolean) {
    this._brandNames = value
  }

  get articles(): boolean {
    return this._articles
  }

  set articles(value: boolean) {
    this._articles = value
  }

  get search(): boolean {
    return this._search
  }

  set search(value: boolean) {
    this._search = value
  }

  get mobileBannerEnabled(): boolean {
    return this._mobileBannerEnabled
  }

  set mobileBannerEnabled(value: boolean) {
    this._mobileBannerEnabled = value
  }

  get desktopBannerEnabled(): boolean {
    return this._desktopBannerEnabled
  }

  set desktopBannerEnabled(value: boolean) {
    this._desktopBannerEnabled = value
  }

  get widget(): string | undefined {
    return this._widget
  }

  set widget(value: string | undefined) {
    this._widget = value
  }

  get changed(): boolean {
    const updated = updateObject(this.json, this.updates)
    return !equal(this.json, updated)
  }

  get canSave(): boolean {
    const busy = this.logo.busy || this.icon.busy || this.image.busy || this.form.error
      || this.mobileBanner.busy || this.desktopBanner.busy
    return !this.form.error && this.changed && !busy
  }

  check() {
    return this.form.check() && this.canSave
  }

  update(json: StandProduct) {
    this.applyJson(json)
  }

  reset() {
    this.applyJson(this._json)
  }

  get updates(): Partial<StandProduct> {
    const { lang } = this
    const name = this.name.value || undefined
    const logo = this.logo.value || undefined
    const shopUrl = this.shopUrl.value || undefined
    const shopButton = this.shopButton.value || undefined
    const group = this.group.value
    const title = this.title.value || undefined
    const icon = this.icon.value || undefined
    const description = this.description.value || undefined
    const image = this.image.value || undefined
    const brandFilter = this._brandFilter || undefined
    const styleFilter = this._styleFilter || undefined
    const genderFilter = this._genderFilter || undefined
    const priceSorting = this._priceSorting || undefined
    const shareCatalogButton = this._shareCatalogButton || undefined
    const prices = this._prices || undefined
    const buyButtons = this._buyButtons || undefined
    const brandNames = this._brandNames || undefined
    const articles = this._articles || undefined
    const search = this._search || undefined
    const banners = {
      mobile: {
        enabled: this._mobileBannerEnabled || undefined,
        url: this.mobileBanner.value || undefined,
      },
      desktop: {
        enabled: this._desktopBannerEnabled || undefined,
        url: this.desktopBanner.value || undefined,
      },
    }
    const widget = this._widget || undefined
    return {
      name, lang, logo, shopUrl, shopButton, group, title, icon, description, image,
      brandFilter, styleFilter, genderFilter, priceSorting, shareCatalogButton,
      prices, buyButtons, brandNames, articles, search, banners, widget,
    }
  }

  private applyJson(json: StandProduct) {
    this.name.value = json.name || ''
    this._lang = json.lang
    this.logo.value = json.logo
    this.shopUrl.value = json.shopUrl ?? ''
    this.shopButton.value = json.shopButton ?? ''
    this.group.value = json.group || ''
    this.title.value = json.title || ''
    this.icon.value = json.icon
    this.description.value = json.description || ''
    this.image.value = json.image
    this._brandFilter = !!json.brandFilter
    this._styleFilter = !!json.styleFilter
    this._genderFilter = !!json.genderFilter
    this._priceSorting = !!json.priceSorting
    this._shareCatalogButton = !!json.shareCatalogButton
    this._prices = !!json.prices
    this._buyButtons = !!json.buyButtons
    this._brandNames = !!json.brandNames
    this._articles = !!json.articles
    this._search = !!json.search
    this._mobileBannerEnabled = !!json.banners?.mobile?.enabled
    this.mobileBanner.value = json.banners?.mobile?.url
    this._desktopBannerEnabled = !!json.banners?.desktop?.enabled
    this.desktopBanner.value = json.banners?.desktop?.url
    this._widget = json.widget
  }
}
