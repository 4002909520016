import { Button } from 'component/Button'
import { Card } from 'component/Card'
import { Image } from 'component/Image'
import { Link } from 'component/Link'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { CompanySaasStore } from 'saas/store/CompanySaasStore'
import { can } from 'store/can'
import { home } from 'store/home'
import { formatDate, parseDate } from 'util/date'
import { emdash } from 'util/typo'
import css from './CompanyCard.module.scss'

type Props = {
  company: CompanySaasStore
}

export const CompanyCard = observer(({ company }: Props) => {
  const { company_id, company_name, description, link, logo_url, created_at } = company.json
  const co = home.companies.findCompany(company_id)
  const created = parseDate(created_at)
  const manage = `/company/${company_id}/manage`

  const onInvite = useLatestCallback(async () => {
    if (co) modal.openInviteUsersModal(co)
  })

  return <Card pad={12} width={320}>
    <Vertical gap={12} wide>
      {logo_url && <Space className={css.image}>
        <Image compact src={logo_url} sources={[logo_url]}/>
      </Space>}
      {!logo_url && <div className={css.blank} />}

      <Vertical gap={4}>
        <Typo size={12} secondary tertiary={!company_id}>
          {company_id || emdash}
        </Typo>
        <Typo size={16} bold tertiary={!company_name}>
          {company_name || emdash}
        </Typo>
        {link && <Space gap={4}>
          <Icon name="link" size={14} secondary />
          <Link href={link} small>{link}</Link>
        </Space>}
      </Vertical>

      {description && <Typo size={12}>{description}</Typo>}

      <Space gap={4} wrap>
        <Space gap={2} oh>
          <Icon size={14} name="calendar_today" secondary />
          <Typo size={12} secondary ellipsis>
            {i18n('label.CreatedAt')}
          </Typo>
        </Space>
        <Typo size={12}>{formatDate(created) ?? emdash}</Typo>
      </Space>

      <Space gap={8}>
        {can.ViewCompanyInformation(company_id) && <Link path={manage}>
          <Button link compact>
            {i18n('company.Manage')}
          </Button>
        </Link>}
        {can.InviteUsers(company_id) && <Button link compact action={onInvite}>
          {i18n('invite.Invite')}
        </Button>}
      </Space>
    </Vertical>
  </Card>
})
