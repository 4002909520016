import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Field } from 'component/Field'
import { Sticker } from 'component/Glass'
import { Input } from 'component/Input'
import { Tooltip } from 'component/Popover/Tooltip'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Switch } from 'component/Switch/Switch'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { StatusTag } from 'saas/page/product/common/StatusTag/StatusTag'
import { tryon } from 'saas/store/product/ProductSaasController'
import css from './Status.module.scss'

export const Status = observer(() => {
  const { busy, key, json } = tryon.it
  const { published } = json
  const noPublish = !key && !published
  const tooltip = noPublish ? i18n('product.CannotPublishWithoutApiKey') : undefined

  const onPublish = useLatestCallback((publish: boolean) => {
    void tryon.it.publish(publish)
  })

  const onEdit = useLatestCallback(() => {
    tryon.it.openKeyEdit()
  })

  return <Vertical maxWidth={480}>
    <Typo size={15} semi>
      {i18n('product.PublishStatus')}
    </Typo>
    <Gap height={12} />
    <Card pad={16} secondary vertical top medium>
      <Space gap={12} wrap>
        <Vertical wide>
          <Typo size={13} semi>
            {i18n('product.PublishProduct')}
          </Typo>
          <Typo size={13} secondary>
            {i18n('product.ApiKeyRequiredToPublish')}
          </Typo>
        </Vertical>
        <Tooltip title={tooltip}>
          <Switch checked={!!published} onChange={onPublish} disabled={busy || noPublish} />
        </Tooltip>
      </Space>
      <Gap height={12} />
      <StatusTag />
      <Gap height={16} />
      <Divider secondary />
      <Gap height={16} />
      <Space>
        <Field className={css.field} label={i18n('company.ApiKey')}>
          <Input value={key?.name ?? ''} disabled />
        </Field>
        <Sticker pin={[0, 8, 0, null]}>
          <IconButton action={onEdit}>
            <Icon size={16} name="edit" secondary />
          </IconButton>
        </Sticker>
      </Space>
    </Card>
  </Vertical>
})
