// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar-sidebar-a602{--width: 96px;z-index:99;flex:0 0 auto;position:relative;width:var(--width);transition:width var(--transition) ease-in-out;box-sizing:border-box}.Sidebar-open-a602{--width: 280px}.Sidebar-fixed-a602{position:fixed;top:0;left:0;bottom:0;width:var(--width);transition:width var(--transition) ease-in-out}.Sidebar-over-a602{position:relative;width:100%;height:100%;overflow:hidden;background-color:var(--sidebar-background)}.Sidebar-content-a602{position:absolute;top:0;left:0;bottom:0;width:var(--width)}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/Sidebar.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CAEA,UAAA,CACA,aAAA,CACA,iBAAA,CACA,kBAAA,CACA,8CAAA,CACA,qBAAA,CAGF,mBACE,cAAA,CAGF,oBACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,kBAAA,CACA,8CAAA,CAGF,mBACE,iBAAA,CACA,UAAA,CACA,WAAA,CACA,eAAA,CACA,0CAAA,CAGF,sBACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,QAAA,CACA,kBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `Sidebar-sidebar-a602`,
	"open": `Sidebar-open-a602`,
	"fixed": `Sidebar-fixed-a602`,
	"over": `Sidebar-over-a602`,
	"content": `Sidebar-content-a602`
};
module.exports = ___CSS_LOADER_EXPORT___;
