import { Card } from 'component/Card'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { ReactElement } from 'react'
import { ProductDeleteModal } from 'saas/page/product/common/ProductDeleteModal/ProductDeleteModal'
import { Title } from 'saas/page/product/common/Title/Title'
import { productDeleteModal } from 'saas/store/product/ProductDeleteModalStore'
import { ui } from 'store/home/ui/HomeUiStore'
import css from './View.module.scss'

type Props = {
  children: [main: ReactElement, panel: ReactElement]
}

export const View = observer(({ children }: Props) => {
  const { mobile } = ui.window
  const [main, panel] = children

  return <Vertical pad={[0, mobile ? 0 : 24]} tall>
    <Title />
    <Divider secondary />
    <Space className={css.root} pad={[16, 0]} top wrap>
      <Card className={css.right} gap={16} pad={16} width={360}>
        {panel}
      </Card>
      <Space className={css.center}>
        <Card gap={16} width="min(900px, 100%)">
          {main}
        </Card>
      </Space>
    </Space>
    {productDeleteModal.present && <ProductDeleteModal />}
  </Vertical>
})
