import { language } from 'app/config/constants'
import { makeAutoObservable, runInAction } from 'mobx'
import { DocSectionStore } from 'saas/page/doc/store/DocSectionStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { api } from 'store/api'
import { parseDoc } from 'type/Doc'
import { LoadingState } from 'type/Store'
import { skipAsync } from 'util/async'

const cache: Record<string, string> = {}

async function getDoc(path: string) {
  const cached = cache[path]
  if (cached) return cached

  const content = await api.getDoc(path + '.' + language + '.md')
  cache[path] = content
  return content
}

export class DocStore {
  state: LoadingState = 'none'
  sections: DocSectionStore[] = []
  private _key: ApiKeyStore | undefined

  constructor() {
    makeAutoObservable(this)
    this.reset()
  }

  get key(): ApiKeyStore | undefined {
    return this._key
  }

  set key(value: ApiKeyStore | undefined) {
    this._key = value
  }

  get minLevel(): number {
    return this.sections.reduce((a, it) => a ? Math.min(a, it.data.level) : it.data.level, 0)
  }

  readonly update = skipAsync(async (path: string) => {
    this.reset()
    try {
      const content = await getDoc(path)
      const doc = parseDoc(content)
      const sections = doc.sections.map(data => new DocSectionStore(data))
      runInAction(() => {
        this.state = 'ready'
        this.sections = sections
      })
    } catch (e) {
      console.error('doc loading failed', e)
      runInAction(() => this.state = 'unknown')
    }
  })

  expand(section: DocSectionStore, expand: boolean) {
    section.expanded = expand
    if (expand) section.hide = false

    const start = this.sections.indexOf(section)
    if (start < 0) return

    const level = section.data.level
    for (let n = start + 1; n < this.sections.length; ++n) {
      const next = this.sections[n]!
      if (next.data.level <= level) break
      next.hide = !expand
    }
  }

  show(section: DocSectionStore) {
    this.expand(section, true)

    const start = this.sections.indexOf(section)
    if (start < 0) return

    let level = section.data.level
    for (let n = start - 1; n >= 0; --n) {
      const next = this.sections[n]!
      if (next.data.level > level) break
      if (next.data.level === level) continue
      level = next.data.level
      this.expand(next, true)
    }

    setTimeout(() => {
      if (!section.ref) return
      const rect = section.ref.getBoundingClientRect()
      const top = rect.top + window.scrollY - 12
      window.scroll({ top, behavior: 'smooth' })
    }, 10)
  }

  expandAll(expand: boolean) {
    for (const section of this.sections) {
      this.expand(section, expand)
    }
  }

  private reset() {
    this.state = 'none'
    this.sections = []
    this._key = undefined
  }
}

export const doc = new DocStore()
