import { Button } from 'component/Button'
import { Modal } from 'component/Modal'
import { Space } from 'component/Space'
import { Gap } from 'component/Space/Gap'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { KeySelect } from 'saas/page/product/tryon/ApiKey/KeySelect'
import { Origins } from 'saas/page/product/tryon/ApiKey/Origins'
import { ApiKeyModalStore, keyModal } from 'saas/store/key/ApiKeyModalStore'
import { tryon } from 'saas/store/product/ProductSaasController'
import { nbsp } from 'util/typo'

export const ApyKeyModal = observer(() => {
  const { busy } = tryon.it
  const { company, keyEdit } = tryon.it
  const { key, canSave } = keyEdit!

  const onSave = useLatestCallback(() => {
    void tryon.it.saveKey()
  })

  const onCancel = useLatestCallback(() => {
    tryon.it.closeKeyEdit()
  })

  const onEdit = useLatestCallback(() => {
    keyModal.it = new ApiKeyModalStore(company, key)
  })

  const onCreate = useLatestCallback(() => {
    const modal = new ApiKeyModalStore(company, undefined)
    modal.attachOnCreate(key => keyEdit!.key = key)
    keyModal.it = modal
  })

  return <Modal title={i18n('company.ApiKey')} onCancel={onCancel}>
    <Vertical>
      <Gap height={8} />
      <KeySelect />
      <Gap height={8} />
      {key && <Origins store={key} />}
    </Vertical>

    <Space gap={12} opposite>
      <Space gap={12}>
        {key ? <Button link compact action={onEdit}>
          {i18n('key.Edit')}
        </Button> : nbsp}
        <Button link compact action={onCreate}>
          {i18n('company.CreateApiKey')}
        </Button>
      </Space>
      <Space gap={12}>
        <Button filled action={onCancel}>
          {i18n('common.Cancel')}
        </Button>
        <Button primary action={onSave} disabled={busy || !canSave}>
          {i18n('common.Save')}
        </Button>
      </Space>
    </Space>
  </Modal>
})
