import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { groupFormSaas } from 'saas/store/groups/GroupFormStore'
import css from './Condition.module.scss'
import { Button } from 'component/Button'
import clsx from 'clsx'

export const Condition = observer(() => {
  const { value, onChange } = groupFormSaas.it.condition

  const onChangeAll = useLatestCallback(() => {
    onChange('all')
  })

  const onChangeAny = useLatestCallback(() => {
    onChange('any')
  })

  return (
    <Space gap={16}>
      <Typo>{i18n('group.conditions.ProductsMustMatch')}</Typo>
      <Space gap={8} pad={8} className={css.wrapper}>
        <Button
          text
          active={value === 'all'}
          className={clsx(css.button)}
          action={onChangeAll}
        >
          {i18n('group.conditions.AllConditions')}
        </Button>
        <Button
          text
          active={value === 'any'}
          className={clsx(css.button)}
          action={onChangeAny}
        >
          {i18n('group.conditions.AnyCondition')}
        </Button>
      </Space>
    </Space>
  )
})
