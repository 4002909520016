import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { tryon } from 'saas/store/product/ProductSaasController'

export const BackgroundColorInput = observer(() => {
  const { background } = tryon.it.edit
  const value = background ?? ''

  const onChange = useLatestCallback((value: string) => {
    tryon.it.edit.background = value.trim()
  })

  return <Field label={i18n('widget.BackgroundColor')}>
    <Input mono lazy value={value} placeholder="#ffffff" onChange={onChange} />
  </Field>
})
