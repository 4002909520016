import { i18n } from 'i18n'
import { makeAutoObservable, runInAction } from 'mobx'
import { onceAsync, skipAsync } from 'util/async'
import { getUiMessage } from 'util/error'

type Factory<Store> = () => Promise<Store | undefined>

type State = 'none' | 'busy' | 'ready' | 'error'

export class AsyncEntry<Store> {
  private readonly factory: Factory<Store>

  state: State = 'none'
  error = ''
  store?: Store

  constructor(factory: Factory<Store>) {
    makeAutoObservable<this, 'factory'>(this, { factory: false })
    this.factory = factory
  }

  get it(): Store {
    const it = this.store
    if (!it) throw new Error('no it')
    return it
  }

  get ready(): boolean {
    return this.state === 'ready'
  }

  get absent(): boolean {
    return this.ready && !this.store
  }

  readonly load = onceAsync(async () => {
    if (this.state === 'none') return await this.reload()
    else return this.store
  })

  readonly reload = skipAsync(async (): Promise<Store | undefined> => {
    this.start()
    try {
      const store = await this.factory()
      this.resolve(store)
      return store
    } catch (e) {
      console.error('load failed', e)
      this.reject(e)
      throw e
    } finally {
      this.stop()
    }
  })

  private start() {
    runInAction(() => {
      this.state = 'busy'
      this.error = ''
      this.store = undefined
    })
  }

  private stop() {
    this.state = 'ready'
  }

  private resolve(value: Store | undefined) {
    this.store = value
  }

  private reject(reason: unknown) {
    this.error = getUiMessage(reason) || i18n('common.Error') || 'Error'
  }
}
