// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExpandItem-button-3016{background:rgba(0,0,0,0);border-color:rgba(0,0,0,0);text-align:left;color:var(--sidebar-text-color);font-size:14px;padding:8px;height:48px;min-height:48px;display:flex;align-items:center;flex-wrap:nowrap;transition:all var(--transition) ease-in-out;cursor:pointer}.ExpandItem-button-3016:hover .ExpandItem-title-3016{color:var(--sidebar-text-color-hover)}.ExpandItem-button-3016:hover .ExpandItem-icon-3016{background:var(--sidebar-item-icon-background-hover);color:var(--sidebar-background)}.ExpandItem-button-3016 .ExpandItem-title-3016{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;transition:all var(--transition) ease-in-out}.ExpandItem-button-3016 .ExpandItem-icon-3016{width:32px;height:32px;min-width:32px;border-radius:8px;color:rgba(250,250,250,.64);margin-right:12px;display:flex;align-items:center;justify-content:center;transition:all var(--transition) ease-in-out}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/ExpandItem.module.scss"],"names":[],"mappings":"AAAA,wBACE,wBAAA,CACA,0BAAA,CACA,eAAA,CACA,+BAAA,CACA,cAAA,CACA,WAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,4CAAA,CACA,cAAA,CAGE,qDACE,qCAAA,CAGF,oDACE,oDAAA,CACA,+BAAA,CAIJ,+CACE,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,4CAAA,CAGF,8CACE,UAAA,CACA,WAAA,CACA,cAAA,CACA,iBAAA,CACA,2BAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,4CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ExpandItem-button-3016`,
	"title": `ExpandItem-title-3016`,
	"icon": `ExpandItem-icon-3016`
};
module.exports = ___CSS_LOADER_EXPORT___;
