import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { productCreate, ProductCreateStore } from 'saas/store/product/ProductCreateStore'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { ui } from 'store/home/ui/HomeUiStore'

export const Title = observer(() => {
  const { small } = ui.window
  const { busy } = productsSaas.it

  const onCreate = useLatestCallback(() => {
    const { config } = productsSaas.it
    productCreate.open(new ProductCreateStore(config))
  })

  return <Space gap={16} opposite>
    <Typo size={18} bold>
      {i18n('product.AllProducts')}
    </Typo>
    {small && <Button square primary disabled={busy} action={onCreate}>
      <Icon size={16} name="add_circle" />
    </Button>}
    {!small && <Button primary large disabled={busy} action={onCreate}>
      <Icon size={16} name="add_circle" />
      {i18n('product.CreateProduct')}
    </Button>}
  </Space>
})
