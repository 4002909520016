import EnPng from 'asset/flag/en.png'
import RuPng from 'asset/flag/ru.png'
import { Segmented } from 'component/Segmented'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productSaas } from 'saas/store/product/ProductSaasController'

export const LanguageSelect = observer(() => {
  const edit = productSaas.it.edit
  if (!('lang' in edit)) throw new Error()
  const { lang } = edit

  const options = [{
    value: 'ru',
    label: i18n('language.Russian'),
    icon: <img src={RuPng} width={16} height={16} />,
  }, {
    value: 'en',
    label: i18n('language.English'),
    icon: <img src={EnPng} width={16} height={16} />,
  }]

  const onChange = useLatestCallback((lang: string) => {
    edit.lang = lang
  })

  return <Segmented options={options} value={lang ?? ''} onChange={onChange} />
})
