import { Table } from 'component/Table'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { FeedRow } from 'saas/page/product/bot/FeedCard/Campaigns/FeedRow'
import { bot } from 'saas/store/product/ProductSaasController'

export const FeedTable = observer(() => {
  const { feeds } = bot.it.feeds.it

  return <Vertical wide>
    <Table>
      <Table.THead>
        <Table.Td>{i18n('label.Name')}</Table.Td>
        <Table.Td>{i18n('label.LastUpdate')}</Table.Td>
        <Table.Td>Status</Table.Td>
        <Table.Td></Table.Td>
      </Table.THead>
      <Table.TBody>
        {feeds.map(feed => <FeedRow key={feed.json.campaign_id} feed={feed} />)}
      </Table.TBody>
    </Table>
  </Vertical>
})
