import { notice } from 'app/notice'
import { i18n } from 'i18n'
import { autorun, makeAutoObservable, reaction, runInAction } from 'mobx'
import { api } from 'store/api'
import { CollectionStore } from 'store/collection'
import { commenting } from 'store/comment/CommentingStore'
import { CompanyStore } from 'store/company/CompanyStore'
import { home } from 'store/home'
import { closeProductsPage, openProductsPage, ProductStore, productStoreCache } from 'store/product'
import { route } from 'store/route'
import { storage } from 'store/storage'

class SaasController {
  ready = false
  error = ''

  constructor() {
    makeAutoObservable(this)
  }

  async init() {
    await this.initCompanies()
    reaction(() => route.pathname, () => commenting.close())
    autorun(() => {
      if (!home.company.present) console.warn('no company')
    })
    runInAction(() => this.ready = true)
  }

  async initCompany(company: CompanyStore) {
    home.company.it = company
    await company.loadCollections()
  }

  async initCollection(collectionId: string | undefined) {
    if (!collectionId) {
      home.collection.it = undefined
      closeProductsPage()
      return
    }
    const collection = await this.loadCollection(collectionId)
    home.collection.it = collection
    openProductsPage(collection)
    this.tryChangeCompany(collection.companyId)
  }

  async initProduct(productId: string | undefined) {
    if (!productId) {
      home.product.it = undefined
      return
    }
    const product = await this.loadProduct(productId)
    home.product.it = product
    this.tryChangeCompany(product.companyId)
  }

  private async loadCollection(collectionId: string): Promise<CollectionStore> {
    let collection = CollectionStore.fromCache(collectionId)
    if (collection) return collection

    const response = await api.getCollectionItems(collectionId)
    collection = CollectionStore.fromJson(response.collection)
    collection.setItems(response.items)
    return collection
  }

  private async loadProduct(productId: string): Promise<ProductStore> {
    let product = productStoreCache[productId]
    if (product) return product

    const response = await api.getItem(productId)
    product = new ProductStore(response.item, response.collection.company_id)
    return product
  }

  private tryChangeCompany(companyId: string | undefined) {
    const company = home.companies.findCompany(companyId)
    if (company) home.company.it = company
  }

  private async initCompanies() {
    await home.companies.load()

    const companyId = storage.loadCompanyId()
    const company = home.companies.findRoute(companyId)

    if (!company) {
      notice.error(i18n('error.NoAccessToCompany'))
      this.error = i18n('error.AccessDenied')
      throw new Error('no companies')
    }

    home.company.it = company
  }
}

export const saas = new SaasController()
