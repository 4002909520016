import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { routesStore } from 'saas/route/RoutesStore'
import { CompanyItem } from 'saas/screen/sidebar/CompanyItem'
import { ExpandItem } from 'saas/screen/sidebar/ExpandItem'
import { renderRouteItems } from 'saas/screen/sidebar/RouteItem'
import { UserItem } from 'saas/screen/sidebar/UserItem'
import { saas } from 'saas/store/SaasController'
import { sidebar } from 'saas/store/SidebarStore'
import { home } from 'store/home'

export const Menu = observer(() => {
  const { small } = sidebar
  const ready = saas.ready && home.company.present

  if (!ready) return null

  if (small) return <Vertical gap={24} pad={[24, 16]}>
    <CompanyItem />
    <Vertical>
      {renderRouteItems(routesStore.routes)}
    </Vertical>
  </Vertical>

  return <Vertical pad={[0, 24]} opposite tall>
    <Vertical gap={16}>
      <CompanyItem />
      <Vertical>
        {renderRouteItems(routesStore.routes)}
      </Vertical>
    </Vertical>
    <Vertical gap={8}>
      <UserItem />
      <ExpandItem />
    </Vertical>
  </Vertical>
})
