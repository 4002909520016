import { Tag as TagAntd } from 'antd'
import { clsx } from 'clsx'
import { useLatestCallback } from 'hook/useLatestCallback'
import { Icon } from 'icon/Icon'
import { MouseEvent, ReactNode } from 'react'
import css from './ItemTag.module.scss'

type Props<Data> = {
  data: Data
  children?: ReactNode
  className?: string
  onClose?(event: MouseEvent, data: Data): void
}

export function ItemTag<Data = undefined>(props: Props<Data>) {
  const { data, children } = props
  const className = clsx(props.className, css.tag)
  const title = typeof children === 'string' && children ? children : undefined

  const onMouseDown = useLatestCallback((event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
  })

  const onCloseStable = useLatestCallback((event: MouseEvent) => {
    props.onClose?.(event, data)
  })

  const onClose = props.onClose ? onCloseStable : undefined

  return <TagAntd className={className} title={title} bordered={false}>
    <div className={css.content}>{children}</div>
    {onClose && <div className={css.close} onMouseDown={onMouseDown} onClick={onClose}>
      <Icon className={css.icon} size={16} name="close" />
    </div>}
  </TagAntd>
}
