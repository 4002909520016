import { CopyIconButton } from 'component/Button/CopyIconButton'
import { IconButton } from 'component/Button/IconButton'
import { Card } from 'component/Card'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { doc } from 'saas/page/doc/store/DocStore'
import { companiesSaas } from 'saas/store/CompaniesSaasStore'
import { ApiKeyStore } from 'saas/store/key/ApiKeyStore'
import { home } from 'store/home'
import { emdash } from 'util/typo'

export const KeyDropdown = observer(() => {
  const company_id = home.company.optional?.company_id
  const keys = companiesSaas.company(company_id)?.keys ?? []

  useEffect(() => {
    void companiesSaas.preload(company_id)
  }, [company_id])

  const items: DropdownItem[] = keys.map((key: ApiKeyStore): DropdownItem => ({
    key: key.key,
    label: <Vertical pad={[8, 0]}>
      <Typo size={13} line={17} secondary>{key.name}</Typo>
      <Typo size={13} line={17}>{key.value || emdash}</Typo>
    </Vertical>,
  }))

  const onSelect = useLatestCallback((id: string) => {
    doc.key = keys.find(key => key.key === id)
  })

  return <Dropdown placement="bottom" stretch items={items} onClick={onSelect}>
    <Card panel gap={16} pad={[8, 12]} round={8} wide opposite>
      <Vertical>
        <Space gap={8}>
          <Typo size={11} secondary>
            {i18n('company.ApiKey')}
          </Typo>
          <Typo size={11} secondary mono>
            {doc.key?.name || emdash}
          </Typo>
        </Space>
        <Typo size={13} mono>
          {doc.key?.value || emdash}
        </Typo>
      </Vertical>
      <Space gap={8}>
        {doc.key && <CopyIconButton data={doc.key?.value} />}
        <IconButton>
          <Icon size={16} name="unfold_more" />
        </IconButton>
      </Space>
    </Card>
  </Dropdown>
})
