import { SortField } from 'feature/pagination/SortField'
import { makeAutoObservable } from 'mobx'
import { mx } from 'store/base/mx'
import { CollectionStore } from 'store/collection'
import { ProductStore } from 'store/product'
import { ProductItem } from 'store/product/ProductItem'
import { ExternalStatus } from 'type/ExternalStatus'
import { InternalStatus } from 'type/InternalStatus'
import { matchSearch } from 'util/search'
import { by } from 'util/sort'

const PAGE = 1

export class ProductsPageStore {
  readonly collection: CollectionStore

  private _page = PAGE
  private _limit = 10
  private _sort: SortField = 'updated_at'

  private _status: InternalStatus | '' = ''
  private _review: ExternalStatus | '' = ''
  private _search = ''

  constructor(collection: CollectionStore) {
    makeAutoObservable(this)
    this.collection = collection
  }

  get ready(): boolean {
    return this.collection.items.present
  }

  get page(): number {
    return this._page
  }

  set page(value: number) {
    this._page = value
  }

  get limit(): number {
    return this._limit
  }

  set limit(value: number) {
    this._limit = value
    this._page = PAGE
  }

  get sort(): SortField {
    return this._sort
  }

  set sort(value: SortField) {
    this._sort = value
    this._page = PAGE
  }

  get status(): InternalStatus | '' {
    return this._status
  }

  set status(value: InternalStatus | '') {
    this._status = value
    this._page = PAGE
  }

  get review(): ExternalStatus | '' {
    return this._review
  }

  set review(value: ExternalStatus | '') {
    this._review = value
    this._page = PAGE
  }

  get search(): string {
    return this._search
  }

  set search(value: string) {
    this._search = value
    this._page = PAGE
  }

  get pages(): number {
    return Math.ceil(this.filtered.length / this.limit)
  }

  get items(): ProductItem[] {
    const to = this.page * this.limit
    const from = to - this.limit
    return this.filtered.slice(from, to)
  }

  private get filtered(): ProductItem[] {
    return this.sorted.filter(item => this.filter(item.store.optional))
  }

  private get sorted(): ProductItem[] {
    const items = this.collection.items.optional?.value ?? []
    return items.toSorted(by(item => this.by(item.store.optional)))
  }

  private by(product: ProductStore | undefined): number | undefined {
    switch (this.sort) {
      case 'updated_at':
        return product?.data?.updated_at?.getTime()
      case 'created_at':
        return product?.data?.created_at?.getTime()
      default:
        return undefined
    }
  }

  private filter(product: ProductStore | undefined): boolean {
    if (!product) return true

    const { status, review, search } = this

    if (search) {
      const { product_name, brand_name, item_id, external_item_id, article } = product.data.json
      const values = [product_name, brand_name, external_item_id, item_id, article]
      if (!matchSearch(search, values)) return false
    }
    if (status) {
      const value = product.data.json.internal_status ?? ''
      if (status !== value) return false
    }
    if (review) {
      const value = product.data.json.external_status ?? ''
      if (review !== value) return false
    }
    return true
  }
}

export const page = mx.ref<ProductsPageStore>()

export function openProductsPage(collection: CollectionStore) {
  void collection.loadItems()
  page.open(new ProductsPageStore(collection))
}

export function closeProductsPage() {
  page.close()
}
