import { security } from 'app/controller/security'
import { Dropdown, DropdownItem } from 'component/Popover/Dropdown'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { modal } from 'modal'
import { ReactNode } from 'react'
import { can } from 'store/can'
import { home } from 'store/home'
import { compact } from 'util/array'

export function buildProfileItems() {
  const company = home.company.optional
  const canInvite = can.InviteUsers(company?.company_id)

  const items: DropdownItem[] = compact([
    {
      key: 'profile',
      label: i18n('common.Profile'),
      action: () => modal.openProfileModal(),
      icon: <Icon size={16} name="account_circle" />,
    },
    canInvite && {
      key: 'invite',
      label: i18n('invite.Invite'),
      action: () => {
        const company = home.company.optional
        modal.openInviteUsersModal(company)
      },
      icon: <Icon size={16} name="person_add" />,
    },
    { divider: true },
    {
      key: 'logout',
      label: i18n('label.Logout'),
      action: () => security.logout(),
      icon: <Icon size={16} name="logout" />,
    },
  ])
  return items
}

type Props = {
  children: ReactNode
}

export const ProfileDropdown = observer(({ children }: Props) => {
  const items = buildProfileItems()

  return <Dropdown items={items} placement="bottomRight" space={8}>
    {children}
  </Dropdown>
})
