import { Field } from 'component/Field'
import { Input } from 'component/Input'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { productCreate } from 'saas/store/product/ProductCreateStore'

export const NameInput = observer(() => {
  const { value, error, onChange } = productCreate.it.name

  return <Field label={i18n('product.ProductName')}>
    <Input value={value} onChange={onChange} error={error} focus />
  </Field>
})
