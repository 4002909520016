import { Field } from 'component/Field'
import { Option, Select } from 'component/Select/Select'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { useLatestCallback } from 'hook/useLatestCallback'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { stand } from 'saas/store/product/ProductSaasController'
import { productsSaas } from 'saas/store/products/ProductsSaasController'
import { getProductTypeTitle } from 'type/product/ProductType'

function getError(product_id: string | undefined): string | undefined {
  if (!product_id) return
  const product = productsSaas.it.products.find(it => it.product_id === product_id)
  if (!product) return i18n('product.ProductNotFound')
  const { type, published } = product.json
  if (type !== 'try_on') return i18n('product.ProductType') + ' ' + getProductTypeTitle(type)
  if (!published) return i18n('product.NotPublished')
}

export const WidgetSelect = observer(() => {
  const store = stand.it.edit
  const products = productsSaas.it.products.filter(it => it.json.type === 'try_on' && it.json.published)
  const empty = !store.widget && !products.length
  const error = getError(store.widget)

  const options: Option[] = [
    {
      value: '',
      label: <Typo secondary>
        {i18n('widget.Default')}
      </Typo>,
    },
    ...products.map(product => ({
      value: product.product_id,
      label: product.json.name,
    })),
  ]

  const onChange = useLatestCallback((value: string | undefined) => {
    store.widget = value || undefined
  })

  return <Vertical gap={6}>
    <Field label={i18n('product.TryOnWidget')}>
      <Select value={store.widget} options={options} disabled={empty} error={!!error}
        wide placeholder={i18n('widget.Default')} onChange={onChange} />
    </Field>
    {empty && <Typo size={11} secondary>{i18n('product.NoProducts')}</Typo>}
    {error && <Typo size={11} error>{error}</Typo>}
  </Vertical>
})
