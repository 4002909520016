import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { GroupsTable } from 'saas/page/groups/components/GroupsTable'
import { Title } from 'saas/page/groups/components/Title'
import { groupsSaas } from 'saas/store/groups/GroupsCache'

export const SaasGroupsView = observer(() => {
  const { groups } = groupsSaas.it
  const empty = !groups.length

  return (
    <Vertical>
      <Title />
      {empty && (
        <Typo size={12} secondary>
          {i18n('group.NoItemGroups')}
        </Typo>
      )}
      {!empty && <GroupsTable />}
    </Vertical>
  )
})
