import { Card } from 'component/Card'
import { Scroll } from 'component/Scroll'
import { Vertical } from 'component/Vertical'
import { observer } from 'mobx-react-lite'
import { AgendaItem } from 'saas/page/doc/AgendaItem'
import { doc } from 'saas/page/doc/store/DocStore'
import css from './AgendaCard.module.scss'

export const AgendaCard = observer(() => {
  const { sections } = doc

  return <Card width={360}>
    <Scroll className={css.scroll}>
      <div>
        <Vertical gap={12} pad={24}>
          {sections.map(section => <AgendaItem key={section.key} section={section} />)}
        </Vertical>
      </div>
    </Scroll>
  </Card>
})
