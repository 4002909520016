// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Page2-page-80c6{padding:24px;gap:24px}@media(width < 760px){.Page2-page-80c6{padding:16px;gap:16px}}@media(width < 760px){.Page2-divider-80c6{margin:0 -16px}}`, "",{"version":3,"sources":["webpack://./src/saas/screen/page/Page2/Page2.module.scss"],"names":[],"mappings":"AAAA,iBACE,YAAA,CACA,QAAA,CAEA,sBAJF,iBAKI,YAAA,CACA,QAAA,CAAA,CAKF,sBADF,oBAEI,cAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page": `Page2-page-80c6`,
	"divider": `Page2-divider-80c6`
};
module.exports = ___CSS_LOADER_EXPORT___;
