import { clsx } from 'clsx'
import { Card } from 'component/Card'
import { Tooltip } from 'component/Popover/Tooltip'
import { Space } from 'component/Space'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { totals } from 'saas/store/stat/TotalsStore'
import css from './TotalCard.module.scss'

type Props = {
  num: number | undefined
  unit: string
  tooltip?: string
}

export const TotalCard = observer(({ num, unit, tooltip }: Props) => {
  const { ready, error } = totals
  const hide = !ready || error

  return <Card minWidth={200} height={100} error={error}>
    <Vertical wide gap={16} pad={16}>
      <Space wide opposite>
        <Typo size={13} className={css.unit}>
          {unit}
        </Typo>
        {tooltip && <Tooltip title={tooltip}>
          <Icon size={16} name="help" />
        </Tooltip>}
      </Space>
      <Typo className={clsx(css.num, hide && css.hide)} size={23} bold>
        {num ?? 0}
      </Typo>
    </Vertical>
  </Card>
})
