import { makeAutoObservable } from 'mobx'
import { BotFeedStore } from 'saas/store/product/bot/BotFeedStore'
import { BotFeed } from 'type/Feed'
import { by } from 'util/sort'

export class BotFeedsStore {
  private readonly _feeds: BotFeedStore[]

  constructor(feeds: BotFeed[]) {
    this._feeds = feeds.map(feed => new BotFeedStore(feed))
      .sort(by(it => it.created_at?.getTime(), true))
    makeAutoObservable(this)
  }

  get feeds(): BotFeedStore[] {
    return this._feeds
  }

  add(feed: BotFeed) {
    this._feeds.unshift(new BotFeedStore(feed))
  }
}
