// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconInput-back-c0c3{max-width:100%;max-height:100%;overflow:hidden}.IconInput-icon-c0c3{width:20px;height:20px}.IconInput-icon-c0c3 img{width:20px;height:20px}.IconInput-pale-c0c3{opacity:.2}.IconInput-light-c0c3{position:absolute;left:47px;top:17px;color:#666}.IconInput-dark-c0c3{position:absolute;left:47px;top:71px;color:#666}`, "",{"version":3,"sources":["webpack://./src/saas/page/product/stand/IconInput/IconInput.module.scss"],"names":[],"mappings":"AAAA,qBACE,cAAA,CACA,eAAA,CACA,eAAA,CAGF,qBACE,UAAA,CACA,WAAA,CAEA,yBACE,UAAA,CACA,WAAA,CAIJ,qBACE,UAAA,CAGF,sBACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,UAAA,CAGF,qBACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"back": `IconInput-back-c0c3`,
	"icon": `IconInput-icon-c0c3`,
	"pale": `IconInput-pale-c0c3`,
	"light": `IconInput-light-c0c3`,
	"dark": `IconInput-dark-c0c3`
};
module.exports = ___CSS_LOADER_EXPORT___;
