// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FiltersTag-tag-bd58{display:flex;align-items:center;justify-content:center;height:20px;padding:0 6px;border-radius:12px;font-size:15px;font-weight:600;white-space:nowrap;text-overflow:ellipsis;background-color:var(--ui-background-color-active);cursor:pointer;-webkit-user-select:none;user-select:none}.FiltersTag-primary-bd58{background-color:var(--ui-primary-color);color:#fff}`, "",{"version":3,"sources":["webpack://./src/page/internal/search/FiltersTag.module.scss"],"names":[],"mappings":"AAAA,qBACE,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,WAAA,CACA,aAAA,CACA,kBAAA,CACA,cAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,kDAAA,CACA,cAAA,CACA,wBAAA,CAAA,gBAAA,CAGF,yBACE,wCAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `FiltersTag-tag-bd58`,
	"primary": `FiltersTag-primary-bd58`
};
module.exports = ___CSS_LOADER_EXPORT___;
