import { AppSvg } from 'app/config/icon'
import { Space } from 'component/Space'
import { observer } from 'mobx-react-lite'
import css from './Logo.module.scss'

export const Logo = observer(() => {
  return <Space className={css.logo} gap={8} pad={[0, 24]} center>
    <AppSvg w={120} />
    <div className={css.sep} />
    <div className={css.title}>SAAS</div>
  </Space>
})
