// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserLogo-image-8b38{display:block;width:100%;height:100%;object-fit:cover;background-color:var(--ui-background-color-weak)}.UserLogo-space-8b38{width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/saas/screen/sidebar/UserLogo.module.scss"],"names":[],"mappings":"AAAA,qBACE,aAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,gDAAA,CAGF,qBACE,UAAA,CACA,WAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `UserLogo-image-8b38`,
	"space": `UserLogo-space-8b38`
};
module.exports = ___CSS_LOADER_EXPORT___;
