import clsx from 'clsx'
import { OverlayScrollbarsComponent, OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { forwardRef, ReactNode } from 'react'
import css from './Scroll.module.scss'

type Props = {
  children: ReactNode
  className?: string
}

export type ScrollRef = OverlayScrollbarsComponentRef

export const Scroll = forwardRef<ScrollRef, Props>((props, ref) => {
  const { children } = props
  const className = clsx(props.className, css.scroll)
  return <OverlayScrollbarsComponent ref={ref} className={className} options={{ scrollbars: { theme: '' } }}>
    {children}
  </OverlayScrollbarsComponent>
})
