import { Space } from 'component/Space'
import { Spinner } from 'component/Spinner'
import { Typo } from 'component/Typo'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { observer } from 'mobx-react-lite'
import { Pagination } from 'page/internal/products/Pagination'
import { ProductFilters } from 'page/internal/products/ProductFilters'
import { ProductList } from 'page/internal/products/ProductList'
import { page } from 'store/product'

export const View = observer(() => {
  const ready = !!page.optional?.ready
  const empty = !page.optional?.items.length

  if (!ready) {
    return <Space height={300} wide center>
      <Spinner />
    </Space>
  }

  return <Vertical gap={16} wide>
    <Pagination top />
    <ProductFilters />
    {empty && <Space height={300} center>
      <Typo size={14} secondary>
        {i18n('item.NoItems')}
      </Typo>
    </Space>}
    {!empty && <ProductList />}
    <Pagination />
  </Vertical>
})
