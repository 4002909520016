import { Button } from 'component/Button'
import { Space } from 'component/Space'
import { Divider } from 'component/Space/Divider'
import { Gap } from 'component/Space/Gap'
import { Vertical } from 'component/Vertical'
import { i18n } from 'i18n'
import { Icon } from 'icon/Icon'
import { observer } from 'mobx-react-lite'
import { inviteUsers } from 'page/invite/store/InviteUsersStore'
import { UserRow } from 'page/invite/UserRow'
import { ui } from 'store/home/ui/HomeUiStore'
import { CompanySection } from './CompanySection'

export const AddUsersStep = observer(() => {
  const { company, users, hasUsersDone, onAddUser, onBack, onNext } = inviteUsers.it
  const title = company?.title
  const logo = company?.company.logo_url

  return <Vertical>
    <CompanySection title={title} logoUrl={logo} />
    <Gap height={12} />
    <Vertical gap={12}>
      {users.map(user => <UserRow key={user.key} user={user} />)}
    </Vertical>
    <Gap height={12} />
    <Divider secondary />
    <Gap height={12} />
    <Space>
      <Button icon={<Icon size={16} name="add_circle" />} link compact action={onAddUser}>
        {i18n('invite.AddUser')}
      </Button>
    </Space>
    <Gap height={24} />
    <Space gap={12} right wrap>
      {ui.manager && <Button action={onBack}>{i18n('common.GoBack')}</Button>}
      <Button wide primary disabled={!hasUsersDone} action={onNext}>
        {i18n('common.GoNext')}
      </Button>
    </Space>
  </Vertical>
})
