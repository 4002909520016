import { GroupFilterConfigsStore } from 'saas/store/groups/GroupFilterConfigsStore'
import { api } from 'store/api'
import { AsyncStoreController } from 'store/base/async/AsyncStoreController'

async function factory(company_id: string): Promise<GroupFilterConfigsStore | undefined> {
  const filters = await api.getGroupFilters(company_id)
  return new GroupFilterConfigsStore(filters)
}

export const filterConfigs = new AsyncStoreController(factory)
