import _ from 'lodash'

export function last<T>(array: readonly T[]): T | undefined {
  return array[array.length - 1]
}

export function compact<T>(array: (T | '' | 0 | false | null | undefined)[] | undefined): T[] {
  return _.compact(array)
}

export function split(text: string | null | undefined): readonly string[] {
  return compact(text?.split(','))
}

export function uniq<T>(array: readonly T[]): T[] {
  return Array.from(new Set(array))
}

export function times(length: number): number[] {
  return _.times(length)
}
