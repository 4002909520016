import { groupsSaas } from 'saas/store/groups/GroupsCache'
import { PageController } from 'store/base/page/PageController'
import { home } from 'store/home'

type Params = {
  company_id: string
}

type Data = object

export class SaasGroupsPageController implements PageController<Params, Data> {
  async load({ company_id }: Params): Promise<Data | undefined> {
    const company = home.selectCompany(company_id)
    if (!company) return
    const _groups = groupsSaas.add(company_id).load()
    await _groups
    return {}
  }

  select(params: Params): void {
    groupsSaas.select(params.company_id)
  }

  close(): void {
    groupsSaas.close()
  }
}

export const saasGroupsPageController = new SaasGroupsPageController()
