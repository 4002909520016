import { i18n } from 'i18n'

export const TRY_ON = 'try_on' as const
export const STAND = 'stand' as const
export const BOT = 'bot' as const

export const PRODUCT_TYPES = [
  TRY_ON,
  STAND,
  BOT,
] as const

export type ProductType = typeof PRODUCT_TYPES[number]

export function getProductTypeTitle(type: ProductType): string
export function getProductTypeTitle(type: ProductType | null | undefined): string | undefined
export function getProductTypeTitle(type: string | null | undefined): string | undefined {
  switch (type) {
    case TRY_ON:
      return i18n('product.TryOnWidget')
    case STAND:
      return i18n('product.QrCatalog')
    case BOT:
      return i18n('product.TelegramBot')
    default:
      return undefined
  }
}
